// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ai_indicator_ai_icon_div__IRzyK {\n  height: -moz-fit-content;\n  height: fit-content;\n  width: -moz-fit-content;\n  width: fit-content;\n  padding: 5px;\n  position: absolute;\n  z-index: 30001;\n  left: 5px;\n  top: 5px;\n  border-radius: 5px;\n  backdrop-filter: blur(3px);\n  -webkit-backdrop-filter: blur(3px);\n  transition: all 0.15s ease-in-out;\n}\n\n.ai_indicator_ai_icon__X5xxT {\n  height: 20px;\n  width: 20px;\n  border-radius: 200px;\n  flex: 1 1;\n  text-align: center;\n  display: table;\n  z-index: 3000;\n  opacity: 1;\n  background-repeat: no-repeat;\n  background-size: contain;\n  background-position: center;\n}", "",{"version":3,"sources":["webpack://./src/styles/ai_indicator.module.scss"],"names":[],"mappings":"AAAA;EACI,wBAAA;EAAA,mBAAA;EACA,uBAAA;EAAA,kBAAA;EACA,YAAA;EACA,kBAAA;EACA,cAAA;EACA,SAAA;EACA,QAAA;EACA,kBAAA;EACA,0BAAA;EACA,kCAAA;EACA,iCAAA;AACJ;;AAEA;EACI,YAAA;EACA,WAAA;EACA,oBAAA;EACA,SAAA;EACA,kBAAA;EACA,cAAA;EACA,aAAA;EACA,UAAA;EACA,4BAAA;EACA,wBAAA;EACA,2BAAA;AACJ","sourcesContent":[".ai_icon_div{\n    height: fit-content;\n    width: fit-content;\n    padding: 5px;\n    position: absolute;\n    z-index: 30001;\n    left: 5px;\n    top: 5px;\n    border-radius: 5px;\n    backdrop-filter: blur(3px);\n    -webkit-backdrop-filter: blur(3px);\n    transition: all 0.15s ease-in-out;\n}\n\n.ai_icon{\n    height: 20px;\n    width: 20px;\n    border-radius: 200px;\n    flex: 1;\n    text-align: center;\n    display: table;\n    z-index: 3000;\n    opacity: 1.0;\n    background-repeat: no-repeat;\n    background-size: contain;\n    background-position: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ai_icon_div": "ai_indicator_ai_icon_div__IRzyK",
	"ai_icon": "ai_indicator_ai_icon__X5xxT"
};
export default ___CSS_LOADER_EXPORT___;
