// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".visualisationManager_rootDiv__kebqr {\n  position: relative;\n  height: 100%;\n  width: 100%;\n}\n\n.visualisationManager_bBoxesAndCaptionsDiv__hkvIr {\n  position: relative;\n  height: 100%;\n  width: 100%;\n}", "",{"version":3,"sources":["webpack://./src/components/sources/visualization/VisualisationManager/visualisationManager.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,YAAA;EACA,WAAA;AACJ;;AAGA;EACI,kBAAA;EACA,YAAA;EACA,WAAA;AAAJ","sourcesContent":[".rootDiv{\n    position: relative;\n    height: 100%;\n    width: 100%;\n    //border: 3px solid red;\n}\n\n.bBoxesAndCaptionsDiv{\n    position: relative;\n    height: 100%;\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rootDiv": "visualisationManager_rootDiv__kebqr",
	"bBoxesAndCaptionsDiv": "visualisationManager_bBoxesAndCaptionsDiv__hkvIr"
};
export default ___CSS_LOADER_EXPORT___;
