import useMeasure from "react-use-measure";
import { ResponsiveLine } from '@nivo/line';
import styles from "./lineChart.module.scss";
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useState } from "react";
import json_line_chart_en from './line_chart_en.json';
import json_line_chart_pt from './line_chart_pt.json';
import { sortByDateAndHour } from "../../../../utilities/Utils.js";

const LineChart = ( { theme, data, parentHeight } ) => {

    const [language, set_language] = useState(localStorage.getItem("language"));
    const json_data = (language === "English" ? json_line_chart_en : json_line_chart_pt);

    const [renderedData, setRenderedData] = useState(data);
    const [activeClasses, setActiveClasses] = useState([]);
    const [ticksFormat, setTicksFormat] = useState({});
    const [classSelectorDivRef, classSelectorDivDimensions] = useMeasure();
    const [hoveredClassSelectorItemIndex, setHoveredClassSelectorItemIndex] = useState(-1);
    const [initialSetup, setInitialSetup] = useState(true);

    // add event listeners
    useEffect(() => {

        // listen for language changes
        function language_change() {
            set_language(localStorage.getItem("language"));
        }
        window.addEventListener('language_change', language_change);

        // do some cleanup (i.e., remove the event listeners if this component ends up being unmounted)
        return(() => {
            window.removeEventListener('language_change', language_change);
        })

    }, []);

    useEffect(() => {

        if(data.length === 0)
            return;

        // check which values to maintain
        if(activeClasses.length === 0 && initialSetup){
            setInitialSetup(false);

            let activeClassesAux = []

            for(let i = 0; i < data.length; i++){
                if(data[i]["isActive"])
                    activeClassesAux.push(data[i].id.split(".")[0])

            if(JSON.stringify(activeClassesAux) !== JSON.stringify(activeClasses))
                setActiveClasses(activeClassesAux);

            }
        }

        else{

            let renderedDataAux = []

            for(let i = 0; i < data.length; i++){

                let itemClone = structuredClone(data[i]);

                if(activeClasses.includes(data[i].id.split(".")[0]))
                    itemClone["isActive"] = true;
                else
                    itemClone["isActive"] = false;

                renderedDataAux.push(itemClone);
            }

            if(JSON.stringify(renderedDataAux) !== JSON.stringify(renderedData))
                setRenderedData(renderedDataAux);
        }

        let allPossibleTicks = {};

        for (let i = 0; i < data.length; i++){

            let ticksKeys = data[i]["data"].map((item) => item["x"]);

            for (let j = 0; j < ticksKeys.length; j++){

                if(!(ticksKeys[j] in allPossibleTicks))
                    allPossibleTicks[ticksKeys[j]] = ticksKeys[j];
            }
        }

        // sort all ticks
        allPossibleTicks = sortByDateAndHour(allPossibleTicks);
        let finalTickFormat = {}

        let allPossibleTicksKeys = Object.keys(allPossibleTicks);

        // make that we only show the minimum information that is needed (i.e., avoid repeating days)
        for(let i = 0; i < allPossibleTicksKeys.length; i++){
            
            if(i === 0)
                finalTickFormat[allPossibleTicksKeys[i]] = "(" + allPossibleTicks[allPossibleTicksKeys[i]].split("_")[2] + "/" + allPossibleTicks[allPossibleTicksKeys[i]].split("_")[1] + ") " + allPossibleTicks[allPossibleTicksKeys[i]].split("_")[3] + "h";

            else{
                if(allPossibleTicksKeys[i - 1].split("_").slice(0, -1).join("_") === allPossibleTicksKeys[i].split("_").slice(0, -1).join("_"))
                    finalTickFormat[allPossibleTicksKeys[i]] = allPossibleTicks[allPossibleTicksKeys[i]].split("_").pop() + "h";

                else
                    finalTickFormat[allPossibleTicksKeys[i]] = "(" + allPossibleTicks[allPossibleTicksKeys[i]].split("_")[2] + "/" + allPossibleTicks[allPossibleTicksKeys[i]].split("_")[1] + ") " + allPossibleTicks[allPossibleTicksKeys[i]].split("_")[3] + "h";
            }
        }

        setTicksFormat(finalTickFormat);

    }, [data, activeClasses, initialSetup]);

    useEffect(() => {

        let activeClassesAux = renderedData.filter((item) => item["isActive"]).map((item) => item["id"]) 
        setActiveClasses(activeClassesAux);

    }, [renderedData]);

    const handleClassSelectorItemChange = (targetIndex) => {
        
        let renderedDataAux = structuredClone(renderedData);
        renderedDataAux[targetIndex]["isActive"] = !renderedDataAux[targetIndex]["isActive"];
        
        setRenderedData(renderedDataAux);
    }

    const determineClassSelectorItemBackgroundColor = (item, index) => {

        if(item["isActive"])
            return(theme === "Light" ? "rgba(150, 150, 150, 0.05)" : "rgba(200, 200, 200, 0.1)");

        else if(hoveredClassSelectorItemIndex === index)
            return(theme === "Light" ? "rgba(180, 180, 180, 0.05)" : "rgba(200, 200, 200, 0.05)");

        else
            return("rgba(150, 150, 150, 0.0)");
    }

    const determineClassSelectorItemBorderColor = (item, index) => {

        if(item["isActive"])
            return(theme === "Light" ? "1px solid rgba(75, 75, 75, 0.1)" : "1px solid rgba(255, 255, 255, 0.03)");

        else if(hoveredClassSelectorItemIndex === index)
            return(theme === "Light" ? "1px solid rgba(75, 75, 75, 0.05)" : "1px solid rgba(210, 210, 210, 0.03)");

        else
            return("1px solid rgba(75, 75, 75, 0.0)");
    }

    const formatTooltipDate = (originalString) => {

        const [year, month, day] = originalString.split('_');
        const formattedDate = `${day}/${month}/${year}`;

        return(formattedDate);
    }

    const clearAllLines = () => {

        let renderedDataAux = structuredClone(renderedData);
        
        for(let i = 0; i < renderedDataAux.length; i++){
            renderedDataAux[i]["isActive"] = false;
        }

        setRenderedData(renderedDataAux);
    }

    return(
        <div 
            className = {styles.rootDiv}
            style = {{
                height: parentHeight + "px",
            }}>
            
            <div 
                ref = {classSelectorDivRef}
                className = {styles.classSelectorDiv}
                style = {{
                    borderBottom: (theme === "Light" ? "1px solid rgba(120, 120, 120, 0.1)" : "1px solid rgba(255, 255, 255, 0.1)"),
                }}>

                <div 
                    className = {styles.classSelectorInnerDiv}>

                    <div 
                        onClick = {() => clearAllLines()}
                        className = {styles.clearAllButtonDiv}
                        style = {{
                            backgroundColor: (theme === "Light" ? "rgba(235, 160, 160, 0.1)" : "rgba(219, 132, 132, 0.25)"),
                            border: (theme === "Light" ? "1px solid rgba(247, 96, 96, 0.25)" : "1px solid rgba(247, 96, 96, 0.25)"),
                        }}>

                        <CloseIcon 
                            className = {styles.clearAllButtonIcon}
                            style = {{
                                width: "13px",
                                height: "13px",
                                color: (theme === "Light" ? "rgba(247, 96, 96, 1.0)" : "rgba(247, 96, 96, 1.0)"),
                            }}/>

                        <div
                            className = {styles.clearAllButtonText}
                            style = {{
                                color: (theme === "Light" ? "rgba(247, 96, 96, 1.0)" : "rgba(247, 96, 96, 1.0)"),
                            }}>
                            {json_data.clearAllButtonText}
                        </div>
                    </div>

                    {
                        renderedData.map((item, index) => (
                            <div
                                key = {index}
                                className = {styles.classSelectorItemDiv}
                                onMouseEnter = {() => setHoveredClassSelectorItemIndex(index)}
                                onMouseLeave = {() => setHoveredClassSelectorItemIndex(-1)}
                                onClick = {() => handleClassSelectorItemChange(index)}
                                style = {{
                                    opacity: (item["isActive"] ? "1.0" : (hoveredClassSelectorItemIndex === index ? "0.8" : "0.6")),
                                    backgroundColor: determineClassSelectorItemBackgroundColor(item, index),
                                    border: determineClassSelectorItemBorderColor(item, index)
                                }}>
                                <div 
                                    className = {styles.classSelectorItemColour}
                                    style = {{
                                        backgroundColor: item.color
                                    }}
                                    />
                                <div
                                    className = {styles.classSelectorItemText}
                                    style = {{
                                        color: (theme === "Light" ? "var(--colour_one)" : "rgba(230, 230, 230, 1.0)"),
                                    }}>
                                    {item.id}
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            
            <div 
                className = {styles.lineChartDiv}
                style = {{
                    height: parentHeight - classSelectorDivDimensions["height"] + "px",
                }}>
                <ResponsiveLine
                    data = {renderedData}
                    margin = {{ top: 20, right: 50, bottom: 50, left: 50 }}
                    colors = {renderedData.map((item) => item["isActive"] ? item.color : "rgba(0, 0, 0, 0.0)")}
                    enableArea = {true}
                    areaBlendMode = {"normal"}
                    areaOpacity = {theme === "Light" ? 0.15 : 0.1}
                    yScale = {{
                        type: 'linear',
                        min: 'auto',
                        max: 'auto',
                        stacked: false,
                        reverse: false
                    }}
                    theme = {{
                        fontSize: "11px", 
                        fontFamily: "regular",
                        axis: {
                            ticks: {
                                text: {
                                    fill: (theme === "Light" ? "black" : "rgba(200, 200, 200, 1.0)"),
                                }
                            },
                            legend: {
                                text: {
                                    fontSize: "12px", 
                                    fontFamily: "semibold",
                                    fill: (theme === "Light" ? "black" : "white"),
                                }
                            }
                        },
                        grid: {
                            line: {
                                stroke: (theme === "Light" ? "rgba(225, 225, 225, 1.0)" : "rgba(50, 50, 50, 0.65)"),
                            }
                        },
                        crosshair: {
                            line: {
                                stroke: (theme === "Light" ? "rgba(165, 165, 165, 1.0)" : "rgba(255, 255, 255, 0.35)"),
                                strokeWidth: 1,
                            },
                        }
                    }}
                    tooltip = { item => {
                        if(activeClasses.includes(item.point.id.split(".")[0])){
                            return (
                                <div
                                    className = {styles.tooltipDiv}
                                    style = {{
                                        boxShadow: (theme === "Light" ? "rgba(149, 157, 165, 0.2) 0px 8px 24px" : "rgba(133, 51, 255, 0.1) 0px 4px 20px"),
                                        background: (theme === "Light" ? "white" : "rgba(26, 31, 38, 1.0)"),
                                        border: "1px solid " + (theme === "Light" ? "rgba(200, 200, 200, 1.0)" : "rgba(42, 46, 53, 1.0)"),
                                        color: (theme === "Light" ? "black" : "white"),
                                    }}>
                                    <div className = {styles.tooltipTopTextDiv}>
                                        <div className = {styles.tooltipPrefix}>{item.point.id.split(".")[0] + ": "}</div>
                                        <div className = {styles.tooltipY}>{item.point.data.y}</div>
                                    </div>
                                    <div className = {styles.tooltipX}>{"(" + formatTooltipDate(item.point.data.x) + ", " + item.point.data.x.split("_")[item.point.data.x.split("_").length - 1] + "h)"}</div>
                                </div>
                            );
                        }

                        else  
                            return(<></>);
                    }}
                    yFormat = " >-.2f"
                    axisTop = {null}
                    axisRight = {null}
                    axisBottom = {{
                        tickSize: 5,
                        tickPadding: 7,
                        tickRotation: 0,
                        format: value => value in ticksFormat ? ticksFormat[value] : ""
                    }}
                    axisLeft = {{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        //legend: 'Detections',
                        legendOffset: -40,
                        legendPosition: 'middle'
                    }}
                    pointSize = {8}
                    pointColor = {{ theme: 'background' }}
                    pointBorderWidth = {2}
                    pointBorderColor = {{ from: 'serieColor' }}
                    pointLabelYOffset = {-12}
                    useMesh = {true}
                />
            </div>
        </div>
    );
}

export default LineChart;