import React from "react";
import CheckToken from "./CheckToken";

function AuthValidator(props) {
  // Validate user with the DB

  return (
    <>
      <CheckToken />
    </>
  );
}

export default AuthValidator;
