import styled from "@emotion/styled";

const Heading4Container = styled.h4`
  font-weight: 400;
  margin-left: 0;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  vertical-align: middle;
  max-width: 80vw;
  height: inherit;
`;

export default Heading4Container;
