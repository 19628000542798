import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import React, { useState, useEffect } from "react";
import FormControl from '@mui/material/FormControl';
import styles from "../../../styles/source_menu_general_tab.module.scss";
import json_source_menu_general_tab_en from '../../../jsons/en/source_menu_general_tab_en.json';
import json_source_menu_general_tab_pt from '../../../jsons/pt/source_menu_general_tab_pt.json';

const SourceMenuGeneralTab = (props) => {

    const [language, set_language] = useState(localStorage.getItem("language"));
    const json_data = (language === "English" ? json_source_menu_general_tab_en : json_source_menu_general_tab_pt);

    // add event listeners
    useEffect(() => {

        // listen for language changes
        function language_change() {
            set_language(localStorage.getItem("language"));
        }
        window.addEventListener('language_change', language_change);

        // do some cleanup (i.e., remove the event listeners if this component ends up being unmounted)
        return(() => {
            window.removeEventListener('language_change', language_change);
        })

    }, []);

    const base_input_props_style = { 
        color: (props.theme === "Light" ? "var(--colour_one)" : "rgba(230, 230, 230, 1.0)"),
        borderRadius: "8px",
        fontSize: 13.5 ,
        border: (props.theme === "Light" ? "" : "0.020in solid rgba(80, 80, 80, 0.5)"), 
    };

    const base_input_props_selector_style = { 
        color: (props.theme === "Light" ? "var(--colour_one)" : "rgba(230, 230, 230, 1.0)"),
        borderRadius: "8px",
        fontSize: 13.5 ,
    };

    const base_input_label_style = { 
        color: (props.theme === "Light" ? "var(--colour_one)" : "rgba(200, 200, 200, 1.0)"), 
        fontSize: 13.5 ,
        backgroundColor: (props.theme === "Light" ? "" : "var(--colour_one_dark_mode)"),
        paddingLeft: (props.theme === "Light" ? "" : "5px"),
        paddingRight: (props.theme === "Light" ? "" : "5px"),
    };

    // name textfield states
    const [name, set_name] = useState("");
    const [name_helper_text, set_name_helper_text] = useState("");

    // url textfield state
    const [url, set_url] = useState("");
    const [url_helper_text, set_url_helper_text] = useState("");

    // protocol textfield state
    const [protocol, set_protocol] = useState(json_data.protocol_selector_options[0]);

    // source_group textfield state
    const [source_group, set_source_group] = useState(props.getAvailableSourceGroupsData ? props.getAvailableSourceGroupsData[0]["name"] : "");

    useEffect(() => {

        if(props.send_data_to_parent_now){

            // invalid input for the name field (too many characters)
            if(name.length > 32)
                set_name_helper_text(json_data.name_textfield_too_long_error_text);

            // invalid input for the url field (invalid characters)
            else if(name === "" || name.match("^[A-Za-z0-9]+$") === null)
                set_name_helper_text(json_data.name_textfield_invalid_characters_error_text);

            // valid input for the name field
            else
                set_name_helper_text("");

            // invalid input for the URL field (no characters)
            if(url.length === 0)
                set_url_helper_text(json_data.url_textfield_no_characters_error_text);

            else{

                // valid input for the URL field
                set_url_helper_text("");

                // pass the current data back to the parent component
                const data = {
                    "name" : name,
                    "url" : url,
                    "protocol" : protocol,
                    "source_group" : source_group
                };

                props.pass_data_to_parent(
                    "general",
                    data
                );
            }
            
            // something is wrong, let's tell the parent to wait for the user to fix any errors
            props.pass_data_to_parent(
                "general",
                null
            );
        }

    }, [props.send_data_to_parent_now]);

    useEffect(() => {

        if(props.clear_all_textfields_now){
            set_name("");
            set_name_helper_text("");
            set_url("");
            set_url_helper_text("");

            props.all_textfields_were_cleared()
        }

    }, [props.clear_all_textfields_now]);

    return (
        <div className = {styles.root}>
            <div className = {styles.text_field_div}>
                <TextField 
                    onChange = {(e) => set_name(e.target.value)}
                    className = {styles.text_field} 
                    id = "outlined-basic" 
                    error = {name_helper_text !== "" ? true : false}
                    InputProps = {{ style: base_input_props_style }}
                    InputLabelProps = {{ style: base_input_label_style }}
                    helperText = {name_helper_text}
                    label = {json_data.name_textfield_hint} 
                    value = {props.add_source_menu_style["visibility"] === "visible" && name !== "" ? name : ""}
                    defaultValue = {name}
                    variant = "outlined" 
                    type = "text" />
            </div>

            <div className = {styles.text_field_div}>
                <TextField 
                    onChange = {(e) => set_url(e.target.value)}
                    className = {styles.text_field} 
                    id = "outlined-basic" 
                    error = {url_helper_text !== "" ? true : false}
                    InputProps = {{ style: base_input_props_style }}
                    InputLabelProps = {{ style: base_input_label_style }}
                    helperText = {url_helper_text}
                    label = {json_data.url_textfield_hint} 
                    value = {props.add_source_menu_style["visibility"] === "visible" && url !== "" ? url : ""}
                    defaultValue = {url}
                    variant = "outlined" 
                    type = "text" />
            </div>

            <div className = {styles.selector_div}>
                <FormControl 
                    fullWidth 
                    style = {{
                        border: (props.theme === "Light" ? "" : "0.020in solid rgba(80, 80, 80, 0.5)"), 
                        borderRadius: "8px"
                    }}>
                    <InputLabel inputlabelprops = {{ style: base_input_label_style }}>
                        <div style = {base_input_label_style}>
                            {json_data.protocol_selector_hint}
                        </div>
                    </InputLabel>
                    <Select 
                        onChange = {(e) => set_protocol(e.target.value)}
                        native defaultValue = {json_data.protocol_selector_hint}
                        inputProps={{ style: base_input_props_selector_style }}
                        style = {{ borderRadius: "8px" }}
                        sx = {{
                            '& .MuiSvgIcon-root': {
                                color: (props.theme === "Light" ? "var(--colour_one)" : "rgba(180, 180, 180, 1.0)"),
                            }
                        }}
                        id = "grouped-native-select"
                        label = {json_data.protocol_selector_hint.slice(0, -1)}
                        autoWidth>
                        {json_data.protocol_selector_options.map((option) => (
                            <option key = {json_data.protocol_selector_options.indexOf(option)} value = {option.name}>
                                {option}
                            </option>
                        ))}
                    </Select>
                </FormControl>
            </div>

            <div className = {styles.selector_div}>
                <FormControl 
                    fullWidth 
                    style = {{
                        border: (props.theme === "Light" ? "" : "0.020in solid rgba(80, 80, 80, 0.5)"), 
                        borderRadius: "8px"
                    }}>
                    <InputLabel inputlabelprops = {{ style: base_input_label_style }}>
                        <div style = {base_input_label_style}>
                            {json_data.source_group_selector_hint}
                        </div>
                    </InputLabel>
                    <Select 
                        onChange = {(e) => set_source_group(e.target.value)}
                        native defaultValue = {json_data.source_group_selector_hint}
                        inputProps={{ style: base_input_props_selector_style }}
                        style = {{ borderRadius: "8px" }}
                        sx = {{
                            '& .MuiSvgIcon-root': {
                                color: (props.theme === "Light" ? "var(--secondary_text_colour)" : "rgba(180, 180, 180, 1.0)"),
                            }
                        }}
                        id = "grouped-native-select"
                        label = {json_data.source_group_selector_hint.slice(0, -1)}
                        autoWidth>
                        {/*<option aria-label = {json_data.source_group_selector_hint} value = "" />*/}
                        {props.getAvailableSourceGroupsData ? 
                            props.getAvailableSourceGroupsData.map((group) => (
                                <option key = {props.getAvailableSourceGroupsData.indexOf(group)} value = {group.name}>
                                    {group.name}
                                </option>
                            )) : 
                            <option key = {0} value = {json_data.no_source_group_error_text}>
                                {json_data.no_source_group_error_text}
                            </option>
                        }
                    </Select>
                </FormControl>
            </div>
        </div>
    );
}

export default SourceMenuGeneralTab;