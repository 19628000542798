import styled from "@emotion/styled";

const HomeGuestDivContainer = styled.div`
  height: 100vh;
  width: 100vw;
  position: relative;
  //border: 10px solid red;
`;

export default HomeGuestDivContainer;
