import ReactDOM from 'react-dom';
import { useDispatch } from "react-redux";
import useMeasure from "react-use-measure";
import React, { useState, useEffect } from "react";
import Button from "../reusable_components/Button.js";
import styles from '../../styles/quick_menu.module.scss';
import ClickAwayListener from 'react-click-away-listener';
import { logOutSuccess } from "../../store/slices/userSlice";
import json_quick_menu_en from '../../jsons/en/quick_menu_en.json';
import json_quick_menu_pt from '../../jsons/pt/quick_menu_pt.json';

const QuickMenu = (props) => {

    // variables and states
    const language = localStorage.getItem("language");
    const json_data = (language === "English" ? json_quick_menu_en : json_quick_menu_pt);
    const divider = "//";
    const aux_ref = React.createRef();
    const [second_div_items_hover_state, set_second_div_items_hover_state] = useState(-1);
    const [theme, set_theme] = useState(localStorage.getItem("theme"));
    const [root_ref, root_dimensions] = useMeasure();
    const [window_width, set_window_width] = useState(window.innerWidth);
    const [is_mobile, set_is_mobile] = useState(window.innerWidth < parseInt(localStorage.getItem("desktop_version_min_width")));

    // add event listeners
    useEffect(() => {

        // listen for theme changes
        function theme_change() {
            set_theme(localStorage.getItem("theme"));
        }
        window.addEventListener('theme_change', theme_change);

        // listen for window resizes
        function resize() { 
            set_window_width(window.innerWidth); 
        }
        window.addEventListener('resize', resize);

        // do some cleanup (i.e., remove the event listeners if this component ends up being unmounted)
        return(() => {
            window.removeEventListener('theme_change', theme_change);
            window.removeEventListener('resize', resize);
        })

    }, []);

    useEffect(() => {

        set_is_mobile(window_width < parseInt(localStorage.getItem("desktop_version_min_width")));
    
    }, [window_width]);

    function determine_position_and_height() {
        
        if(is_mobile && (root_dimensions.height !== 0)){

            props.change_quick_menu_style({
                maxHeight: root_dimensions.height + "px",
                visibility: props.quick_menu_style["visibility"],
                position: "fixed",
                top: "48%",
                transform: "translateY(-48%)",
                opacity: props.quick_menu_style["opacity"], 
                transition: props.quick_menu_style["transition"]
            });
        }

        else if(!is_mobile){

            let left_reference_x = ReactDOM.findDOMNode(props.quick_menu_ref.current).getBoundingClientRect().x;
            let left_reference_width = ReactDOM.findDOMNode(props.quick_menu_ref.current).getBoundingClientRect().width
            let left_current_width = ReactDOM.findDOMNode(aux_ref.current).getBoundingClientRect().width;
            
            let left = ((left_reference_x + (left_reference_width / 2))) - (left_current_width / 2) + "px";
            
            if(left !== props.quick_menu_style["left"]){
                props.change_quick_menu_style({
                    visibility: props.quick_menu_style["visibility"], 
                    opacity: props.quick_menu_style["opacity"], 
                    left: left, 
                    top: props.quick_menu_style["top"],
                    position: "absolute",
                    transition: props.quick_menu_style["transition"]
                });
            }
        }
    }

    useEffect(() => {
        determine_position_and_height();
    }, is_mobile ? [root_dimensions.height] : null);

    const dispatch = useDispatch();

    async function handle_logout() {
        dispatch(logOutSuccess());
    }

    const determine_second_div_item_text_color = (item_index) => {
        
        if((second_div_items_hover_state !== -1) && (item_index === json_data.buttons.length - 1))
            return(theme === "Light" ? (second_div_items_hover_state === (json_data.buttons.length - 1) ? "rgba(242, 168, 168, 1.0)" : "rgba(157, 70, 67, 1.0)") : (second_div_items_hover_state === (json_data.buttons.length - 1) ? "rgba(246, 208, 208, 1.0)" : "white"));
        
        return((item_index === json_data.buttons.length - 1) ? (theme === "Light" ? "rgba(157, 70, 67, 1.0)" : "var(--colour_five)") :  (theme === "Light" ? "var(--colour_one)" : "var(--colour_five)"))
    }

    const determine_second_div_item_background_color = (item_index) => {
        
        if(second_div_items_hover_state === item_index)
            return((item_index === json_data.buttons.length - 1) ? "rgba(125, 75, 70, 1.0)" : (theme === "Light" ? "rgba(150, 150, 150, 0.08)" : "rgba(200, 200, 200, 0.1)"))
        
        if(second_div_items_hover_state === -1 || second_div_items_hover_state !== item_index)
            return(theme === "Light" ? "rgba(150, 150, 150, 0.08)" : "rgba(200, 200, 200, 0.02)")
    }

    const determine_second_div_item_border = (item_index) => {

        if(second_div_items_hover_state === item_index)
            return((item_index === json_data.buttons.length - 1) ? "1px solid rgba(150, 150, 150, 0.0)" : (theme === "Light" ? "1px solid rgba(75, 75, 75, 0.25)" : "1px solid rgba(255, 255, 255, 0.13)"));

        return("1px solid rgba(75, 75, 75, 0.0)");
    }

    const determine_settings_button_icon_url = (item, item_index) => {

        if(second_div_items_hover_state === item_index)
            return(theme === "Light" ? ((json_data.buttons.indexOf(item) === (json_data.buttons.length - 1)) ? item.icon_white : item.icon_blue) : item.icon_white);

        return(theme === "Light" ? item.icon_blue : ((json_data.buttons.indexOf(item) === (json_data.buttons.length - 1)) ? item.icon_dark : item.icon_white));
        
    }

    const handle_click = (item_index) => {

        if(props.quick_menu_style["visibility"] === "hidden") return;

        if(item_index === 0){ // edit profile
            // TODO:
        }

        else if(item_index === 1){ // request support
            // TODO:
        }

        else if(item_index === 2){ // send feedback
            // TODO:
        }

        else if(item_index === 3){ // logout
            props.hide_menu_background()
            localStorage.setItem("current_url", "/");
            window.dispatchEvent(new Event("current_url_change"));
            setTimeout(handle_logout, 150);
        }
    }

    const handle_blog_click = () => {
        window.open("https://www.deepneuronic.com/blog", "_blank");
    }

    const handle_click_away = () => {
        return;
    }

    return (
        <div
        className = {is_mobile ? styles.super_root : null} 
        ref = {is_mobile ? root_ref : null}
        style = {is_mobile ? props.quick_menu_style : null}>

            <ClickAwayListener onClickAway = {props.close_quick_menu()}>
                <div 
                    onScroll = {() => props.close_quick_menu()}
                    onMouseLeave = {() => props.close_quick_menu()}
                    className = {styles.root}
                    style = {!is_mobile ? props.quick_menu_style : null}>

                        <div 
                            className = {styles.root_inner} 
                            ref = {aux_ref}
                            style = {{
                                width: (is_mobile ? "" : "300px"),
                                backgroundColor: (theme === "Light" ? "rgba(245, 245, 245, 1.0)" : "var(--colour_one_dark_mode)"),
                                border: (theme === "Light" ? "0.020in solid var(--colour_two)" : "0.020in solid rgba(80, 80, 80, 0.55)"),
                                boxShadow: (theme === "Light" ? "rgba(100, 100, 100, 0.35) 0px 0px 10px" : "rgba(10, 15, 21, 0.75) 0px 8px 24px"),
                            }}>

                            <div className = {styles.hover_square} />

                            <div 
                                className = {styles.close_button_div}
                                style = {{
                                    display: (is_mobile ? "" : "none"),
                                    backgroundColor: (theme === "Light" ? "var(--colour_one)" : "rgba(190, 190, 190, 1.0)")
                                }}
                                onClick = {() => window.dispatchEvent(new Event("all_menus_should_disappear"))}
                                >
                                <div className = {styles.vertical_align}>
                                    <div 
                                        className = {styles.close_button} 
                                        style = {{
                                            backgroundImage: (theme === "Light" ? "url(\"/media/icons/close_white_2.png\")" : "url(\"/media/icons/close_4.png\")")
                                        }}/>
                                </div>
                            </div>

                            <div className = {styles.top_bar_div}>
                                
                                <div 
                                    className = {styles.version_text_div}
                                    style = {{
                                        backgroundColor: (theme === "Light" ? "rgba(230, 230, 230, 0.55)" : "rgba(80, 80, 80, 0.25)"),
                                    }}>
                                        <div 
                                            className = {styles.version_text}
                                            style = {{
                                                color: (theme === "Light" ? "rgba(140, 140, 140, 1.0)" : "rgba(215, 215, 215, 1.0)"),
                                            }}>
                                            {json_data.version_text + localStorage.getItem("version")}
                                        </div>
                                </div>
                                
                                <div 
                                    className = {styles.top_bar}
                                    style = {{
                                        opacity: "1.0",
                                        backgroundImage: "url(\"/media/images/menu_pattern_THEME_mode.jpg\")".replace("THEME", theme.toLowerCase()),
                                    }}/>
                                <div 
                                    className = {styles.top_bar_fade}
                                    style = {{
                                        opacity: "1.0",
                                        backgroundImage: (theme === "Light" ? "linear-gradient(to bottom, rgba(235,235,235,0.55), rgba(245, 245, 245, 1.0))" : "linear-gradient(to bottom, rgba(20, 25, 32, 0.6), var(--colour_one_dark_mode))")
                                    }}/>

                                <div className = {styles.user_image_div}>
                                    <div className = {styles.user_image} />
                                </div>
                            </div>

                            <div className = {styles.first_div}>
                                <div className = {styles.greetings_text_div}>
                                    <div className = {styles.vertical_align}>
                                        <div className = {styles.greetings_text_inner_div}>
                                            <div 
                                                className = {styles.greetings_text_first_line}
                                                style = {{
                                                    color: (theme === "Light" ? "var(--colour_one)" : "rgba(230, 230, 230, 1.0)")
                                                }}>{json_data.greetings_text_first_line}</div>
                                            <div className = {styles.greetings_text_second_line_div}>
                                                <span 
                                                    className = {styles.greetings_text_second_line_username}
                                                    style = {{
                                                        color: (theme === "Light" ? "var(--colour_one)" : "rgba(230, 230, 230, 1.0)")
                                                    }}>{localStorage.getItem("current_user")}</span>
                                                <span 
                                                    className = {styles.greetings_text_second_line_others}
                                                    style = {{
                                                        color: (theme === "Light" ? "var(--colour_one)" : "rgba(230, 230, 230, 1.0)")
                                                    }}>{json_data.greetings_text_second_line}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            
                            <div className = {styles.second_div}>
                                {json_data.buttons.map((item) => (
                                    <div 
                                        onClick = {() => handle_click(json_data.buttons.indexOf(item))}
                                        onMouseEnter = {() => set_second_div_items_hover_state(json_data.buttons.indexOf(item))}
                                        onMouseLeave = {() => set_second_div_items_hover_state(-1)}
                                        key = {json_data.buttons.indexOf(item)}
                                        style = {{
                                            cursor: (props.quick_menu_style["visibility"] === "hidden" ? "normal" : "pointer"),
                                            flex: ((json_data.buttons.indexOf(item) === (json_data.buttons.length - 1)) ? "3" : "1"),
                                            flexBasis: ((json_data.buttons.indexOf(item) === (json_data.buttons.length - 1)) ? "100%" : "20%"),
                                            height: ((json_data.buttons.indexOf(item) === (json_data.buttons.length - 1)) ? "" : "65px"),
                                        }}>
                                        <Button 
                                            orientation = {(json_data.buttons.indexOf(item) === (json_data.buttons.length - 1)) ? "horizontal" : "vertical"}
                                            text = {item.title}
                                            text_size = {"11.5px"}
                                            text_family = {((json_data.buttons.indexOf(item) === (json_data.buttons.length - 1)) ? "semibold" : "regular")}
                                            text_color = {determine_second_div_item_text_color(json_data.buttons.indexOf(item))}
                                            border = {determine_second_div_item_border(json_data.buttons.indexOf(item))}
                                            background_color = {determine_second_div_item_background_color(json_data.buttons.indexOf(item))}
                                            icon_url = {determine_settings_button_icon_url(item, json_data.buttons.indexOf(item))}
                                            icon_size = {"18px"}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    
                    </div>
            </ClickAwayListener>
        </div>
    );
}

export default QuickMenu;