import { useDispatch } from "react-redux";
import React, { useEffect, useState, useRef } from "react";
import { SocketIOComponent } from "./SocketIOComponent.js";
import { are_the_two_lists_equal } from "../../utilities/Utils.js";
import { SourceViewMainSortable } from "./SourceViewMainSortable.js";
import { useLongPress, LongPressDetectEvents } from "use-long-press";
import { SourceInfoFullscreenDiv } from "./SourceInfoFullscreenDiv.js";
import styles from "../../../styles/multi_source_view_main.module.scss";
import { useGetAvailableSourcesQuery } from "../../../services/SourceService";
import json_multi_source_view_main_en from '../../../jsons/en/multi_source_view_main_en.json';
import json_multi_source_view_main_pt from '../../../jsons/pt/multi_source_view_main_pt.json';
import json_settings_menu_en from '../../../jsons/en/settings_menu_en.json';
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  DragOverlay,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
} from '@dnd-kit/sortable';

import { DndProvider } from 'react-dnd-multi-backend'
import { HTML5toTouch } from 'rdndmb-html5-to-touch'

function MultiSourceViewMain(props) {

  // variables and states
  const [language, set_language] = useState(localStorage.getItem("language"));
  const json_data = (language === "English" ? json_multi_source_view_main_en : json_multi_source_view_main_pt);
  const [theme, set_theme] = useState(localStorage.getItem("theme"));
  const [activeId, setActiveId] = useState(null);
  const [hovered_id, set_hovered_id] = useState(null);
  const source_info_fullscreen_div_ref = useRef(null);
  const [columns, set_columns] = useState(4);
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
  const [window_width, set_window_width] = useState(window.innerWidth);
  const maximise_space_text = json_settings_menu_en.tabs[0].items[3].options[0].text;
  const mobile_navbar_height = getComputedStyle(document.documentElement).getPropertyValue('--mobile_navbar_height');
  const [navbar_behaviour, set_navbar_behaviour] = useState(localStorage.getItem("navbar_behaviour"));
  const dispatch = useDispatch();
  const [header_menus_visibility, set_header_menus_visibility] = useState(localStorage.getItem("header_menus_visibility"));
  
  const [visible_sources, set_visible_sources] = useState([]);
  const [hidden_sources, set_hidden_sources] = useState(localStorage.getItem("hidden_sources") !== null ? JSON.parse(localStorage.getItem("hidden_sources")) : []);

  const [info_div_status, set_info_div_status] = useState(localStorage.getItem("info_div_status"));
  const [fullscreen_div_status, set_fullscreen_div_status] = useState(localStorage.getItem("fullscreen_div_status"));
  const [is_mobile, set_is_mobile] = useState(window.innerWidth < parseInt(localStorage.getItem("desktop_version_min_width")));
  const [enter_wiggle_mode, set_enter_wiggle_mode] = useState(0);

  // add event listeners
  useEffect(() => {

    // listen for window resizes
    function resize() { 
      set_window_width(window.innerWidth); 
    }
    window.addEventListener('resize', resize);

    // listen for theme changes
    function theme_change() {
      set_theme(localStorage.getItem("theme"));
    }
    window.addEventListener('theme_change', theme_change);

    // listen for language changes
    function language_change() {
      set_language(localStorage.getItem("language"));
    }
    window.addEventListener('language_change', language_change);

    // listen for info div and fullscreen div status changes
    function info_or_fullscreen_div_status_change() {
      set_info_div_status(localStorage.getItem("info_div_status"));
      set_fullscreen_div_status(localStorage.getItem("fullscreen_div_status"));
    }
    window.addEventListener('info_or_fullscreen_div_status_change', info_or_fullscreen_div_status_change);

    // listen for header menu visibility changes
    function header_menus_visibility_change() {
      set_header_menus_visibility(localStorage.getItem("header_menus_visibility"));
    }
    window.addEventListener('header_menus_visibility_change', header_menus_visibility_change);

    // listen for navbar behaviour changes
    function navbar_behaviour_change() {
      set_navbar_behaviour(localStorage.getItem("navbar_behaviour"));
    }
    window.addEventListener('navbar_behaviour_change', navbar_behaviour_change);

    // listen for source changes
    function sources_change() {

      // visible sources
      let visible_sources_aux = localStorage.getItem("visible_sources") !== null ? JSON.parse(localStorage.getItem("visible_sources")) : [];
      if(!are_the_two_lists_equal(visible_sources_aux, visible_sources))
        set_visible_sources(visible_sources_aux);

      // hidden sources
      let hidden_sources_aux = localStorage.getItem("hidden_sources") !== null ? JSON.parse(localStorage.getItem("hidden_sources")) : [];
      if(!are_the_two_lists_equal(hidden_sources_aux, hidden_sources))
        set_hidden_sources(hidden_sources_aux);
    
    }
    window.addEventListener('sources_change', sources_change);

    // listen for grid_layout changes (in the settings menu)
    function grid_layout_change() {

      let new_source_span_override = null;
      
      // the user wants every source to maximise the space that is available, so every source should now have a span of "2"
      if(localStorage.getItem("grid_layout") === maximise_space_text)
        new_source_span_override = "2";
      
      // the user wants every source to snap to the grid, so every source should now have a span of "1"
      else
        new_source_span_override = "1";

      let span_sources_to_save = {}
      let visible_sources = JSON.parse(localStorage.getItem("visible_sources"));

      for(let i = 0; i < visible_sources.length; i++){
        span_sources_to_save[localStorage.getItem("current_user") + "_" + visible_sources[i]["unique_identifier"]] = new_source_span_override;
      }
      
      localStorage.setItem("saved_span_sources", JSON.stringify(span_sources_to_save));
      window.dispatchEvent(new Event("source_grid_span_change"));
    }
    window.addEventListener('grid_layout_change', grid_layout_change);

    // do some cleanup (i.e., remove the event listeners if this component ends up being unmounted)
    return(() => {
      window.removeEventListener('resize', resize);
      window.removeEventListener('theme_change', theme_change);
      window.removeEventListener('language_change', language_change);
      window.removeEventListener('info_or_fullscreen_div_status_change', info_or_fullscreen_div_status_change);
      window.removeEventListener('header_menus_visibility_change', header_menus_visibility_change);
      window.removeEventListener('navbar_behaviour_change', navbar_behaviour_change);
      window.removeEventListener('sources_change', sources_change);
      window.removeEventListener('grid_layout_change', grid_layout_change);
    })

  }, []);

  const {
    data: getAvailableSourceData,
    //isError: getAvailableSourceIsError,
    //isLoading: getAvailableSourceIsLoading,
  } = useGetAvailableSourcesQuery({
    linkedOnly: 1,
    activeOnly: 1,
    includeLinkedProperty: 1,
  });

  useEffect(() => {
    
    if(window_width >= 950) 
      set_columns(4);
    
    else if(window_width < 950 && window_width >= 850) 
      set_columns(3);
    
    else if(window_width < 850 && window_width >= 500) 
      set_columns(2);
    
    else if(window_width < 500){
      set_columns(1);

      // make sure that every source stays small
      localStorage.setItem(json_settings_menu_en.tabs[0].items[3].title.toLowerCase().replace(" (s)", "").replaceAll(" ", "_"), json_settings_menu_en.tabs[0].items[3].options[1].text);
      window.dispatchEvent(new Event("grid_layout_change"));
    } 

  }, [window_width]);

  useEffect(() => {

    let saved_visible_sources = (localStorage.getItem("visible_sources") === null ? [] : JSON.parse(localStorage.getItem("visible_sources")));
    let saved_hidden_sources = (localStorage.getItem("hidden_sources") === null ? [] : JSON.parse(localStorage.getItem("hidden_sources")));

    if(saved_visible_sources.length === 0 && saved_hidden_sources.length === 0 && getAvailableSourceData){

      if(!are_the_two_lists_equal(getAvailableSourceData, visible_sources)){
        set_visible_sources(getAvailableSourceData);
        localStorage.setItem("visible_sources", JSON.stringify(getAvailableSourceData));
        window.dispatchEvent(new Event("sources_change"));
      }
    }

    else if (saved_visible_sources.length !== 0 || saved_hidden_sources.length !== 0) {

      if(!are_the_two_lists_equal(saved_visible_sources, visible_sources)){
        set_visible_sources(saved_visible_sources);
        window.dispatchEvent(new Event("sources_change"));
      }
    }

    else
      set_visible_sources([]);
  
  // eslint-disable-next-line
  }, [visible_sources, getAvailableSourceData]);

  useEffect(() => {

    let is_mobile_aux = window_width < parseInt(localStorage.getItem("desktop_version_min_width"));
    
    set_is_mobile(is_mobile_aux);

    if(is_mobile_aux)
      change_draggable_status("deactivate");

  }, [window_width]);

  const change_draggable_status = (activate_or_deactivate) => {
    if(activate_or_deactivate === "activate")
      localStorage.setItem("draggable_should_be_disabled", false);

    else
      localStorage.setItem("draggable_should_be_disabled", true);

      window.dispatchEvent(new Event("draggable_should_be_disabled_change"));
  }

  const long_press_callback = (touched_source_index) => {
    if(is_mobile){
      change_draggable_status("activate");
      set_enter_wiggle_mode(1);
      //handleDragStart(null, touched_source_index.context);
    }
  };

  window.addEventListener('contextmenu', function(e) {
    if(is_mobile)
      e.preventDefault();
  }, true);

  const bind = useLongPress((event, touched_source_index) => long_press_callback(touched_source_index), {
    //onStart: (event, touched_source_index) => long_press_callback(touched_source_index),
    //onFinish: (event, touched_source_index) => alert("long press finished"),
    //onCancel: (event, touched_source_index) => change_draggable_status("deactivate"),
    filterEvents: (event) => true, // All events can potentially trigger long press
    threshold: 1000,
    captureEvent: true,
    cancelOnMovement: true,
    detect: LongPressDetectEvents.BOTH
  });

  return (
    <div
    style = {{
      height: "fit-content",
      minHeight: (is_mobile ? "calc(95vh - var(--mobile_navbar_height) - var(--mobile_footer_height))" : "calc(100vh - " + (navbar_behaviour === "Collapsed" ? "var(--collapsed_navbar_height)": "var(--navbar_height)") + ")"),
      //width: "100vw",
      position: "absolute",
      zIndex: (header_menus_visibility === "visible" ? "10" : "3000"),
      top: (is_mobile ? (parseInt(mobile_navbar_height.replace("px", "")) * 1.9) + "px" : navbar_behaviour === "Collapsed" ? "var(--collapsed_navbar_height)" : "var(--navbar_height)"),
      left: "0",
      right: "0",
      bottom: "20px",
      margin: "0 auto",
      overflow: "visible",
      //border: "1px solid green",
    }}>

      <div 
        className = {styles.wiggle_mode_popup}
        style = {{
          visibility: (is_mobile ? (enter_wiggle_mode ? "visible" : "hidden") : "hidden"),
          opacity: (is_mobile ? (enter_wiggle_mode ? "1.0" : "0.0") : "0.0"),
          backgroundColor: (theme === "Light" ? "var(--colour_one)" : "rgba(255, 255, 255, 1.0)"),
        }}>
        <div 
          className = {styles.wiggle_mode_popup_text}
          style = {{
            color: (theme === "Light" ? "rgba(255, 255, 255, 1.0)" : "var(--colour_one_dark_mode)"),
          }}>
          {json_data.wiggle_mode_popup_text}
        </div>
      </div>
      
      {/* source layout */}
      <div style = {{
        margin: "0 auto",
        position: "relative",
        width: "100%",
        opacity: ((info_div_status === "visible" || fullscreen_div_status === "visible") ? "0.0" : "1.0"),
        transition: "all 0.2s ease-in-out",
        //border: "1px solid red",
        }}>
          <DndProvider options = {HTML5toTouch}>
            <DndContext
              sensors = {sensors}
              collisionDetection = {closestCenter}
              onDragStart = {handleDragStart}
              onDragEnd = {handleDragEnd}
              onDragCancel = {handleDragCancel}
            >
              <SortableContext items = {visible_sources} strategy = {rectSortingStrategy}>
                <div 
                  className = {styles.container}
                  style = {{
                    gridTemplateColumns: `repeat(${columns}, 1fr)`,
                    width: "95%",
                    margin: "0 auto",
                    marginTop: (is_mobile ? "" : "20px"),
                  }}>
                  {visible_sources.length !== 0 ? 
                  visible_sources.map((item, index) => (
                    <SourceViewMainSortable
                      {...bind(index)}
                      className = {styles.source_view_main_sortable}
                      enter_wiggle_mode = {enter_wiggle_mode}
                      onMouseEnter = {() => set_hovered_id(item)}
                      onMouseLeave = {() => set_hovered_id(null)}
                      key = {item["unique_identifier"]} 
                      item = {item}
                      index = {index}
                      active_id = {activeId}
                      unique_identifier = {item["unique_identifier"]}
                      sources_image_data = {props.sources_image_data}
                      change_sources_image_data = {props.change_sources_image_data}
                      visible_sources = {visible_sources}
                      current_source_counter = {visible_sources.length.toString()}
                      ref = {source_info_fullscreen_div_ref}
                    />
                  )) : <></>}
                </div>
              </SortableContext>
            </DndContext>
          </DndProvider>
          
          <div className = {styles.hidden_sources_div}>
            {hidden_sources.map((item) => (
              <SocketIOComponent 
                key = {hidden_sources.indexOf(item)}
                is_being_dragged_prop = {false}
                sources_image_data = {props.sources_image_data}
                change_sources_image_data = {props.change_sources_image_data}
                grid_span = {1}
                position = {hidden_sources.indexOf(item)}
                source_list = {item["name"]}
                currentSource = {item["unique_identifier"]}
                setCurrentSource = {null}
                dispatch = {dispatch}
                currentSourceCounter = {hidden_sources.length}
                is_visible = {false}
                change_anomaly_detected = {null}
                active_id = {null}
              />
            ))}
          </div>

          <div style = {{
            display: (is_mobile ? "" : "none"),
            marginTop: "5px",
            height: "var(--mobile_footer_height)",
            width: "100vw",
          }}></div>
      </div>

      {/* info div and fullscreen div */}
      <SourceInfoFullscreenDiv ref = {source_info_fullscreen_div_ref}/>
    </div>
  );

  function handleDragStart(event, touched_source_index) {
    
    if(event === null){
      localStorage.setItem("clicked_source", touched_source_index);
      window.dispatchEvent(new Event("clicked_source_change"));
      setActiveId(visible_sources[touched_source_index]["unique_identifier"]);
    }

    else{
      localStorage.setItem("clicked_source", visible_sources.indexOf(event.active.id));
      window.dispatchEvent(new Event("clicked_source_change"));
      setActiveId(event.active.id["unique_identifier"]);
    }
  }

  function handleDragEnd(event) {
    
    const {active, over} = event;

    if (active.id !== over.id) {

      var visible_sources_aux = visible_sources.slice();
      
      const oldIndex = visible_sources_aux.indexOf(active.id);
      const newIndex = visible_sources_aux.indexOf(over.id);

      visible_sources_aux = arrayMove(visible_sources_aux, oldIndex, newIndex);
      
      set_visible_sources(visible_sources_aux);

      localStorage.setItem("visible_sources", JSON.stringify(visible_sources_aux));
      window.dispatchEvent(new Event("sources_change"));
    }
    
    localStorage.setItem("clicked_source", "-1");
    window.dispatchEvent(new Event("clicked_source_change"));

    setActiveId(null);

    if(is_mobile){
      change_draggable_status("deactivate");
      set_enter_wiggle_mode(0);
    }
  }

  function handleDragCancel() {
    if(is_mobile){
      change_draggable_status("deactivate");
      set_enter_wiggle_mode(0);
    }
  }

}

export default MultiSourceViewMain;