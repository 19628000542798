import React from 'react';
import { useState, useEffect } from "react";
import styles from '../../styles/socials.module.scss';
import json_socials from '../../jsons/socials.json';

function Socials(props) {

    // variables and states
    const [theme, set_theme] = useState(localStorage.getItem("theme"));

    // add event listeners
    useEffect(() => {

        // listen for theme changes
        function theme_change() {
            set_theme(localStorage.getItem("theme"));
        }
        window.addEventListener('theme_change', theme_change);

        // do some cleanup (i.e., remove the event listeners if this component ends up being unmounted)
        return(() => {
            window.removeEventListener('theme_change', theme_change);
        })

    }, []);

    return (
        <div className = {styles.root}>
            {json_socials.items.map((item) => (
                <div 
                    className = {styles.item_div}
                    title = {item.description}
                    style = {{
                        border: (theme === "Light" ? "1px solid var(--colour_one)" : "1px solid var(--colour_four_dark_mode)"),
                        backgroundColor: (theme === "Light" ? "var(--colour_one)" : "var(--colour_one_dark_mode)"),
                    }}
                    onClick = {() => window.open(item.link, "_blank")} 
                    key = {json_socials.items.indexOf(item)}
                    >
                    <div className = {styles.vertical_align} style = {{height: "100%"}}>
                        <div 
                            className = {styles.item_div_icon} 
                            style = {{
                                backgroundImage: "url(\"" + (theme === "Light" ? item.icon : item.icon.replace("_blue", "")) + "\")", 
                            }}>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Socials;