import React from "react";
import HomeGuest from "./HomeGuest";
import { useNavigate } from "react-router-dom";

function Home(props) {

  let navigate = useNavigate();

  const homeContent = <HomeGuest />;

  window.addEventListener("logged_in_change", () => {
    navigate('/dashboard/');
  });

  return <>{homeContent}</>;
}

export default Home;
