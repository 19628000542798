import React from "react";
import Socials from "./Socials";
import LoginMenu from "./LoginMenu";
import { useState, useEffect } from "react";
import HeaderLogos from "../header/HeaderLogos";
import HomeGuestDivContainer from "../../containers/Divs/HomeGuest/HomeGuestDivContainer";
import HomeGuestDivContainerBackgroundImage from "../../containers/Divs/HomeGuest/HomeGuestDivContainerBackgroundImage";
import LanguageAndThemeSelector from "./LanguageAndThemeSelector";

function HomeGuest(props) {
  
  // variables and states
  const [theme, set_theme] = useState(localStorage.getItem("theme"));

  // add event listeners
  useEffect(() => {

    // listen for theme changes
    function theme_change() {
      set_theme(localStorage.getItem("theme"));
    }
    window.addEventListener('theme_change', theme_change);

    // do some cleanup (i.e., remove the event listeners if this component ends up being unmounted)
    return(() => {
      window.removeEventListener('theme_change', theme_change);
    })

  }, []);
  
  return (
    <HomeGuestDivContainer>

      <div style = {{
        position: "fixed",
        top: "15px",
        zIndex: "300",
        width: "100vw",
      }}>
        <HeaderLogos current_url = {"/"} />
      </div>

      <LoginMenu 
        global_communication_channel = {props.global_communication_channel}
        send_via_global_communication_channel = {props.send_via_global_communication_channel}
      />

      <HomeGuestDivContainerBackgroundImage style = {{
        backgroundImage: "url(\"media/images/background_9_" + theme.toLowerCase() + "_mode.jpg\")"
      }} />

      <div style = {{width: "100%"}}>
        <Socials 
          global_communication_channel = {props.global_communication_channel}
          send_via_global_communication_channel = {props.send_via_global_communication_channel}
        />
      </div>

      <div style = {{
        position: "absolute",
        bottom: "15px",
        right: "15px",
        zIndex: "9000",
      }}>
        <LanguageAndThemeSelector />
      </div>

    </HomeGuestDivContainer>
  );
}

export default HomeGuest;