import Papa from "papaparse";
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { io } from "socket.io-client";
import MUITable from './Visuals/Table';
import Tabs from '@mui/material/Tabs';
import PieChart from './Visuals/PieChart';
import useMeasure from "react-use-measure";
import LineChart from './Visuals/LineChart';
import React, { useEffect, useState } from "react";
import REACT_APP_API_URL from "../../../config/Api";
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from "../../../styles/statistics_component.module.scss";
import json_statistics_component_en from '../../../jsons/en/statistics_component_en.json';
import json_statistics_component_pt from '../../../jsons/pt/statistics_component_pt.json';

const StatisticsComponent = (props) => {

    // variables and states
    const [language, set_language] = useState(localStorage.getItem("language"));
    const json_data = (language === "English" ? json_statistics_component_en : json_statistics_component_pt);
    const [theme, set_theme] = useState(localStorage.getItem("theme"));
    const [active_tab, set_active_tab] = useState(0);
    const [contentDivRef, contentDivDimensions] = useMeasure();
    const [tabsRef, tabsDimensions] = useMeasure();
    const [mlAnalysis, setMlAnalysis] = useState({});
    const [pieData, setPieData] = useState([]);
    const [tableData, setTableData] = useState({ "rows": [], "columns": [] });
    const [lineData, setLineData] = useState([]);
    const [tabs, setTabs] = useState(json_data.tabs);
    const [coloursPerclassNameForPlots, setColoursPerclassNameForPlots] = useState({});
    const [current_user, set_current_user] = useState(localStorage.getItem("current_user"));

    //console.log(current_user);

    useEffect(() => {

        function createTableData(id, object, timestamp, name, clip_path, camera_id) {
            let formattedName = name.replace(/_/g, ' ');
            return { id, object, timestamp, name: formattedName, clip_path, camera_id };
        }

        const socketIO = io(REACT_APP_API_URL);

        const interval = setInterval(() => {

            socketIO.emit("obtainAlertData", {
                camera_id: props.source,
                client_name: current_user,
            });

        }, 1000);

        socketIO.on("obtainAlertData", (message, callback) => {

            let retrievedData = JSON.parse(message["data"]);
            
            let tableDataAux = {
                "rows": [],
                "columns": [
                    { id: 'id', label: 'ID', minWidth: 50, align: 'center' },
                    { id: 'object', label: 'Object', minWidth: 125, align: 'center' },
                    { id: 'timestamp', label: 'Timestamp', minWidth: 150, align: 'center' },
                    { id: 'name', label: 'Camera', minWidth: 150, align: 'center' },
                    { id: 'clip_path', label: 'Clip\u00a0Path', minWidth: 50, align: 'center' },
                    //{ id: 'camera_id', label: 'Camera\u00a0ID', minWidth: 170, align: 'center' },
                ]
            }

            for (let i = 0; i < retrievedData.length; i++) {

                tableDataAux["rows"].push(createTableData(
                    retrievedData[i]["id"],
                    retrievedData[i]["object"],
                    retrievedData[i]["timestamp"],
                    retrievedData[i]["name"],
                    retrievedData[i]["clip_path"],
                    retrievedData[i]["camera_id"],
                ))
            }

            if(JSON.stringify(tableDataAux) !== JSON.stringify(tableData))
                setTableData(tableDataAux);

            callback("Received");
        });

        return () => {
            clearInterval(interval)
            socketIO.disconnect()
        };

    }, [props.source]);

    useEffect(() => {

        // retrieve the .csv file
        Papa.parse("/media/files/colours_per_class_name_for_plots.csv", {
            delimiter: ",",
            download: true,
            complete: function (results) {

                let resultsAux = results.data;
                let coloursPerclassNameForPlotsAux = {};

                for (let i = 0; i < resultsAux.length; i++) {
                    coloursPerclassNameForPlotsAux[resultsAux[i][0]] = "rgba" + resultsAux[i][1].split(" ").join(", ").replace(")", "") + ", 1.0)"
                }

                setColoursPerclassNameForPlots(coloursPerclassNameForPlotsAux)
            },
        });

    }, []);

    // add event listeners
    useEffect(() => {

        visualisationManagerArgumentsChange();

        // listen for theme changes
        function theme_change() {
            set_theme(localStorage.getItem("theme"));
        }
        window.addEventListener('theme_change', theme_change);

        // listen for language changes
        function language_change() {
            set_language(localStorage.getItem("language"));
        }
        window.addEventListener('language_change', language_change);

        function visualisationManagerArgumentsChange() {

            let stuffThatWasStoredAux = localStorage.getItem("visualisationManagerArguments");

            if (stuffThatWasStoredAux === null)
                return;

            let stuffThatWasStored = {};

            if (props.source !== "general") {
                stuffThatWasStored = JSON.parse(stuffThatWasStoredAux)[props.source];

                if (stuffThatWasStored !== undefined && stuffThatWasStored !== null)
                    setMlAnalysis(stuffThatWasStored["mlAnalysis"]);
            }

            else {

                let mergedDict = {}
                Object.entries(JSON.parse(stuffThatWasStoredAux)).forEach(([source, sourceDetails]) => {
                    mergedDict = mergeStats(mergedDict, sourceDetails["mlAnalysis"]);
                });

                setMlAnalysis(mergedDict);
            }
        }
        window.addEventListener('visualisationManagerArgumentsChange', visualisationManagerArgumentsChange);

        // do some cleanup (i.e., remove the event listeners if this component ends up being unmounted)
        return (() => {
            window.removeEventListener('theme_change', theme_change);
            window.removeEventListener('language_change', language_change);
            window.removeEventListener('visualisationManagerArgumentsChange', visualisationManagerArgumentsChange);
        })

    }, [props.source]);

    useEffect(() => {

        if (props.visibleTabs === "all")
            setTabs(json_data.tabs);

        else if (props.visibleTabs === "allButLast")
            setTabs(json_data.tabs.slice(0, -1));

        else if (props.visibleTabs === "last")
            setTabs([json_data.tabs[json_data.tabs.length - 1]]);

    }, [props.visibleTabs, json_data]);

    useEffect(() => {

        // -------------------------------------------------------------------------------------------------------------
        // take care of the pie chart
        // -------------------------------------------------------------------------------------------------------------
        let pieDataAux = []

        if (props.type !== "last" && "stats" in mlAnalysis && "pie_chart • detections_per_class" in mlAnalysis["stats"]) {

            Object.entries(mlAnalysis["stats"]["pie_chart • detections_per_class"]).forEach(([k, v]) => {

                let canAdd = true;

                let match = pieDataAux.filter((item) => item["label"] === (k.includes(" • ") ? k.split(" • ")[0] : k))

                // add the value to the existing entry
                if (match.length !== 0) {
                    pieDataAux[pieDataAux.indexOf(match[0])]["value"] += (typeof (v) === "string" ? parseInt(v) : v)
                    canAdd = false;
                }

                if (canAdd) {

                    let className = k.includes(" • ") ? k.split(" • ")[0] : k

                    pieDataAux.push({
                        "id": className,
                        "label": className,
                        "value": (typeof (v) === "string" ? parseInt(v) : v),
                        "color": (className in coloursPerclassNameForPlots ? coloursPerclassNameForPlots[className] : "rgba(255, 60, 60, 1.0)"),
                    })
                }
            });

            if(JSON.stringify(pieDataAux) !== JSON.stringify(pieData))
                setPieData(pieDataAux);
        }

        // ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
        // take care of the line chart
        // ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
        let lineDataAux = []

        if (props.type !== "allButLast" && "stats" in mlAnalysis && "line_chart • detections_per_class_per_hour" in mlAnalysis["stats"]) {

            let squeezedData = structuredClone(mlAnalysis["stats"]["line_chart • detections_per_class_per_hour"]);

            // put everything in the correct format
            Object.entries(squeezedData).forEach(([hour, hourData]) => {

                Object.entries(hourData).forEach(([className, detections]) => {

                    className = className.includes(" • ") ? className.split(" • ")[0] : className;

                    let existingEntry = lineDataAux.filter((item) => (item["id"] === className))

                    if (existingEntry.length === 1) {
                        lineDataAux[lineDataAux.indexOf(existingEntry[0])]["data"].push({
                            "x": hour,
                            "y": (typeof (detections) === "string" ? parseInt(detections) : detections)
                        })
                    }

                    else {
                        lineDataAux.push({
                            "id": className,
                            "isActive": true,
                            "color": (className in coloursPerclassNameForPlots ? coloursPerclassNameForPlots[className] : "rgba(255, 60, 60, 1.0)"),
                            "data": [
                                {
                                    "x": hour,
                                    "y": (typeof (detections) === "string" ? parseInt(detections) : detections)
                                }
                            ]
                        });
                    }
                });
            });

            if(JSON.stringify(lineDataAux) !== JSON.stringify(lineData))
                setLineData(lineDataAux);
        }

    }, [mlAnalysis, props, coloursPerclassNameForPlots]);

    function mergeStats(obj1, obj2) {
        for (const key in obj2) {

            if (typeof obj2[key] === "object" && obj2[key] !== null) {
                obj1[key] = obj1[key] || {}; // ensure the key exists in obj1
                mergeStats(obj1[key], obj2[key]); // recursive call for nested objects
            }

            // add values for non-object properties
            else
                obj1[key] = parseInt((obj1[key] || 0)) + parseInt(obj2[key]) + "";
        }
        return (obj1);
    }

    const handle_tab_change = (e, new_tab) => {
        set_active_tab(new_tab);
        localStorage.setItem("statistics_component_active_tab", active_tab);
        window.dispatchEvent(new Event("statistics_component_active_tab_change"));
    }

    function tab_props(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <div
            ref={contentDivRef}
            className={styles.root}>

            <Box
                className={styles.rootInnerDiv}
                sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: (props.visibleTabs === "last" ? "0" : "0.1rem solid " + (theme === "Light" ? "rgba(3, 52, 77, 0.25)" : "rgba(150, 150, 150, 0.15)")) }}>
                    <Tabs
                        ref={tabsRef}
                        value={active_tab}
                        onChange={handle_tab_change}
                        aria-label="basic tabs example"
                        TabIndicatorProps={{ style: { height: "1px", background: (theme === "Light" ? ("rgba(3, 52, 77" + (props.visibleTabs === "all" ? ", 1.0)" : ", 0.35)")) : "rgba(200, 200, 200, 0.25)") } }}>
                        {tabs.map((tab) => (
                            <Tab style={{
                                width: (100 / tabs.length) + "%",
                                maxWidth: (100 / tabs.length) + "%",
                                color: (theme === "Light" ? "var(--colour_one)" : (active_tab === tabs.indexOf(tab) ? "rgba(200, 200, 200, 1.0)" : "rgba(150, 150, 150, 1.0)")),
                                fontWeight: (active_tab === tabs.indexOf(tab) ? "bold" : "lighter"),
                            }}
                                key={tabs.indexOf(tab)}
                                label={tab.title} {...tab_props(tabs.indexOf(tab))} />
                        ))}
                    </Tabs>
                </Box>
                <div className={styles.contentDiv}>
                    {tabs.map((tab) => (
                        <div
                            key={tabs.indexOf(tab)}
                            role="tabpanel"
                            hidden={active_tab !== tabs.indexOf(tab)}
                            id={`simple-tabpanel-${tabs.indexOf(tab)}`}
                            aria-labelledby={`simple-tab-${tabs.indexOf(tab)}`}
                            className={styles.tab_root}>
                            {active_tab === tabs.indexOf(tab) && (

                                tab.id === "pie_chart • detections_per_class" ?
                                    <div className={styles.tab_root_inner}>
                                        <PieChart
                                            theme={theme}
                                            data={pieData}
                                            parentHeight={contentDivDimensions["height"] - tabsDimensions["height"]} />
                                    </div>
                                    :
                                    (
                                        tab.id === "table • alerts_history" ?
                                            <div className={styles.tab_root_inner}>
                                                {
                                                    tableData["columns"].length === 0 ?
                                                        <div className={styles.circular_progress_div}>
                                                            <CircularProgress
                                                                size={70}
                                                                style={{
                                                                    color: (theme === "Light" ? "var(--colour_three)" : "rgba(80, 80, 80, 1.0)"),
                                                                }} />
                                                        </div>
                                                        :
                                                        <MUITable
                                                            theme={theme}
                                                            data={tableData}
                                                            parentHeight={contentDivDimensions["height"] - tabsDimensions["height"]} />
                                                }
                                            </div>
                                            :
                                            <div className={styles.tab_root_inner}>
                                                <LineChart
                                                    theme={theme}
                                                    data={lineData}
                                                    parentHeight={contentDivDimensions["height"] - tabsDimensions["height"]} />
                                            </div>
                                    )
                            )}
                        </div>
                    ))}
                </div>
            </Box>
        </div>
    );
}
export default StatisticsComponent;