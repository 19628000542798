import React from "react";
import styles from "./debugVisuals.module.scss";

const DebugVisuals = ( { debugVisuals, parentDivDimensions, configFile } ) => {

    return(
        <div className = {styles.rootDiv}>
            <svg 
                width = {parentDivDimensions["width"]} 
                height = {parentDivDimensions["height"]} 
                viewBox = {`0 0 ${parentDivDimensions["width"]} ${parentDivDimensions["height"]}`}>
                
                {/* polygons, such as delimited areas */}
                {
                    JSON.stringify(configFile) !== "{}" && configFile["VISUALISATION_DETAILS"]["DEBUG_VISUALS"]["DELIMITED_AREAS"]["SHOW"] ?
                        debugVisuals["polygons"].map((item, index) => (
                            <polygon 
                                key = {index}
                                points = {item["POINTS"].map((point) => point.join(',')).join(' ')} 
                                fill = {`rgba(${item["COLOUR"][0]}, ${item["COLOUR"][1]}, ${item["COLOUR"][2]}, 0.3)`}
                                stroke = {`rgba(${item["COLOUR"][0]}, ${item["COLOUR"][1]}, ${item["COLOUR"][2]}, 0.7)`} />
                        )) : <></>
                }

                {/* lines, such as association lines */}
                {
                    JSON.stringify(configFile) !== "{}" && configFile["VISUALISATION_DETAILS"]["DEBUG_VISUALS"]["SHOW_ASSOCIATION_LINES"] ?
                        debugVisuals["lines"].map((item, index) => (
                            <line 
                                key = {index}
                                x1 = {item["POINTS"][0][0] + ""} 
                                y1 = {item["POINTS"][0][1] + ""} 
                                x2 = {item["POINTS"][1][0] + ""} 
                                y2 = {item["POINTS"][1][1] + ""} 
                                stroke = {`rgba(${item["COLOUR"][0]}, ${item["COLOUR"][1]}, ${item["COLOUR"][2]}, 1.0)`}
                                strokeWidth = {1} />
                        )) : <></>
                }
            </svg>
        </div>
    );
}

export default DebugVisuals;