import { useState } from "react";
import QuickMenu from "./QuickMenu";
import HeaderLogos from "./HeaderLogos";
import SourcesMenu from "./SourcesMenu";
import SettingsMenu from "./SettingsMenu";
import AnyAttribute from 'react-any-attr';
import StatisticsMenu from "./StatisticsMenu";
import React, { useEffect, useRef } from "react";
import styles from '../../styles/header.module.scss';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import json_header_en from '../../jsons/en/header_en.json';
import json_header_pt from '../../jsons/pt/header_pt.json';
import EditSourceMenu from '../sources/management/EditSourceMenu';
import AddSourceMenu from "../sources/management/AddSourceMenu.js";
import HeaderContainer from "../../containers/HeaderBar/HeaderContainer";
import LeftSideContainer from "../../containers/HeaderBar/LeftSideContainer";
import Heading4Container from "../../containers/HeaderBar/Heading4Container";
import RightSideContainer from "../../containers/HeaderBar/RightSideContainer";
import HeaderBarContainer from "../../containers/HeaderBar/HeaderBarContainer";

function Header(props) {

  // variables and states
  const language = localStorage.getItem("language");
  const json_data = (language === "English" ? json_header_en : json_header_pt);
  const [theme, set_theme] = useState(localStorage.getItem("theme"));
  const red_alert_colour = getComputedStyle(document.documentElement).getPropertyValue('--red_alert_colour_' + theme.toLowerCase() + "_mode");
  const yellow_alert_colour = getComputedStyle(document.documentElement).getPropertyValue('--yellow_alert_colour_' + theme.toLowerCase() + "_mode");
  const [mouse_is_on_navbar, set_mouse_is_on_navbar] = useState(false);
  const [mouse_is_on_collapsed_navbar, set_mouse_is_on_collapsed_navbar] = useState(false);
  const [mouse_is_on_expanded_navbar, set_mouse_is_on_expanded_navbar] = useState(false);
  const [source_to_edit, set_source_to_edit] = useState(null);
  const [current_user, set_current_user] = useState(localStorage.getItem("current_user"));
  const [navbar_behaviour, set_navbar_behaviour] = useState(localStorage.getItem("navbar_behaviour"));
  const navbar_height = getComputedStyle(document.documentElement).getPropertyValue('--navbar_height');
  const colour_one_dark_mode = getComputedStyle(document.documentElement).getPropertyValue('--colour_one_dark_mode');
  const menu_animation = 5;
  const [add_source_button_hovered_state, set_add_source_button_hovered_state] = useState(false);
  const [anomaly_detected, set_anomaly_detected] = useState("none");
  const [window_width, set_window_width] = useState(window.innerWidth);
  const [is_mobile, set_is_mobile] = useState(window.innerWidth < parseInt(localStorage.getItem("desktop_version_min_width")));
  const [animatedButton, setAnimatedButton] = useState(null);
  const [animateAddSourceButton, setAnimateAddSourceButton] = useState("0");

  // generic input menu style
  const [input_menu_style, set_input_menu_style] = useState(
    {
      visibility: "hidden",
      opacity: "0.0",
      position: "fixed",
      top: (is_mobile ? "calc(var(--mobile_navbar_height) + 20px)" : (navbar_behaviour === "Collapsed" ? "0px" : "var(--navbar_height)")),
      bottom: (is_mobile ? "var(--mobile_footer_height)" : ""),
      transition: "all 0.15s ease-in-out",
    });

  // (add_sources_menu) starting style
  const [add_source_menu_style, set_add_source_menu_style] = useState(input_menu_style);
  
  // (edit_sources_menu) starting style
  const [edit_source_menu_style, set_edit_source_menu_style] = useState(input_menu_style);

  function change_add_source_menu_style(new_style) { set_add_source_menu_style(new_style); }
  function change_edit_source_menu_style(new_style) { set_edit_source_menu_style(new_style); }

  // add event listeners
  useEffect(() => {

    // listen for theme changes
    function theme_change() {
      set_theme(localStorage.getItem("theme"));
      document.body.style.backgroundColor = (localStorage.getItem("theme") === "Light" ? "rgba(215, 215, 215, 1.0)" : "var(--colour_two_dark_mode)");
    }
    window.addEventListener('theme_change', theme_change);

    // listen for window resizes
    function resize() { 
      set_window_width(window.innerWidth);
    }
    window.addEventListener('resize', resize);

    // know when to call the "edit source" menu
    function edit_source_menu_should_appear() {
      localStorage.setItem("header_menus_visibility", "visible");
      window.dispatchEvent(new Event("header_menus_visibility_change"));
      set_source_to_edit(JSON.parse(localStorage.getItem("source_to_edit")))
      
      close_all_menus(["sources"]);
      show_input_menu("edit_source");
    }
    window.addEventListener('edit_source_menu_should_appear', edit_source_menu_should_appear);

    // know when an anomaly has been detected
    function anomaly_detected_change() {
      set_anomaly_detected(localStorage.getItem("alert_colour"));
    }
    window.addEventListener('anomaly_detected_change', anomaly_detected_change);

    // know when all menus should disappear
    function all_menus_should_disappear() {
      close_all_menus([]);
    }
    window.addEventListener('all_menus_should_disappear', all_menus_should_disappear);

    // do some cleanup (i.e., remove the event listeners if this component ends up being unmounted)
    return(() => {
      window.removeEventListener('theme_change', theme_change);
      window.removeEventListener('resize', resize);
      window.removeEventListener('edit_source_menu_should_appear', edit_source_menu_should_appear);
      window.removeEventListener('anomaly_detected_change', anomaly_detected_change);
      window.removeEventListener('all_menus_should_disappear', all_menus_should_disappear);
    })

  }, []);

  useEffect(() => {

    set_is_mobile(window_width < parseInt(localStorage.getItem("desktop_version_min_width")));

  }, [window_width]);

  function hide_menu_background_aux() { close_dropdown_menu("quick"); }

  useEffect(() => {

    if(!mouse_is_on_navbar)
      set_navbar_behaviour(localStorage.getItem("navbar_behaviour"));
  
  // eslint-disable-next-line
  }, [localStorage.getItem("navbar_behaviour")]);

  useEffect(() => {

    if(edit_source_menu_style["visibility"] === "visible" || add_source_menu_style["visibility"] === "visible"){
      localStorage.setItem("header_menus_visibility", "hidden");
      window.dispatchEvent(new Event("header_menus_visibility_change"));
    }

    else{
      localStorage.setItem("header_menus_visibility", (mouse_is_on_navbar ? "visible" : "hidden"));
      window.dispatchEvent(new Event("header_menus_visibility_change"));
    }
  }, [mouse_is_on_navbar, add_source_menu_style, edit_source_menu_style]);

  // generic menu style
  const [starting_dropdown_menu_style, set_starting_dropdown_menu_style] = useState(
    is_mobile ? { 
      visibility: "hidden", 
      position: "fixed",
      opacity: 0.0, 
      transition: "all 0.15s ease-in-out"
    } : 
    {
      visibility: "hidden", 
      opacity: 0.0, 
      left: "0px",
      top: -parseInt(navbar_height.replace("px", "")) + "px",
      transition: "all 0.15s ease-in-out"
  });

  // (sources_menu) starting position and style
  const sources_menu_ref = React.createRef();
  const [sources_menu_style, set_sources_menu_style] = useState(starting_dropdown_menu_style);

  // (statistics_menu) starting position and style
  const statistics_menu_ref = React.createRef();
  const [statistics_menu_style, set_statistics_menu_style] = useState(starting_dropdown_menu_style);

  // (settings_menu) starting position and style
  const settings_menu_ref = React.createRef();
  const [settings_menu_style, set_settings_menu_style] = useState(starting_dropdown_menu_style);

  // (quick_menu) starting position and style
  const quick_menu_ref = React.createRef();
  const [quick_menu_style, set_quick_menu_style] = useState(starting_dropdown_menu_style);
  
  function change_sources_menu_style(new_style) { set_sources_menu_style(new_style); }
  function change_statistics_menu_style(new_style) { set_statistics_menu_style(new_style); }
  function change_settings_menu_style(new_style) { set_settings_menu_style(new_style); }
  function change_quick_menu_style(new_style) { set_quick_menu_style(new_style); }

  function show_dropdown_menu(target) {
    
    // close every menu that may or may not be open
    close_all_menus(is_mobile ? [] : ["add_source", "edit_source"]);

    // compute the new style
    if(is_mobile){
      var new_style_aux = (target === "sources" ? sources_menu_style : (target === "statistics" ? statistics_menu_style : (target === "settings" ? settings_menu_style : quick_menu_style)));
      
      var new_style = {
        maxHeight: new_style_aux["maxHeight"],
        visibility: "visible",
        position: new_style_aux["position"],
        top: "50%",
        transform: "translateY(-50%)",
        opacity: "1.0", 
        transition: new_style_aux["transition"]
      }
    }

    else{
      var new_style = {
        visibility: "visible",
        opacity: 1.0, 
        left: (target === "sources" ? sources_menu_style["left"] : (target === "statistics" ? statistics_menu_style["left"] : (target === "settings" ? settings_menu_style["left"] : quick_menu_style["left"]))), 
        top: menu_animation + "px", 
        transition: "all 0.15s ease-in-out",
      }
    }
    
    setAnimatedButton(target);

    // actually attribute the new style to the correct menu
    if(target === "sources") change_sources_menu_style(new_style);
    else if(target === "statistics") change_statistics_menu_style(new_style);
    else if(target === "settings") change_settings_menu_style(new_style);
    else change_quick_menu_style(new_style);

    // update the local storage
    localStorage.setItem("header_menus_visibility", "visible");
    window.dispatchEvent(new Event("header_menus_visibility_change"));
  }

  function close_dropdown_menu(target, override_and_close) {

    let target_menu_is_hidden = (target === "sources" ? sources_menu_style["visibility"] : (target === "statistics" ? statistics_menu_style["visibility"] : (target === "settings" ? settings_menu_style["visibility"] : quick_menu_style["visibility"])));
    
    if(!override_and_close && (target_menu_is_hidden === "hidden" || mouse_is_on_navbar))
      return;

    if(is_mobile){
      var new_style_aux = (target === "sources" ? sources_menu_style : (target === "statistics" ? statistics_menu_style : (target === "settings" ? settings_menu_style : quick_menu_style)));
      var new_style = {
        maxHeight: new_style_aux["maxHeight"],
        visibility: "hidden",
        position: new_style_aux["position"],
        top: "48%",
        transform: "translateY(-48%)",
        opacity: "0.0", 
        transition: new_style_aux["transition"]
      }
    }

    else{
      var new_style = {
        visibility: "hidden",
        opacity: 0.0,
        left: (target === "sources" ? sources_menu_style["left"] : (target === "statistics" ? statistics_menu_style["left"] : (target === "settings" ? settings_menu_style["left"] : quick_menu_style["left"]))), 
        top: -menu_animation + "px",
        transition: "all 0.15s ease-in-out",
      };
    }

    // actually attribute the new style to the correct menu
    if(target === "sources") change_sources_menu_style(new_style);
    else if(target === "statistics") change_statistics_menu_style(new_style);
    else if(target === "settings") change_settings_menu_style(new_style);
    else change_quick_menu_style(new_style);

    // update the local storage
    localStorage.setItem("header_menus_visibility", "hidden");
    window.dispatchEvent(new Event("header_menus_visibility_change"));
  }

  function close_sources_menu_aux() { close_dropdown_menu("sources"); }
  function close_statistics_menu_aux() { close_dropdown_menu("statistics"); }
  function close_settings_menu_aux() { close_dropdown_menu("settings"); }
  function close_quick_menu_aux() { close_dropdown_menu("quick"); }

  const close_all_menus = (menus_to_keep_open) => {

    // check if other header menus are hidden
    const header_menus = ["sources", "statistics", "settings", "quick"];
    const header_menu_styles = [
      sources_menu_style, statistics_menu_style, 
      settings_menu_style, quick_menu_style
    ];
    var all_header_menus_are_hidden = true;

    for(let i = 0; i < header_menus.length; i++)
      if(header_menu_styles[i]["visibility"] === "visible"){
        all_header_menus_are_hidden = false;
        break;
      }

    // close every input menu, if required
    const input_menus = ["add_source", "edit_source"];
    const input_menu_styles = [add_source_menu_style, edit_source_menu_style]
    
    for(let i = 0; i < input_menus.length; i++)
      if(!menus_to_keep_open.includes(input_menus[i]) && input_menu_styles[i]["visibility"] === "visible" && all_header_menus_are_hidden) close_input_menu(input_menus[i]);

    // close every dropdown menu, if required
    for(let i = 0; i < header_menus.length; i++)
      if(!menus_to_keep_open.includes(header_menus[i])) close_dropdown_menu(header_menus[i], true);
  }
  
  function show_input_menu(target) {
    
    // close every menu that may or may not be open
    if(is_mobile)
      close_all_menus([]);

    // compute the new style
    var new_style_aux = (target === "add_source" ? add_source_menu_style : edit_source_menu_style);
    var new_style = {
      maxHeight: new_style_aux["maxHeight"],
      visibility: "visible",
      position: new_style_aux["position"],
      top: "50%",
      transform: "translateY(-50%)",
      opacity: "1.0", 
      transition: new_style_aux["transition"]
    }
    
    // actually attribute the new style to the correct menu
    if(target === "add_source") change_add_source_menu_style(new_style);
    else if(target === "edit_source") change_edit_source_menu_style(new_style);

    // update the local storage
    localStorage.setItem("header_menus_visibility", "visible");
    window.dispatchEvent(new Event("header_menus_visibility_change"));
  }

  function close_input_menu(target) {
    
    let target_menu_is_hidden = (target === "add_source" ? add_source_menu_style["visibility"] : edit_source_menu_style["visibility"]);
    
    if(target_menu_is_hidden === "hidden")
      return;

    var new_style = {
      maxHeight: (target === "add_source" ? add_source_menu_style["maxHeight"] : edit_source_menu_style["maxHeight"]),
      visibility: "hidden",
      position: "fixed",
      top: "48%",
      transform: "translateY(-48%)",
      opacity: "0.0", 
      transition: (target === "add_source" ? add_source_menu_style["transition"] : edit_source_menu_style["transition"]),
    }

    // actually attribute the new style to the correct menu
    if(target === "add_source") change_add_source_menu_style(new_style);
    else if(target === "edit_source") change_edit_source_menu_style(new_style);

    // update the local storage
    localStorage.setItem("header_menus_visibility", "hidden");
    window.dispatchEvent(new Event("header_menus_visibility_change"));

    if(localStorage.getItem("navbar_behaviour") === "Collapsed")
      set_navbar_behaviour("Collapsed") 
  }

  function close_add_source_menu_aux() { close_input_menu("add_source"); }
  function close_edit_source_menu_aux() { close_input_menu("edit_source"); }

  function is_any_dropdown_menu_open() {
    
    if(sources_menu_style["visibility"] === "visible" 
      || statistics_menu_style["visibility"] === "visible" 
      || settings_menu_style["visibility"] === "visible" 
      || quick_menu_style["visibility"] === "visible"
      ) return(true);

    return(false);
  }

  function is_any_input_menu_open() {
    
    if(add_source_menu_style["visibility"] === "visible" || edit_source_menu_style["visibility"] === "visible")
      return(true);

    return(false);
  }

  const collapsed_style = {
    display: (!is_mobile ? "" : "none"),
    backgroundColor: (theme === "Light" ? "white" : "var(--colour_one_dark_mode)"), 
    boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 8px", 
    borderBottom: (anomaly_detected !== "none" ? ("0.045in solid " + (anomaly_detected === "red" ? red_alert_colour : yellow_alert_colour)) : ("0.020in solid " + (theme === "Light" ? "var(--colour_one)" : "rgba(80, 80, 80, 1.0)"))),
    transform: "translateY(-100px)",
    transition: "all 0.15s ease-in-out",
    zIndex: "404999999999999999",
  }

  const expanded_style = {
    display: (!is_mobile ? "" : "none"),
    marginTop: "0px",
    backgroundColor: (theme === "Light" ? "rgba(245, 245, 245, 1.0)" : "var(--colour_one_dark_mode)"),
    boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 8px", 
    borderBottom: (anomaly_detected !== "none" ? ("0.045in solid " + (anomaly_detected === "red" ? red_alert_colour : yellow_alert_colour)) : ("0.020in solid " + (theme === "Light" ? "var(--colour_three)" : "rgba(80, 80, 80, 0.55)"))),
    transform: "translateY(0)",
    transition: "all 0.15s ease-in-out",
    zIndex: "4999999999999999",
  }

  // use a ref to the "mouse_is_on_collapsed_navbar" state 
  // (i.e., "mouse_is_on_collapsed_navbar_ref" changes inside the timeout function, whereas "mouse_is_on_collapsed_navbar" does not)
  const mouse_is_on_collapsed_navbar_ref = useRef(mouse_is_on_collapsed_navbar);
  mouse_is_on_collapsed_navbar_ref.current = mouse_is_on_collapsed_navbar;

  const mouse_is_on_expanded_navbar_ref = useRef(mouse_is_on_expanded_navbar);
  mouse_is_on_expanded_navbar_ref.current = mouse_is_on_expanded_navbar;
  
  useEffect(() => {
    
    setTimeout(() => { 
      if(mouse_is_on_collapsed_navbar_ref.current)
        set_navbar_behaviour("Expanded");
    }, 300);
    
    if(is_any_input_menu_open() && !(mouse_is_on_expanded_navbar_ref.current) && localStorage.getItem("navbar_behaviour") === "Collapsed")
      set_navbar_behaviour("Collapsed");

    let menus_were_closed = false;
    if((!is_mobile && !mouse_is_on_navbar && !is_any_input_menu_open())){
      
      close_all_menus([]);
      menus_were_closed = true;
    }

    else if(!is_mobile && !mouse_is_on_navbar && is_any_input_menu_open() && is_any_dropdown_menu_open()){
      close_all_menus(["add_source", "edit_source"]);
      menus_were_closed = true;
    }

    if(menus_were_closed && localStorage.getItem("navbar_behaviour") === "Collapsed")      
        set_navbar_behaviour("Collapsed");

  }, [mouse_is_on_navbar, mouse_is_on_collapsed_navbar, mouse_is_on_expanded_navbar]);

  const handle_click_away = () => {
    
    if(navbar_behaviour === "Collapsed"){
      set_mouse_is_on_navbar(false);
      set_navbar_behaviour("Collapsed");
    }
  }

  const handle_add_source_click = () => {

    setAnimateAddSourceButton("1");

    window.dispatchEvent(new Event("info_or_fullscreen_div_status_should_disappear"));
    window.dispatchEvent(new Event("edit_source_menu_should_disappear"));
    close_all_menus([])

    localStorage.setItem("header_menus_visibility", "hidden");
    window.dispatchEvent(new Event("header_menus_visibility_change"));
    show_input_menu("add_source");
    
    window.dispatchEvent(new Event("add_source_menu_visibility_change"));
  }

  const handle_key_down = (e) => {

    if(e["code"] === "Escape" && add_source_menu_style["visibility"] === "visible")
      window.dispatchEvent(new Event("add_source_menu_should_disappear"));
    
    else if(e["code"] === "Escape" && edit_source_menu_style["visibility"] === "visible")
      window.dispatchEvent(new Event("edit_source_menu_should_disappear"));

    else if(e["code"] === "Escape" && is_mobile)
      window.dispatchEvent(new Event("all_menus_should_disappear"));
  }

  const handle_child_on_click = (e) => {
    
    // prevent the parent's onClick method from being triggered
    e.stopPropagation()
  }
  
  return (
    <ClickAwayListener onClickAway = {() => handle_click_away()}>
      <div 
        className = {styles.root}
        onKeyDown = {handle_key_down}
        tabIndex = "0">
        <div 
          className = {styles.collapsed_navbar}
          onMouseEnter = {() => {set_mouse_is_on_collapsed_navbar(true); set_mouse_is_on_navbar(true)}}
          onMouseLeave = {() => {set_mouse_is_on_collapsed_navbar(false); set_mouse_is_on_navbar(false)}}
          onClick = {() => set_navbar_behaviour("Expanded")}
          style = {{
            transform: (navbar_behaviour === "Collapsed" ? "translateY(0)" : "translateY(-100px)"),
            display: (is_mobile ? "none" : ""),
            backgroundColor: (theme === "Light" ? "var(--colour_one)" : "var(--colour_one_dark_mode)"),
            color: "white",
            borderBottom: (anomaly_detected !== "none" ? ("0.045in solid " + (anomaly_detected === "red" ? red_alert_colour : yellow_alert_colour)) : ("0.020in solid " + (theme === "Light" ? "var(--colour_one)" : "rgba(80, 80, 80, 1.0)"))),
          }}>
          <div className = {styles.vertical_align}>
            <div 
              className = {styles.collapsed_navbar_icon} 
              style = {{
                backgroundImage: "url(\"/media/icons/menu_white.png\")",
              }}
              />
          </div>
        </div>

        <HeaderBarContainer 
          onMouseEnter = {() => {set_mouse_is_on_expanded_navbar(true); set_mouse_is_on_navbar(true)}}
          onMouseLeave = {() => {set_mouse_is_on_expanded_navbar(false); set_mouse_is_on_navbar(false)}}
          style = {navbar_behaviour === "Collapsed" ? collapsed_style : expanded_style}>
          <HeaderContainer>
            <RightSideContainer>
              <div 
                className = {styles.toolbar_div}
                style = {{
                  width: (window_width >= 950 ? "100%" : "85%"),
                }}>

                <LeftSideContainer>
                  <Heading4Container>
                    <HeaderLogos current_url = {"/dashboard"} />
                  </Heading4Container>
                </LeftSideContainer>
                
                <div 
                  className = {styles.toolbar_items_div}
                  style = {{
                    flex: (window_width >= 950 ? "" : "1"),
                  }}>
                  <div className = {styles.toolbar_items_inner_div}>

                    <div className = {styles.toolbar_button_super_div}>
                        <AnyAttribute
                            attributes = {{
                                animateAddSourceButton: animateAddSourceButton,
                            }}>
                            <div 
                                onClick = {() => handle_add_source_click()}
                                onAnimationEnd = {() => setAnimateAddSourceButton("0")}
                                onMouseEnter = {is_mobile ? () => set_add_source_button_hovered_state(true) : () => {set_add_source_button_hovered_state(true); close_dropdown_menu("sources");}}
                                onMouseLeave = {() => set_add_source_button_hovered_state(false)}
                                className = {styles.add_source_button_icon_div}
                                style = {{
                                    backgroundColor: (!add_source_button_hovered_state ? "transparent" : (theme === "Light" ? "var(--colour_four)" : "rgba(80, 80, 80, 0.3)")),
                                }}>
                                <div className = {styles.vertical_align}>
                                    <div 
                                    className = {styles.add_source_button_icon} 
                                    style = {{
                                        backgroundImage: "url(\"../media/icons/plus_THEME_mode.svg\"".replace("THEME", theme.toLowerCase()),
                                    }}/>
                                </div> 
                            </div>
                        </AnyAttribute>
                      
                      <div className = {styles.toolbar_button_inner_div}>
                      <AnyAttribute
                            attributes = {{
                                animateSourcesButton: (animatedButton === "sources" ? "1" : "0"),
                            }}>
                            <div 
                            className = {styles.toolbar_button_div}
                            ref = {sources_menu_ref}
                            onClick = {() => show_dropdown_menu("sources")}
                            onAnimationEnd = {() => setAnimatedButton(null)}
                            style = {{
                                padding: "10.5px",
                                backgroundColor: (theme === "Light" ? (sources_menu_style["visibility"] === "visible" ? "var(--colour_one)" : "var(--colour_four)") : (sources_menu_style["visibility"] === "visible" ? "rgba(200, 200, 200, 0.15)" : "var(--colour_eight)"))
                            }}
                            >
                            <div className = {styles.vertical_align} style = {{height: "100%"}}>
                                <div 
                                className = {styles.toolbar_button_icon} 
                                style = {{
                                    height: "23.5px",
                                    width: "23.5px",
                                    backgroundImage: "url(\"../media/icons/sources" + (theme === "Light" ? (sources_menu_style["visibility"] === "visible" ? "_white" : "") : (sources_menu_style["visibility"] === "visible" ? "_white" : "_dark_mode")) + ".png\")"
                                }}
                                />
                            </div>
                            </div>
                        </AnyAttribute>
                      
                        <div 
                          className = {styles.toolbar_button_dot} 
                          style = {{
                            opacity: sources_menu_style["opacity"],
                            visibility: sources_menu_style["visibility"],
                            backgroundColor: (theme === "Light" ? "var(--colour_one)" : "var(--colour_eight)"),
                          }}/>
                      
                      </div>

                    </div>

                    <div className = {styles.toolbar_button_inner_div}>
                        <AnyAttribute
                            attributes = {{
                                animateStatisticsButton: (animatedButton === "statistics" ? "1" : "0"),
                            }}>
                    
                            <div 
                                className = {styles.toolbar_button_div}
                                ref = {statistics_menu_ref}
                                onClick = {() => show_dropdown_menu("statistics")}
                                onAnimationEnd = {() => setAnimatedButton(null)}
                                style = {{
                                    backgroundColor: (theme === "Light" ? (statistics_menu_style["visibility"] === "visible" ? "var(--colour_one)" : "var(--colour_four)") : (statistics_menu_style["visibility"] === "visible" ? "rgba(200, 200, 200, 0.15)" : "var(--colour_eight)"))
                                }}
                                >
                                <div className = {styles.vertical_align} style = {{height: "100%"}}>
                                <div 
                                    className = {styles.toolbar_button_icon} 
                                    style = {{
                                    backgroundImage: "url(\"../media/icons/statistics" + (theme === "Light" ? (statistics_menu_style["visibility"] === "visible" ? "_white" : "") : (statistics_menu_style["visibility"] === "visible" ? "_white" : "_dark_mode")) + ".svg\")"
                                    }}
                                    />
                                </div>
                            </div>
                        </AnyAttribute>
                    
                      <div 
                          className = {styles.toolbar_button_dot} 
                          style = {{
                            opacity: statistics_menu_style["opacity"],
                            visibility: statistics_menu_style["visibility"],
                            backgroundColor: (theme === "Light" ? "var(--colour_one)" : "var(--colour_eight)"),
                          }}/>

                    </div>
                    
                    <div className = {styles.toolbar_button_inner_div}>
                      
                    <AnyAttribute
                            attributes = {{
                                animateSettingsButton: (animatedButton === "settings" ? "1" : "0"),
                            }}>
                            <div 
                                className = {styles.toolbar_button_div}
                                ref = {settings_menu_ref}
                                onClick = {() => show_dropdown_menu("settings")}
                                onAnimationEnd = {() => setAnimatedButton(null)}
                                style = {{
                                backgroundColor: (theme === "Light" ? (settings_menu_style["visibility"] === "visible" ? "var(--colour_one)" : "var(--colour_four)") : (settings_menu_style["visibility"] === "visible" ? "rgba(200, 200, 200, 0.15)" : "var(--colour_eight)"))
                                }}
                                >
                                <div className = {styles.vertical_align} style = {{height: "100%"}}>
                                <div 
                                    className = {styles.toolbar_button_icon} 
                                    style = {{
                                    backgroundImage: "url(\"../media/icons/settings" + (theme === "Light" ? (settings_menu_style["visibility"] === "visible" ? "_white" : "") : (settings_menu_style["visibility"] === "visible" ? "_white" : "_dark_mode")) + ".svg\")"
                                    }}
                                    />
                                </div>
                            </div>
                        </AnyAttribute>

                      <div 
                          className = {styles.toolbar_button_dot} 
                          style = {{
                            opacity: settings_menu_style["opacity"],
                            visibility: settings_menu_style["visibility"],
                            backgroundColor: (theme === "Light" ? "var(--colour_one)" : "var(--colour_eight)"),
                          }}/>

                    </div>
                  </div>
                </div>

                <div 
                  className = {styles.toolbar_separator}
                  style = {{
                    display: (window_width >= 950 ? "" : "none"),
                    borderRight: ("0.020in solid " + (theme === "Light" ? "rgba(125, 125, 125, 0.35)" : "rgba(110, 110, 110, 0.35)")),
                  }}>

                </div>

                <div 
                  className = {styles.user_bar_div}
                  ref = {quick_menu_ref}
                  onClick = {() => show_dropdown_menu("quick")}>
                  <div 
                    className = {styles.user_image_div}
                    style = {{
                      //border: (theme === "Light" ? "1px solid var(--colour_one)" : "1px solid rgba(100, 100, 100, 0.75)")
                    }}>
                    <div className = {styles.user_image} />
                  </div>

                  <div 
                    className = {styles.user_name_div}
                    style = {{
                      display: (window_width >= 950 ? "" : "none"),
                    }}>
                    <div 
                      className = {styles.user_name}
                      style = {{
                        color: (theme === "Light" ? "var(--colour_one)" : "var(--colour_eight)")
                      }}>
                      {current_user}
                    </div>
                  </div>

                  <div className = {styles.expand_arrow_div}>
                    <div className = {styles.vertical_align} style = {{height: "100%"}}>
                      <div 
                        className = {styles.expand_arrow_icon} 
                        style = {{
                          transform: (quick_menu_style["visibility"] === "visible" ? "scaleY(-1)": "none"),
                          transition: "all 0.15s ease-in-out",
                          backgroundImage: (theme === "Light" ? "url(\"/media/icons/arrow_down.png\")" : "url(\"/media/icons/arrow_down_white.png\")"),
                          opacity: (theme === "Light" ? "0.3" : "0.65")
                        }}
                        />
                    </div>
                  </div>
                </div>
              </div>
            </RightSideContainer>
          </HeaderContainer>
        </HeaderBarContainer>

        <div 
          className = {styles.mobile_header}
          style = {{
            display: (!is_mobile ? "none": ""),
            backgroundColor: (theme === "Light" ? "rgba(255, 255, 255, 0.95)" : colour_one_dark_mode.replace("1)", "0.9)")),
            zIndex: "400000000",
            boxShadow: (window_width <= 500 ? "0px 0px 10px 3px rgba(0, 0, 0, 0.35)" : ""),
          }}>
            <div 
              className = {styles.horizontal_line}
              style = {{
                height: (anomaly_detected !== "none" ? "3px" : "0.023in"),
                backgroundColor: (anomaly_detected !== "none" ? (anomaly_detected === "red" ? red_alert_colour : yellow_alert_colour) : (theme === "Light" ? "var(--colour_one)" : "rgba(80, 80, 80, 1.0)")),
              }}
              />
            <HeaderLogos current_url = {"/dashboard"} />
        </div>

        <div 
          className = {styles.mobile_footer}
          style = {{
            height: "var(--mobile_footer_height)",
            borderTop: (anomaly_detected !== "none" ? ("0.045in solid " + (anomaly_detected === "red" ? red_alert_colour : yellow_alert_colour)) : ("0.023in solid " + (theme === "Light" ? "var(--colour_one)" : "rgba(80, 80, 80, 1.0)"))),
            display: (!is_mobile ? "none": ""),
            backgroundColor: (theme === "Light" ? "rgba(255, 255, 255, 0.95)" : colour_one_dark_mode.replace("1)", "0.9)")),
            zIndex: "400000000",
            boxShadow: (window_width <= 500 ? "0px -1px 10px 3px rgba(0, 0, 0, 0.35)" : ""),
          }}>

          <div className = {styles.mobile_footer_inner}>

            <div 
              className = {styles.toolbar_button_inner_div}
              style = {{
                flexWrap: "wrap",
              }}>
              <div 
                className = {styles.toolbar_button_div}
                onClick = {() => show_dropdown_menu("sources")}
                style = {{
                  flexBasis: "100%",
                  padding: "10.5px",
                }}
                >
                <div className = {styles.vertical_align} style = {{height: "100%"}}>
                  <div 
                    className = {styles.toolbar_button_icon}
                    style = {{
                      height: "23px",
                      width: "23px",
                      opacity: (sources_menu_style["visibility"] === "visible" ? "1.0" : "85"),
                      backgroundImage: ("url(\"../media/icons/sources" + (sources_menu_style["visibility"] === "visible" ? ((theme === "Light" ? "_blue" : "_white") + ".svg\")") : ((theme === "Light" ? "" : "") + ".png\")")))
                    }}
                    />
                </div>
              </div>
            
              <div 
                className = {styles.toolbar_button_text}
                style = {{
                  flexBasis: "100%",
                  opacity: (sources_menu_style["visibility"] === "visible" ? "1.0" : "0.65"),
                  color: (theme === "Light" ? (sources_menu_style["visibility"] === "visible" ? "var(--colour_one)" : "var(--colour_three)") : "rgba(255, 255, 255, 1.0)"),
                }}>
                {json_data.sources_menu_caption.toUpperCase()}
              </div>
            </div>

            <div 
              className = {styles.toolbar_button_inner_div}
              style = {{
                flexWrap: "wrap",
              }}>
              <div 
                className = {styles.toolbar_button_div}
                onClick = {() => show_dropdown_menu("statistics")}
                style = {{
                  flexBasis: "100%",
                }}
                >
                <div className = {styles.vertical_align} style = {{height: "100%"}}>
                  <div 
                    className = {styles.toolbar_button_icon}
                    style = {{
                      height: "23px",
                      width: "23px",
                      opacity: (statistics_menu_style["visibility"] === "visible" ? "1.0" : "0.85"),
                      backgroundImage: ("url(\"../media/icons/statistics" + (statistics_menu_style["visibility"] === "visible" ? ((theme === "Light" ? "_blue" : "_white") + ".svg\")") : ((theme === "Light" ? "" : "") + ".svg\")")))
                    }}
                    />
                </div>
              </div>
            
              <div 
                className = {styles.toolbar_button_text}
                style = {{
                  flexBasis: "100%",
                  opacity: (statistics_menu_style["visibility"] === "visible" ? "1.0" : "0.65"),
                  color: (theme === "Light" ? (statistics_menu_style["visibility"] === "visible" ? "var(--colour_one)" : "var(--colour_three)") : "rgba(255, 255, 255, 1.0)"),
                }}>
                {json_data.statistics_menu_caption.toUpperCase()}
              </div>
            </div>

            <div 
              className = {styles.toolbar_button_inner_div}
              style = {{
                flexWrap: "wrap",
              }}>
              <div 
                className = {styles.toolbar_button_div}
                onClick = {() => handle_add_source_click()}
                style = {{
                  padding: "6px",
                  flexBasis: "100%",
                }}
                >
                <div 
                  className = {styles.vertical_align} 
                  style = {{
                    borderRadius: "500px", 
                    height: "100%",
                    overflow: "hidden"
                  }}>
                  <div 
                    className = {styles.add_source_button_icon_div}
                    style = {{
                      flex: "1",
                      height: "100%",
                      padding: "13px",
                      margin: "0 auto",
                      backgroundColor: (add_source_menu_style["visibility"] === "visible" ? (theme === "Light" ? "var(--colour_two)" : "rgba(255, 255, 255, 0.08)") : (theme === "Light" ? "rgba(21, 79, 107, 0.03)" : "rgba(200, 200, 200, 0.04)")),
                      border: (add_source_menu_style["visibility"] === "visible" ? (theme === "Light" ? "1px solid var(--colour_one)" : "1px solid rgba(255, 255, 255, 0.4)") : (theme === "Light" ? "1px solid rgba(75, 75, 75, 0.1)" : "1px solid rgba(255, 255, 255, 0.03)")),
                    }}>
                    <div className = {styles.vertical_align}>
                      <div 
                        className = {styles.toolbar_button_icon}
                        style = {{
                          height: "23.5px",
                          width: "23.5px",
                          opacity: (add_source_menu_style["visibility"] === "visible" ? "1.0" : "0.6"),
                          backgroundImage: "url(\"../media/icons/plus_THEME_mode.svg\"".replace("THEME", theme.toLowerCase()),
                        }}/>
                    </div> 
                  </div>
                </div>
              </div>
            </div>

            <div 
              className = {styles.toolbar_button_inner_div}
              style = {{
                flexWrap: "wrap",
              }}>
              <div 
                className = {styles.toolbar_button_div}
                onClick = {() => show_dropdown_menu("settings")}
                style = {{
                  flexBasis: "100%",
                }}
                >
                <div className = {styles.vertical_align} style = {{height: "100%"}}>
                  <div 
                    className = {styles.toolbar_button_icon}
                    style = {{
                      height: "23px",
                      width: "23px",
                      opacity: (settings_menu_style["visibility"] === "visible" ? "1.0" : "0.85"),
                      backgroundImage: ("url(\"../media/icons/settings" + (settings_menu_style["visibility"] === "visible" ? ((theme === "Light" ? "_blue" : "_white") + ".svg\")") : ((theme === "Light" ? "" : "") + ".svg\")"))),
                    }}
                    />
                </div>
              </div>
            
              <div 
                className = {styles.toolbar_button_text}
                style = {{
                  flexBasis: "100%",
                  opacity: (settings_menu_style["visibility"] === "visible" ? "1.0" : "0.65"),
                  color: (theme === "Light" ? (settings_menu_style["visibility"] === "visible" ? "var(--colour_one)" : "var(--colour_three)") : "rgba(255, 255, 255, 1.0)"),
                }}>
                {json_data.settings_menu_caption.toUpperCase()}
              </div>
            </div>

            <div 
              className = {styles.toolbar_button_inner_div}
              style = {{
                flexWrap: "wrap",
              }}>
              <div 
                className = {styles.toolbar_button_div}
                onClick = {() => show_dropdown_menu("quick")}
                style = {{
                  padding: "6px",
                  flexBasis: "100%",
                }}
                >
                <div className = {styles.vertical_align} style = {{height: "100%"}}>
                  <div 
                    className = {styles.user_image_div}
                    style = {{
                      margin: "0 auto",
                      opacity: (quick_menu_style["visibility"] === "visible" ? "1.0" : "0.65"),
                      border: (theme === "Light" ? "1px solid var(--colour_one)" : "1px solid rgba(100, 100, 100, 1.0)")
                    }}>
                    <div 
                      className = {styles.user_image} 
                      style = {{
                        height: "27px",
                        width: "27px",
                      }}/>
                  </div>
                </div>
              </div>
            
              <div 
                className = {styles.toolbar_button_text}
                style = {{
                  marginTop: "2px",
                  flexBasis: "100%",
                  opacity: (quick_menu_style["visibility"] === "visible" ? "1.0" : "0.65"),
                  color: (theme === "Light" ? (quick_menu_style["visibility"] === "visible" ? "var(--colour_one)" : "var(--colour_three)") : "rgba(255, 255, 255, 1.0)"),
                }}>
                {current_user.toUpperCase()}
              </div>
            </div>
          </div>
        </div>

        <div 
          className = {styles.menu_background}
          onClick = {() => close_all_menus([])}
          style = {{
            zIndex: (mouse_is_on_navbar || (!mouse_is_on_navbar && is_any_input_menu_open()) || (is_mobile && (is_any_dropdown_menu_open() || is_any_input_menu_open())) ? "40000000" : "9"),
            opacity: ((is_any_dropdown_menu_open() || is_any_input_menu_open()) ? "1.0" : "0.0"),
            visibility: ((is_any_dropdown_menu_open() || is_any_input_menu_open()) ? "visible" : "hidden")
          }}>
          <div className = {styles.menus_inner_div}>
            <div
              className = {styles.menu_div}
              onMouseEnter = {() => set_mouse_is_on_navbar(true)}
              //onMouseLeave = {() => set_mouse_is_on_navbar(false)}
              style = {{
                height: (is_mobile ? "100vh" : "fit-content"),
                width: (is_mobile ? "100vw" : "fit-content"),
                position: (is_mobile ? "relative" : "absolute"),
                zIndex: (sources_menu_style["visibility"] === "visible" ? "40000002" : "40"),
              }}>
                <div 
                  onMouseEnter = {() => set_mouse_is_on_expanded_navbar(true)}
                  //onMouseLeave = {() => set_mouse_is_on_expanded_navbar(false)}
                  onClick = {(e) => handle_child_on_click(e)}>
                  <SourcesMenu
                    sources_menu_ref = {sources_menu_ref}
                    sources_menu_style = {sources_menu_style} 
                    change_sources_menu_style = {change_sources_menu_style} 
                    close_sources_menu = {close_sources_menu_aux}
                    sources_image_data = {props.sources_image_data}
                    change_sources_image_data = {props.change_sources_image_data}
                  />
                </div>
            </div>

            <div
              className = {styles.menu_div}
              onMouseEnter = {() => set_mouse_is_on_navbar(true)}
              //onMouseLeave = {() => set_mouse_is_on_navbar(false)}
              style = {{
                height: (is_mobile ? "100vh" : "fit-content"),
                width: (is_mobile ? "100vw" : "fit-content"),
                position: (is_mobile ? "relative" : "absolute"),
                zIndex: (statistics_menu_style["visibility"] === "visible" ? "40000002" : "40"),
              }}>
                <div 
                  onMouseEnter = {() => set_mouse_is_on_expanded_navbar(true)}
                  //onMouseLeave = {() => set_mouse_is_on_expanded_navbar(false)}
                  onClick = {(e) => handle_child_on_click(e)}>
                  <StatisticsMenu
                    is_visible = {statistics_menu_style["visibility"] === "visible"}
                    statistics_menu_ref = {statistics_menu_ref}
                    statistics_menu_style = {statistics_menu_style} 
                    change_statistics_menu_style = {change_statistics_menu_style} 
                    close_statistics_menu = {close_statistics_menu_aux}
                  />
                </div>
            </div>

            <div
              className = {styles.menu_div}
              onMouseEnter = {() => set_mouse_is_on_navbar(true)}
              //onMouseLeave = {() => set_mouse_is_on_navbar(false)}
              style = {{
                height: (is_mobile ? "100vh" : "fit-content"),
                width: (is_mobile ? "100vw" : "fit-content"),
                position: (is_mobile ? "relative" : "absolute"),
                zIndex: (settings_menu_style["visibility"] === "visible" ? "40000002" : "40"),
              }}>
                <div 
                  onMouseEnter = {() => set_mouse_is_on_expanded_navbar(true)}
                  //onMouseLeave = {() => set_mouse_is_on_expanded_navbar(false)}
                  onClick = {(e) => handle_child_on_click(e)}>
                  <SettingsMenu
                    settings_menu_ref = {settings_menu_ref}
                    settings_menu_style = {settings_menu_style} 
                    change_settings_menu_style = {change_settings_menu_style} 
                    close_settings_menu = {close_settings_menu_aux}
                  />
                </div>
            </div>

            <div
              className = {styles.menu_div}
              onMouseEnter = {() => set_mouse_is_on_navbar(true)}
              //onMouseLeave = {() => set_mouse_is_on_navbar(false)}
              style = {{
                height: (is_mobile ? "100vh" : "fit-content"),
                width: (is_mobile ? "100vw" : "fit-content"),
                position: (is_mobile ? "relative" : "absolute"),
                zIndex: (quick_menu_style["visibility"] === "visible" ? "40000002" : "40"),
              }}>
                <div 
                  onMouseEnter = {() => set_mouse_is_on_expanded_navbar(true)}
                  //onMouseLeave = {() => set_mouse_is_on_expanded_navbar(false)}
                  onClick = {(e) => handle_child_on_click(e)}>
                  <QuickMenu 
                    quick_menu_ref = {quick_menu_ref}
                    quick_menu_style = {quick_menu_style} 
                    change_quick_menu_style = {change_quick_menu_style} 
                    close_quick_menu = {close_quick_menu_aux}
                    hide_menu_background = {hide_menu_background_aux}
                  />
                </div>
            </div>

            <div
              className = {styles.menu_div}
              style = {{
                height: "100vh",
                width: "100vw",
                position: "relative",
                cursor: "pointer",
                zIndex: (add_source_menu_style["visibility"] === "visible" ? "40000000" : "40"),
              }}>
                <div 
                  onClick = {(e) => handle_child_on_click(e)}
                  style = {{
                    cursor: "auto",
                  }}>
                <AddSourceMenu 
                    add_source_menu_style = {add_source_menu_style}
                    change_add_source_menu_style = {change_add_source_menu_style}
                    close_add_source_menu = {close_add_source_menu_aux}
                  />
                </div>
            </div>

            <div
              className = {styles.menu_div}
              style = {{
                height: "100vh",
                width: "100vw",
                position: "relative",
                cursor: "pointer",
                zIndex: (edit_source_menu_style["visibility"] === "visible" ? "40000000" : "40"),
              }}>
                <div 
                  onClick = {(e) => handle_child_on_click(e)}
                  style = {{
                    cursor: "auto",
                  }}>
                <EditSourceMenu 
                  source_to_edit = {source_to_edit}
                  edit_source_menu_style = {edit_source_menu_style} 
                  change_edit_source_menu_style = {change_edit_source_menu_style} 
                  close_edit_source_menu = {close_edit_source_menu_aux}
                />
                </div>
            </div>
            
            {/* additional background blur layer for the special case when input and dropdown menus are active at the same time */}
            <div 
              className = {styles.menu_background}
              style = {{
                zIndex: "40000001",
                opacity: ((is_any_dropdown_menu_open() && is_any_input_menu_open()) ? "1.0" : "0.0"),
                visibility: ((is_any_dropdown_menu_open() && is_any_input_menu_open()) ? "visible" : "hidden")
              }} />

          </div>
        </div>
      </div>
    </ClickAwayListener>
  );
}

export default Header;
