import { useDispatch } from "react-redux";
import useMeasure from "react-use-measure";
import React, { useState, useEffect } from "react";
import Button from "../../reusable_components/Button.js";
import SourceMenuGeneralTab from "./SourceMenuGeneralTab.js";
import styles from "../../../styles/add_source_menu.module.scss";
import { usePostCreateNewSourceMutation } from "../../../services/SourceService";
import json_add_source_menu_en from '../../../jsons/en/add_source_menu_en.json';
import json_add_source_menu_pt from '../../../jsons/pt/add_source_menu_pt.json';
import { 
    useGetAvailableSourceGroupsQuery, 
    usePostLinkSourceToUserByUIDMutation, 
    useGetAvailableSourcesQuery 
} from "../../../services/SourceService";

const AddSourceMenu = (props) => {

    // variables and states
    const [is_loading, set_is_loading] = useState(false);
    const [can_add, set_can_add] = useState(false);
    const [ai_button_state, set_ai_button_state] = useState(true);
    const [new_source_save_mode, set_new_source_save_mode] = useState(localStorage.getItem("new_source").toLowerCase());
    const [hidden_sources, set_hidden_sources] = useState(localStorage.getItem("hidden_sources") !== null ? JSON.parse(localStorage.getItem("hidden_sources")) : []);
    const [visible_sources, set_visible_sources] = useState(localStorage.getItem("visible_sources") === null ? [] : JSON.parse(localStorage.getItem("visible_sources")).filter((item) => !hidden_sources.includes(item.unique_identifier)));
    const [language, set_language] = useState(localStorage.getItem("language"));
    const json_data = (language === "English" ? json_add_source_menu_en : json_add_source_menu_pt);
    const [theme, set_theme] = useState(localStorage.getItem("theme"));
    const [root_ref, root_dimensions] = useMeasure();
    const [window_width, set_window_width] = useState(window.innerWidth);
    const [is_mobile, set_is_mobile] = useState(window.innerWidth < parseInt(localStorage.getItem("desktop_version_min_width")));
    const [header_menus_visibility, set_header_menus_visibility] = useState(localStorage.getItem("header_menus_visibility"));
    const [navbar_behaviour, set_navbar_behaviour] = useState(localStorage.getItem("navbar_behaviour"));

    const [active_tab, set_active_tab] = useState(0);
    const [send_data_to_parent_now, set_send_data_to_parent_now] = useState(false);
    const [clear_all_textfields_now, set_clear_all_textfields_now] = useState(false);
    const [complete_data, set_complete_data] = useState({
        "general": null,
        "classes": null,
        "others": null
    });

    // add event listeners
    useEffect(() => {

        // listen for theme changes
        function theme_change() {
            set_theme(localStorage.getItem("theme"));
        }
        window.addEventListener('theme_change', theme_change);

        // listen for language changes
        function language_change() {
            set_language(localStorage.getItem("language"));
        }
        window.addEventListener('language_change', language_change);

        // listen for window resizes
        function resize() { 
            set_window_width(window.innerWidth); 
        }
        window.addEventListener('resize', resize);

        // listen for source save mode changes
        function new_source_change() {
            set_new_source_save_mode(localStorage.getItem("new_source").toLowerCase());
        }
        window.addEventListener('new_source_change', new_source_change);

        // listen for header menus visibility changes
        function header_menus_visibility_change() {
            set_header_menus_visibility(localStorage.getItem("header_menus_visibility"));
        }
        window.addEventListener('header_menus_visibility_change', header_menus_visibility_change);

        // listen for logo clicks
        function add_source_menu_should_disappear() {
            handle_close();
        }
        window.addEventListener('add_source_menu_should_disappear', add_source_menu_should_disappear);

        // do some cleanup (i.e., remove the event listeners if this component ends up being unmounted)
        return(() => {
            window.removeEventListener('theme_change', theme_change);
            window.removeEventListener('language_change', language_change);
            window.removeEventListener('resize', resize);
            window.removeEventListener('new_source_change', new_source_change);
            window.removeEventListener('header_menus_visibility_change', header_menus_visibility_change);
            window.removeEventListener('add_source_menu_should_disappear', add_source_menu_should_disappear);
        })

    }, []);

    // (has to be placed outside of the useEffect due to state updates) listen for logo clicks
    function add_source_menu_should_disappear() {
        handle_close();
    }
    window.addEventListener('add_source_menu_should_disappear', add_source_menu_should_disappear);

    useEffect(() => {

        set_navbar_behaviour(localStorage.getItem("navbar_behaviour"));
    
      // eslint-disable-next-line
    }, [localStorage.getItem("navbar_behaviour")]);

    useEffect(() => {

        set_is_mobile(window_width < parseInt(localStorage.getItem("desktop_version_min_width")));
    
    }, [window_width]);

    function determine_position_and_height() {
        
        if(!("maxHeight" in props.add_source_menu_style) && (root_dimensions.height !== 0)){

            props.change_add_source_menu_style({
                maxHeight: root_dimensions.height + "px",
                visibility: props.add_source_menu_style["visibility"],
                position: "fixed",
                top: "48%",
                transform: "translateY(-48%)",
                opacity: props.add_source_menu_style["opacity"], 
                transition: props.add_source_menu_style["transition"]
            });
        }
    }

    useEffect(() => {
        determine_position_and_height();
    }, [root_dimensions.height]);

    const dispatch = useDispatch();

    const [
        postCreateNewSource,
        {
        data: postCreateNewSourceData,
        //isLoading: postCreateNewSourceIsLoading,
        isSuccess: postCreateNewSourceIsSuccess,
        isError: postCreateNewSourceIsError,
        },
    ] = usePostCreateNewSourceMutation();

    const [
        postLinkSourceToUserByUID,
        /*{
            isSuccess: postLinkSourceToUserByUidIsSuccess,
            isError: postLinkSourceToUserByUidIsError,
        }*/,
    ] = usePostLinkSourceToUserByUIDMutation();

    const {
        data: getAvailableSourceGroupsData,
        isSuccess: getAvailableSourceGroupsIsSuccess,
        //isError: getAvailableSourceGroupsIsError,
        //isLoading: getAvailableSourceGroupsIsLoading,
    } = useGetAvailableSourceGroupsQuery();

    const {
        data: getAvailableSourceData,
        //isError: getAvailableSourceIsError,
        //isLoading: getAvailableSourceIsLoading,
        } = useGetAvailableSourcesQuery({
        linkedOnly: 1,
        activeOnly: 1,
        includeLinkedProperty: 1,
    });

    const {
        data: getAllAvailableSourceData,
        //isError: getAllAvailableSourceIsError,
        //isLoading: getAllAvailableSourceIsLoading,
        } = useGetAvailableSourcesQuery({
        linkedOnly: 1,
        activeOnly: 1,
        includeLinkedProperty: 1,
    });

    const linkThisSource = (unique) => {
        const payload = new FormData();
        payload.set("unique_identifier", unique);
        postLinkSourceToUserByUID(payload);
    };

    useEffect(() => {
        try {

            for (let i = 0; i < getAllAvailableSourceData.length; i++) {
                
                if (getAllAvailableSourceData[i].linked === false) {
                    linkThisSource(getAllAvailableSourceData[i].unique_identifier);

                    // store the new source to local storage
                    var sources_aux = (new_source_save_mode.includes("visible") ? visible_sources : hidden_sources).slice();
                    if((new_source_save_mode.includes("visible") ? visible_sources : hidden_sources).filter((item) => item.unique_identifier === getAllAvailableSourceData[i].unique_identifier).length === 0){
                        sources_aux.push(getAllAvailableSourceData[i]);

                        if(new_source_save_mode.includes("visible"))
                            set_visible_sources(sources_aux.slice());
                        
                        else
                            set_hidden_sources(sources_aux.slice());
                    }

                    // either save this source in the AI list or not
                    if(ai_button_state){
                        
                        let ai_sources_aux = localStorage.getItem("ai_sources") !== null ? JSON.parse(localStorage.getItem("ai_sources")) : [];
                        if(!ai_sources_aux.includes(getAllAvailableSourceData[i].unique_identifier))
                            ai_sources_aux.push(getAllAvailableSourceData[i].unique_identifier);
                        
                        localStorage.setItem("ai_sources", JSON.stringify(ai_sources_aux));
                        window.dispatchEvent(new Event("ai_sources_change"));
                    }
                    
                    // save everything
                    localStorage.setItem((new_source_save_mode.includes("visible") ? "visible_sources" : "hidden_sources"), JSON.stringify(sources_aux));
                }
            }
        } catch (e) {
            <></>;
        }
    }, [getAllAvailableSourceData]);

    const onSubmit = () => {

        const payload = new FormData();
        
        payload.set("name", complete_data["general"]["name"]);
        payload.set("url", complete_data["general"]["url"]);
        payload.set("is_active", 0);
        payload.set("protocol", complete_data["general"]["protocol"]);
        payload.set("group", complete_data["general"]["source_group"]);

        postCreateNewSource(payload);

        set_send_data_to_parent_now(false);
    };

    useEffect(() => {
        
        if (can_add && postCreateNewSourceIsSuccess && postCreateNewSourceData && postCreateNewSourceData.name) {
            setTimeout(() => {
                set_is_loading(false);
                
                // clear all textfields
                set_clear_all_textfields_now(true);
                
                handle_close()
                window.dispatchEvent(new Event("sources_change"));

            }, 1000);
        }
    
    // eslint-disable-next-line
    }, [postCreateNewSourceIsSuccess, postCreateNewSourceData, can_add]);

    const handle_close = () => {
        
        if(props.add_source_menu_style["visibility"] === "visible" && (is_mobile || header_menus_visibility === "hidden")){
            props.close_add_source_menu();
            set_can_add(false);

            // clear all textfields
            set_clear_all_textfields_now(true);
        }
    }

    const handle_click = (action) => {

        if(action === "ai"){
            set_ai_button_state(!ai_button_state);
        }

        else if(action === "done"){

            // retrieve data from the child components
            set_send_data_to_parent_now(true);

            set_is_loading(true);

            // wait for a bit for the data to arrive
            setTimeout(() => {}, 1000);

            // actually submit the new source's details, if everything is in order
            if(complete_data["general"] !== null && complete_data["classes"] !== null && complete_data["others"] !== null)
                onSubmit();

            else
                set_is_loading(false);
        }
    }

    const handle_key_down = (e) => {
        if(e["code"] === "Enter"){
            set_can_add(true);
            handle_click("done");
        }
    }

    const handle_tab_change = (e, new_tab) => {
        set_active_tab(new_tab);
    }
    
    function tab_props(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const pass_data_to_parent = (which_tab, data) => {
        
        set_complete_data(previous_complete_data => ({...previous_complete_data, [which_tab]: data}));
    }

    const all_textfields_were_cleared = () => {
        set_clear_all_textfields_now(false);
    }

    return (
        <div
            className = {styles.super_root} 
            ref = {root_ref}
            style = {props.add_source_menu_style}>

                <div 
                    className = {styles.root}
                    style = {{
                        marginTop: (is_mobile ? "" : (navbar_behaviour === "Expanded" ? "var(--navbar_height)" : "var(--collapsed_navbar_height)")),
                        width: (is_mobile ? "" : "500px"),
                        backgroundColor: (theme === "Light" ? "rgba(245, 245, 245, 1.0)" : "var(--colour_one_dark_mode)"),
                        border: (theme === "Light" ? "0.020in solid var(--colour_two)" : "0.020in solid rgba(80, 80, 80, 0.55)"),
                        boxShadow: (theme === "Light" ? "rgba(100, 100, 100, 0.35) 0px 0px 10px" : "rgba(10, 15, 21, 0.75) 0px 8px 24px"),
                    }}
                    onKeyDown = {handle_key_down}
                    tabIndex = "0">

                <div 
                    className = {styles.close_button_div}
                    style = {{
                        backgroundColor: (theme === "Light" ? "var(--colour_one)" : "rgba(190, 190, 190, 1.0)")
                    }}
                    onClick = {() => handle_close()}
                    >
                    <div className = {styles.vertical_align}>
                        <div 
                            className = {styles.close_button} 
                            style = {{
                                backgroundImage: (theme === "Light" ? "url(\"/media/icons/close_white_2.png\")" : "url(\"/media/icons/close_4.png\")")
                            }}/>
                    </div>
                </div>
                
                <div className = {styles.top_bar_div}>
                    <div 
                        className = {styles.top_bar}
                        style = {{
                            opacity: "1.0",
                            backgroundImage: "url(\"/media/images/menu_pattern_THEME_mode.jpg\")".replace("THEME", theme.toLowerCase()),
                        }}/>
                    <div 
                        className = {styles.top_bar_fade}
                        style = {{
                            opacity: "1.0",
                            backgroundImage: (theme === "Light" ? "linear-gradient(to bottom, rgba(235,235,235,0.55), rgba(245, 245, 245, 1.0))" : "linear-gradient(to bottom, rgba(20, 25, 32, 0.6), var(--colour_one_dark_mode))")
                        }}/>
                    
                    <div className = {styles.text_super_div}>
                        <div className = {styles.top_text_div} >
                            <div 
                                className = {styles.top_text}
                                style = {{
                                    color: (theme === "Light" ? "var(--colour_one)" : "rgba(230, 230, 230, 1.0)")
                                }}
                                >{json_data.top_text.toUpperCase()}</div>
                        </div>
                        <div className = {styles.secondary_text_div}>
                            <div 
                                className = {styles.secondary_text}
                                style = {{
                                    color: (theme === "Light" ? "var(--secondary_text_colour)" : "rgba(230, 230, 230, 0.75)")
                                }}>{json_data.secondary_text.replaceAll("-", "‑")}</div>
                        </div>
                    </div>
                </div>

                <SourceMenuGeneralTab 
                    theme = {theme}
                    json_data = {json_data}
                    clear_all_textfields_now = {clear_all_textfields_now}
                    all_textfields_were_cleared = {all_textfields_were_cleared}
                    send_data_to_parent_now = {send_data_to_parent_now}
                    pass_data_to_parent = {pass_data_to_parent}
                    add_source_menu_style = {props.add_source_menu_style}
                    getAvailableSourceGroupsData = {getAvailableSourceGroupsData}
                />

                <div className = {styles.bottom_buttons_div}>
                    <div 
                        className = {styles.button_div}
                        onClick = {() => handle_click("ai")}
                        >
                        <Button 
                            key = {1}
                            orientation = {"vertical"}
                            text = {ai_button_state ? json_data.ai_button_text_on : json_data.ai_button_text_off}
                            text_size = {"13.5px"}
                            text_family = {"semibold"}
                            text_color = {(ai_button_state ? "rgba(163, 194, 194, 1.0)" : (theme === "Light" ? "rgba(110, 110, 110, 1.0)" : "rgba(190, 190, 190, 1.0)"))}
                            background_color = {(ai_button_state ? "var(--ai_colour)" : "rgb(200, 200, 200, 0.35)")}
                            icon_url = {null}
                            icon_size = {null}
                        />
                    </div>

                    <div 
                        className = {styles.button_div}
                        onClick = {() => {set_can_add(true); handle_click("done");}}
                        >
                        <Button 
                            key = {1}
                            orientation = {"vertical"}
                            text = {json_data.done_button_text}
                            text_size = {"13.5px"}
                            text_family = {"semibold"}
                            text_color = {(theme === "Light" ? "var(--main_button_text_colour)" : "rgba(50, 50, 50, 1.0)")}
                            background_color = {(theme === "Light" ? "var(--colour_one)" : "rgba(230, 230, 230, 1.0)")}
                            icon_url = {null}
                            icon_size = {null}
                            is_loading = {is_loading}
                        />
                    </div>

                </div>
            </div>
        </div>
    );
}

export default AddSourceMenu;