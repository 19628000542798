import { ResponsivePie } from '@nivo/pie';
import styles from "./pieChart.module.scss";
import React, { useEffect, useState } from "react";

const PieChart = ( { theme, data, parentHeight } ) => {

    const [pieSlicesFill, setPieSlicesFill] = useState([]);

    // alternate the "slice type" automatically
    useEffect(() => {

        let pieSlicesFillAux = [];
        let currentType = "dots";

        for(let i = 0; i < data.length; i++){

            pieSlicesFillAux.push({
                match: { id: data[i]["id"]},
                id: currentType
            })

            currentType = (currentType === "dots" ? "lines" : "dots");
        }

        setPieSlicesFill(pieSlicesFillAux);

    }, [data]);

    return(
        <div 
            className = {styles.rootDiv}
            style = {{
                height: parentHeight + "px",
            }}>
            <ResponsivePie
                theme = {{
                    fontSize: "13px", 
                    fontFamily: "medium",
                }}
                tooltip = { item => {
                    return (
                        <div
                            className = {styles.tooltipDiv}
                            style={{
                                background: (theme === "Light" ? "white" : "rgba(26, 31, 38, 1.0)"),
                                border: "1px solid " + (theme === "Light" ? "rgba(200, 200, 200, 1.0)" : "rgba(42, 46, 53, 1.0)"),
                                color: (theme === "Light" ? "black" : "white"),
                            }}>
                            <div className = {styles.tooltipPrefix}>{item.datum.label + ": "}</div>
                            <div className = {styles.tooltipValue}>{item.datum.value}</div>
                        </div>
                    )
                }}
                colors = {data.map((item) => item.color)}
                data = {data}
                margin = {{ top: 40, right: 80, bottom: 40, left: 80 }}
                innerRadius = {0.4}
                padAngle = {0.7}
                cornerRadius = {3}
                activeOuterRadiusOffset = {8}
                borderWidth = {1.5}
                borderColor = {{
                    from: "color",
                    modifiers: [
                        [
                            "darker",
                            0.3
                        ]
                    ]
                }}
                arcLinkLabelsSkipAngle = {10}
                arcLinkLabelsTextColor = {theme === "Light" ? "var(--colour_one)" : "rgba(255, 255, 255, 0.7)"}
                arcLinkLabelsThickness = {2}
                arcLinkLabelsColor = {{ from: 'color' }}
                arcLabelsSkipAngle = {10}
                arcLabelsTextColor = {{
                    from: "color",
                    modifiers: [
                        [
                            "darker",
                            2
                        ]
                    ]
                }}
                defs = {[
                    {
                        id: 'dots',
                        type: 'patternDots',
                        background: 'inherit',
                        color: 'rgba(255, 255, 255, 0.3)',
                        size: 2,
                        padding: 1,
                        stagger: true
                    },
                    {
                        id: 'lines',
                        type: 'patternLines',
                        background: 'inherit',
                        color: 'rgba(255, 255, 255, 0.3)',
                        rotation: -45,
                        lineWidth: 6,
                        spacing: 8
                    }
                ]}
                //fill = {pieSlicesFill}
            />
        </div>
    );
}

export default PieChart;