import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import styles from "./table.module.scss";
import useMeasure from "react-use-measure";
import json_table_en from './table_en.json';
import json_table_pt from './table_pt.json';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableContainer from '@mui/material/TableContainer';
import { downloadExcel } from 'react-export-table-to-excel';
import React, { useEffect, useState, useMemo } from "react";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const MUITable = ( { theme, data, parentHeight } ) => {

    const [language, set_language] = useState(localStorage.getItem("language"));
    const json_data = (language === "English" ? json_table_en : json_table_pt);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25); 
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('timestamp');
    const [paginationDivRef, paginationDivDimensions] = useMeasure();
    const [maxNumberOfPages, setMaxNumberOfPages] = useState(Math.ceil(data["rows"].length / rowsPerPage));
    const [pageArrowsStatus, setPageArrowsStatus] = useState({
        left: {cursor: "pointer", opacity: "0.75"},
        right: {cursor: "pointer", opacity: "0.75"}
    });

    const [excelDownloadPayload, setExcelDownloadPayload] = useState({});
    const [clipURLs, setClipURLs] = useState({});

    const visibleRows = useMemo(
        () =>
            stableSort(data["rows"], getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage, data],
    );

    // add event listeners
    useEffect(() => {

        // listen for language changes
        function language_change() {
            set_language(localStorage.getItem("language"));
        }
        window.addEventListener('language_change', language_change);

        // do some cleanup (i.e., remove the event listeners if this component ends up being unmounted)
        return(() => {
            window.removeEventListener('language_change', language_change);
        })

    }, []);

    function retrieveClipURL (dataItem) {

        let pattern = "https://storage.googleapis.com/web_app_clips/"

        pattern += dataItem["camera_id"].replaceAll(" ", "%20") + "/" + dataItem["id"] + ".mp4"
        
        return(pattern);
    }

    useEffect(() => {

        // -----------------------------------------------------------------------------------------------------------
        // if required, retrieve clip URLs
        // -----------------------------------------------------------------------------------------------------------
        let newClipURLs = structuredClone(clipURLs);

        for(let i = 0; i < data["rows"].length; i++){

            if(data["rows"][i]["clip_path"] === "View clip" || data["rows"][i]["clip_path"] === "Click to View Clip"){
                newClipURLs[data["rows"][i]["id"]] = retrieveClipURL(data["rows"][i]);
            }
        }

        if(JSON.stringify(newClipURLs) !== JSON.stringify(clipURLs))
            setClipURLs(newClipURLs);

        // ----------------------------------------------------
        // build the payload
        // ----------------------------------------------------

        // build the header
        let header = [];

        for(let i = 0; i < data["columns"].length; i++){
            header.push(data["columns"][i]["label"]);
        }

        // build the body (and make sure to sort the data)
        let body = [];
        let sortedRows = stableSort(data["rows"], getComparator(order, orderBy))

        for(let i = 0; i < sortedRows.length; i++){

            let row = structuredClone(sortedRows[i]);

            row["clip_path"] = clipURLs[sortedRows[i]["id"]];

            body.push(row);
        }
        
        // prepare the object for download
        let newExcelDownloadPayload = {
            fileName: "DeepNeuronic Analytics",
            sheet: "Analytics",
            tablePayload: {
                header,
                body: body,
            },
        }

        setExcelDownloadPayload(newExcelDownloadPayload);

    }, [data, visibleRows, clipURLs, order, orderBy]);

    function handleExportButtonClick() {
        downloadExcel(excelDownloadPayload);
    }

    function descendingComparator(a, b, orderBy) {

        if(orderBy === "clip_path")
            return(0)

        // determine the best way to perform the comparison
        let validatedA = a[orderBy];
        let validatedB = b[orderBy];
        
        if(orderBy === "timestamp"){
            validatedA = new Date(validatedA);
            validatedB = new Date(validatedB);
        }

        if(validatedB < validatedA)
            return(-1);
        
        if(validatedB > validatedA)
            return(1);
        
        return(0);
    }
    
    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }
    
    function stableSort(array, comparator) {
    
        const stabilizedThis = array.map((el, index) => [el, index]);
            stabilizedThis.sort((a, b) => {
                const order = comparator(a[0], b[0]);
                
                if(order !== 0)
                    return order;

                return a[1] - b[1];
            });

        return(stabilizedThis.map((el) => el[0]));
    }

    const handleRequestSort = (column_id) => {
        
        const isAsc = orderBy === column_id && order === 'asc';
        
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(column_id.toLowerCase());
    };

    const handleCellClick = (columnLabel, row) => {

        if(!columnLabel.toLowerCase().includes("clip"))
            return;

        // handle the "clip" column click
        if(row["id"] in clipURLs)
            window.open(clipURLs[row["id"]], "__blank");
    }

    useEffect(() => {

        let maxNumberOfPagesAux = Math.ceil(data["rows"].length / rowsPerPage);
        setMaxNumberOfPages(maxNumberOfPagesAux);

        let newPageArrowStatus = {}
        let enteredInPreviousIf = false;

        if(page === 0 || (page + 1) === maxNumberOfPagesAux){

            if(page === 0){
                newPageArrowStatus = {
                    "left": {cursor: "not-allowed", opacity: 0.2},
                    "right": {cursor: "pointer", opacity: 0.75}
                }

                enteredInPreviousIf = true;
            }

            if((page + 1) === maxNumberOfPagesAux){
                newPageArrowStatus = {
                    "left": (enteredInPreviousIf ? newPageArrowStatus["left"] : {cursor: "pointer", opacity: 0.75}),
                    "right": {cursor: "not-allowed", opacity: 0.2},
                }
            }
        }

        else{
            newPageArrowStatus = {
                "left": {cursor: "pointer", opacity: 0.75},
                "right": {cursor: "pointer", opacity: 0.75},
            }
        }

        if(JSON.stringify(newPageArrowStatus) !== JSON.stringify(pageArrowsStatus))
            setPageArrowsStatus(newPageArrowStatus);

    }, [page, pageArrowsStatus]);

    const handlePageArrowClick = (direction) => {
        
        if((page === 0 && direction === "left") || ((page + 1) === maxNumberOfPages && direction === "right"))
            return;

        let newPage = Math.max(0, Math.min(direction === "left" ? page - 1 : page + 1, maxNumberOfPages))

        setPage(newPage);
    };

    return(
        <div 
            className = {styles.rootDiv}
            style = {{
                height: "100%",
            }}>

            <Paper 
                sx = {{ 
                    width: "100%", 
                    height: parentHeight - paginationDivDimensions["height"] + "px", 
                    overflow: 'hidden',
                    backgroundColor: (theme === "Light" ? "rgba(217, 217, 217, 1.0)" : "rgb(25, 41, 50, 1.0)"),
                }}>
                <TableContainer 
                    sx = {{ 
                        maxHeight: parentHeight - paginationDivDimensions["height"], 
                        backgroundColor: (theme === "Light" ? "rgba(230, 234, 238, 1.0)" : "rgb(35, 41, 50, 1.0)"),
                    }}>
                    <Table
                        padding = {"none"} 
                        stickyHeader
                        aria-label = "sticky table">
                        <TableHead>
                            <TableRow>
                            {data["columns"].map((column, index) => (
                                <TableCell
                                    key = {index}
                                    align = {column.align}
                                    style = {{ 
                                        minWidth: column.minWidth, 
                                        padding: "10px",
                                        paddingLeft: "15px",
                                        paddingRight: "15px",
                                        borderBottom: "1px solid " + (theme === "Light" ? "var(--colour_one)" : "rgba(245, 245, 245, 0.25)"),
                                        color: (theme === "Light" ? "var(--colour_one)" : "rgba(245, 245, 245, 1.0)"),
                                        backgroundColor: (theme === "Light" ? "rgba(207, 216, 224, 1.0)" : "rgb(38, 42, 49, 1.0)"),
                                    }}>

                                    {
                                        column.id === "clip_path" ? 
                                        <div>
                                            {column.label}
                                        </div>
                                        :
                                        <TableSortLabel
                                            active = {orderBy === column.id}
                                            direction = {orderBy === column.id ? order : 'asc'}
                                            onClick = {() => handleRequestSort(column.id)}
                                            IconComponent = {null}>
                                            <div 
                                                style = {{
                                                    color: (theme === "Light" ? "var(--colour_one)" : "rgba(245, 245, 245, 1.0)")
                                                }}>
                                                {column.label}
                                            </div>
                                            {orderBy === column.id ? (
                                                <ArrowDownwardIcon 
                                                    className = {styles.sortArrowIcon}
                                                    style = {{
                                                        opacity: "0.75",
                                                        transform: (order === "asc" ? "rotate(180deg)" : "rotate(0deg)"),
                                                        width: "15px",
                                                        height: "15px",
                                                        color: (theme === "Light" ? "var(--colour_one)" : "rgba(245, 245, 245, 1.0)"),
                                                    }}/> 
                                                ) 
                                                : 
                                                <></>
                                            }
                                        </TableSortLabel>
                                    }
                                </TableCell>
                            ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {visibleRows
                            .map((row, index) => {
                                return (
                                <TableRow 
                                    hover 
                                    role = "checkbox" 
                                    tabIndex = {-1}
                                    key = {index}
                                    sx = {{
                                        "&:hover": {
                                            backgroundColor: (theme === "Light" ? "rgba(220, 224, 228, 1.0)" : "rgb(30, 34, 40, 0.5)") + " !important"
                                        }
                                    }}>
                                    
                                    {data["columns"].map((column, index) => {
                                        const value = row[column.id];
                                        return (
                                            <TableCell 
                                                onClick = {() => handleCellClick(column.label, row)}
                                                key = {index} 
                                                align = {column.align}
                                                sx = {{
                                                    transition: "all 0.15s ease-in-out",
                                                    opacity: "1.0",
                                                    cursor: (("" + value).toLowerCase().includes("clip") ? "pointer" : "normal"),
                                                    textDecoration: (("" + value).toLowerCase().includes("clip") ? "underline" : "none"),
                                                    borderBottom: "none", 
                                                    padding: "10px",
                                                    paddingLeft: "15px",
                                                    paddingRight: "15px",
                                                    color: (theme === "Light" ? "var(--main_text_colour)" : "rgba(150, 150, 150, 1.0)"),
                                                    willChange: "opacity",

                                                    "&:hover": {
                                                        opacity: (("" + value).toLowerCase().includes("clip") ? "0.7" : "1.0"),
                                                        transition: "all 0.15s ease-in-out",
                                                        willChange: "opacity",
                                                    }
                                                }}>
                                            {column.format && typeof value === 'number' ? column.format(value) : value}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>

            <div 
                ref = {paginationDivRef}
                className = {styles.paginationDiv}
                style = {{
                    borderTop: "1px solid " + (theme === "Light" ? "var(--colour_one)" : "rgba(245, 245, 245, 0.25)"),
                    backgroundColor: (theme === "Light" ? "rgba(207, 216, 224, 1.0)" : "rgb(38, 42, 49, 1.0)"),
                }}>
                <div className = {styles.pageIndicatorDiv}>
                    <KeyboardArrowLeftIcon 
                        key = {"left"}
                        onClick = {() => handlePageArrowClick("left")}
                        className = {styles.pageArrowDiv}
                        style = {{
                            opacity: pageArrowsStatus["left"]["opacity"],
                            cursor: pageArrowsStatus["left"]["cursor"],
                            width: "20px",
                            height: "20px",
                            color: (theme === "Light" ? "var(--colour_one)" : "rgba(245, 245, 245, 1.0)"),
                        }}/> 
                    
                    <div 
                        className = {styles.pageText}
                        style = {{
                            opacity: "0.75",
                            color: (theme === "Light" ? "var(--colour_one)" : "rgba(245, 245, 245, 1.0)")
                        }}>
                        {json_data.pageText.replace("X", Math.min(page + 1, maxNumberOfPages)).replace("Y", maxNumberOfPages)}
                    </div>

                    <KeyboardArrowRightIcon 
                        key = {"right"}
                        onClick = {() => handlePageArrowClick("right")}
                        className = {styles.pageArrowDiv}
                        style = {{
                            opacity: pageArrowsStatus["right"]["opacity"],
                            cursor: pageArrowsStatus["right"]["cursor"],
                            width: "20px",
                            height: "20px",
                            color: (theme === "Light" ? "var(--colour_one)" : "rgba(245, 245, 245, 1.0)"),
                        }}/> 
                </div>

                <div 
                    className = {styles.exportButtonDiv}
                    onClick = {() => handleExportButtonClick()}>
                    
                    <CloudDownloadIcon 
                        className = {styles.downloadIcon}
                        style = {{
                            opacity: "0.75",
                            width: "15px",
                            height: "15px",
                            color: (theme === "Light" ? "var(--colour_one)" : "rgba(245, 245, 245, 1.0)"),
                        }}/> 
                    
                    <div 
                        className = {styles.exportButtonText}
                        style = {{
                            opacity: "0.75",
                            color: (theme === "Light" ? "var(--colour_one)" : "rgba(245, 245, 245, 1.0)")
                        }}>
                        {json_data.exportButtonText}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MUITable;