import styled from "@emotion/styled";
// import HeaderBarContainer from "./HeaderBarContainer.js";
import BaseDivContainer from "../Divs/BaseDivContainer";

const HeaderContainer = styled(BaseDivContainer)`
  flex-direction: row;
  align-items: center;
  vertical-align: middle;
  display: flex;
  max-width: 80vw;
  height: inherit;
`;

export default HeaderContainer;
