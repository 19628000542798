import styled from "@emotion/styled";
import style from "../../Style";

const HeaderBarContainer = styled.header`
  color: ${style.DARK_BACKGROUND_COLOR};
  text-align: left;
  display: relative;
  box-sizing: border-box;
  position: fixed;
  width: 100vw;
  z-index: 300000;
  top: 0;
  height: var(--navbar_height);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
`;

export default HeaderBarContainer;
