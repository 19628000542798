import { io } from "socket.io-client";
import Button from "@mui/material/Button";
import { styled } from '@mui/material/styles';
import InfoIcon from "@mui/icons-material/Info";
import MovieIcon from "@mui/icons-material/Movie";
import CheckIcon from '@mui/icons-material/Check';
import REACT_APP_API_URL from "../../../config/Api";
import ButtonGroup from "@mui/material/ButtonGroup";
import LensBlurIcon from '@mui/icons-material/LensBlur';
import VisualisationManager from "./VisualisationManager";
import AIIndicator from "../../reusable_components/AIIndicator";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from "../../../styles/socket_io_component.module.scss";
import React, { forwardRef, useEffect, useRef, useState, useCallback } from "react";
import json_settings_menu_en from '../../../jsons/en/settings_menu_en.json';
import { are_the_two_lists_equal, sortByDateAndHour } from "../../utilities/Utils.js";
import CanvasContainer from "../../../containers/Sources/Visualization/CanvasContainer";
import CanvasExternalContainer from "../../../containers/Sources/Visualization/CanvasExternalContainer";

export const SocketIOComponent = forwardRef(

  ({
    enter_wiggle_mode,
    is_being_dragged_prop,
    sources_image_data,
    change_sources_image_data,
    grid_span,
    position,
    source_name,
    currentSource,
    setCurrentSource,
    dispatch,
    currentSourceCounter,
    is_visible,
    change_anomaly_detected,
    active_id,
  }, ref) => {

    const DEBUG_MODE = false;

    // variables and states
    const [theme, set_theme] = useState(localStorage.getItem("theme"));
    const ai_colour = getComputedStyle(document.documentElement).getPropertyValue('--ai_colour');
    const red_alert_colour = getComputedStyle(document.documentElement).getPropertyValue('--red_alert_colour_' + theme.toLowerCase() + "_mode");
    const yellow_alert_colour = getComputedStyle(document.documentElement).getPropertyValue('--yellow_alert_colour_' + theme.toLowerCase() + "_mode");
    const [ai_sources, set_ai_sources] = useState(localStorage.getItem("ai_sources") !== null ? JSON.parse(localStorage.getItem("ai_sources")) : []);
    const [state, setState] = useState(false);
    const [anomaly_detected, set_anomaly_detected] = useState("none");
    const sourceFramesProcessedOption = false;
    const thisCanvas = useRef(null);
    const parent_ref = useRef(null);
    const [is_loading, set_is_loading] = useState(true);
    const current_user = localStorage.getItem("current_user");
    const [hovered, set_hovered] = useState(false);
    //var has_sent_thumbnail = false;
    const [frameHeight, setFrameHeight] = useState(0);
    const [frameWidth, setFrameWidth] = useState(0);
    const [sendClip, setSendClip] = useState(false);

    // ---------------------------------------------------------------------------
    // variables for the heatmaps
    // ---------------------------------------------------------------------------
    const [showHeatmap, setShowHeatmap] = useState(true);
    const [currentHeatmap, setCurrentHeatmap] = useState({
      "rawHeatmap": [],
      "colourCorrectedHeatmap": [],
    });
    let colormap = require('colormap');
    const numberOfColours = 260
    let heatmapColours = colormap({
      colormap: 'jet',
      nshades: numberOfColours,
      format: 'rgba',
      alpha: 1
    })

    // make sure that the opacity goes up from 0 as each colour grows in intensity
    heatmapColours.map((item, index) => item[3] = (index / numberOfColours) * 0.75);

    const [window_width, set_window_width] = useState(window.innerWidth);
    const [times_icon, set_times_icon] = useState(grid_span === "1" ? "2" : "1");
    const [is_mobile, set_is_mobile] = useState(window.innerWidth < parseInt(localStorage.getItem("desktop_version_min_width")));

    let mlAnalysisAux = {};
    if (currentSource === "8fce88468f779401894605be35a3304c40bfa1800b62e63aed6f24b80591aab4") {

      mlAnalysisAux = {
        "predictions": {
          "bus_2_0.83": [151, 94, 100, 49],
          "car_3_0.59": [286, 93, 38, 17],
          "car_5_0.46": [71, 100, 33, 16],
          "car_6_0.44": [2, 83, 28, 10],
          "motorcycle_7_0.29": [91, 91, 23, 12],
          "truck_8_0.28": [168, 108, 22, 19],
          "low_speed_8_0.28": [168, 108, 22, 19],
          "zone-1 • car_38_0.81": [55, 130, 59, 32],
          //"stopped_38_0.81": [92, 143, 59, 32]
        },
        "use_case_controllers": {
          "license_plate_detection": {
            "38": "11-XX-34"
          },
          "vehicle_analysis": {
            "38": "red"
          }
        },
        "debug_visuals": {
          "polygons": [
            {
              "DESCRIPTION": "zone-1",
              "POINTS": [[48.35, 159.34], [76.63, 171.39], [300.11, 114.75], [286.43, 105.63]],
              "COLOUR": [184, 104, 249, 255]
            },
            {
              "DESCRIPTION": "zone-2",
              "POINTS": [[82.09, 174.141], [109.45, 181.421], [311.04, 125.7], [301.02, 116.58]],
              "COLOUR": [242, 53, 138, 255]
            }
          ],
          "lines": []
        },
        "vitals": {},
        "stats": {
          "pie_chart • detections_per_class": {
            "car": 89,
            "truck": 13,
            "zone-2 • car": 32,
            "zone-1 • car": 51,
            "low_speed": 3,
            "person": 63,
            "stopped": 3,
            "zone-1 • truck": 7,
            "jaywalking": 8,
            "zone-2 • truck": 1
          },
          "line_chart • detections_per_class_per_hour": {
            "2023_11_26_06": {
              "zone-1 • car": 2,
              "car": 4,
              "zone-2 • car": 2,
              "bus": 2, "truck": 1
            },
            "2023_11_26_07": {
              "person": 32,
              "car": 24,
              "zone-2 • car": 8,
              "truck": 6,
              "zone-1 • truck": 4,
              "bus": 1,
              "zone-1 • car": 8,
              "jaywalking": 1
            },
            "2023_11_26_08": {
              "person": 114,
              "zone-2 • car": 20,
              "car": 51,
              "zone-1 • car": 32,
              "truck": 5,
              "zone-1 • truck": 4,
              "jaywalking": 21,
              "zone-2 • truck": 1
            },
            "2023_11_26_09": {
              "car": 127,
              "zone-1 • car": 70,
              "person": 169,
              "zone-1 • truck": 9,
              "zone-2 • car": 37,
              "truck": 12, "bus": 6,
              "zone-1 • bus": 1,
              "jaywalking": 9
            },
            "2023_11_26_10": {
              "car": 196,
              "zone-1 • car": 82,
              "person": 339,
              "zone-2 • car": 60,
              "jaywalking": 32,
              "truck": 4,
              "zone-1 • truck": 3,
              "zone-2 • truck": 3,
              "stopped": 1
            },
            "2023_11_26_11": {
              "car": 221,
              "zone-1 • car": 119,
              "zone-2 • car": 70,
              "person": 410,
              "jaywalking": 45,
              "zone-1 • truck": 3,
              "truck": 3,
              "low_speed": 3,
              "zone-2 • truck": 1,
              "stopped": 1
            },
            "2023_11_26_12": {
              "car": 214,
              "zone-1 • car": 116,
              "zone-2 • car": 72,
              "person": 435,
              "jaywalking": 32,
              "truck": 13,
              "zone-1 • truck": 5,
              "low_speed": 1,
              "zone-2 • truck": 1,
              "stopped": 1
            },
            "2023_11_26_13": {
              "car": 201,
              "jaywalking": 34,
              "person": 356,
              "zone-1 • car": 112,
              "zone-2 • car": 76,
              "stopped": 3,
              "truck": 3,
              "zone-1 • truck": 2
            },
            "2023_11_26_14": {
              "car": 246,
              "person": 522,
              "zone-2 • car": 89,
              "jaywalking": 46,
              "zone-1 • car": 119,
              "stopped": 1,
              "zone-1 • truck": 7,
              "zone-2 • truck": 1,
              "truck": 15, "bus": 1,
              "low_speed": 1
            },
            "2023_11_26_15": {
              "car": 257,
              "person": 624,
              "zone-2 • car": 125,
              "low_speed": 6,
              "zone-1 • car": 146,
              "zone-1 • truck": 7,
              "truck": 9,
              "jaywalking": 52,
              "zone-2 • truck": 2,
              "stopped": 10
            },
            "2023_11_26_16": {
              "car": 159,
              "person": 504,
              "zone-1 • car": 130,
              "jaywalking": 62,
              "zone-2 • car": 96,
              "low_speed": 2, "truck": 4,
              "zone-1 • truck": 2,
              "zone-2 • truck": 1,
              "stopped": 4
            },
            "2023_11_28_08": {
              "car": 398,
              "truck": 46,
              "person": 651,
              "zone-2 • car": 148,
              "zone-1 • car": 290,
              "zone-1 • truck": 24,
              "jaywalking": 55,
              "low_speed": 7,
              "bus": 4,
              "zone-2 • truck": 4,
              "stopped": 6
            },
            "2023_11_28_09": {
              "car": 828,
              "person": 583,
              "zone-2 • car": 307,
              "zone-1 • car": 486,
              "jaywalking": 26,
              "low_speed": 7,
              "truck": 146,
              "zone-2 • truck": 17,
              "zone-1 • truck": 91,
              "stopped": 11,
              "bus": 9,
              "zone-1 • bus": 2
            },
            "2023_11_28_10": {
              "car": 639,
              "zone-1 • car": 409,
              "truck": 122,
              "zone-1 • truck": 78,
              "zone-2 • truck": 19,
              "person": 443,
              "zone-2 • car": 266,
              "low_speed": 6,
              "jaywalking": 28,
              "stopped": 22,
              "bus": 4,
              "zone-1 • bus": 1
            },
            "2023_11_28_11": {
              "car": 168,
              "zone-1 • car": 109,
              "person": 83,
              "jaywalking": 4,
              "zone-2 • car": 74,
              "stopped": 9,
              "truck": 32,
              "zone-1 • truck": 13,
              "low_speed": 3,
              "zone-2 • truck": 4
            },
            "2023_11_28_12": {
              "car": 89,
              "truck": 13,
              "zone-2 • car": 32,
              "zone-1 • car": 51,
              "low_speed": 3,
              "person": 63,
              "stopped": 3,
              "zone-1 • truck": 7,
              "jaywalking": 8,
              "zone-2 • truck": 1
            }
          }
        },
        "statistics_menu": { 'S': {}, 'A': {}, 'D': {}, 'P': {}, 'N': { 'car': 58 } }
      }
    }

    else {
      mlAnalysisAux = {
        "predictions": {
          "bus_2_0.83": [151, 94, 100, 49],
          "low_speed_2_0.83": [151, 94, 100, 49],
          "car_3_0.59": [286, 93, 38, 17],
          "car_5_0.46": [71, 100, 33, 16],
          "car_6_0.44": [2, 83, 28, 10],
          "motorcycle_7_0.29": [91, 91, 23, 12],
          "truck_8_0.28": [168, 108, 22, 19],
          "zone-1 • car_38_0.81": [55, 130, 59, 32],
          //"stopped_38_0.81": [92, 143, 59, 32]
        },
        "use_case_controllers": {
          "license_plate_detection": {
            "38": "56-YY-17"
          },
          "vehicle_analysis": {
            "38": "blue"
          }
        },
        "debug_visuals": {
          "polygons": [
            {
              "DESCRIPTION": "zone-1",
              "POINTS": [[48.35, 159.34], [76.63, 171.39], [300.11, 114.75], [286.43, 105.63]],
              "COLOUR": [184, 104, 249, 255]
            },
            {
              "DESCRIPTION": "zone-2",
              "POINTS": [[82.09, 174.141], [109.45, 181.421], [311.04, 125.7], [301.02, 116.58]],
              "COLOUR": [242, 53, 138, 255]
            }
          ],
          "lines": []
        },
        "vitals": {},
        "stats": {
          "pie_chart • detections_per_class": {
            "bus": "3",
            "car": "17",
            "person": "18",
            "zone-1 • car": "4",
            "zone-2 • truck": "2",
            "truck": "3",
          },
          "line_chart • detections_per_class_per_hour": {
            "2023_09_28_00": {
              "bus": "188",
              "car": "163",
              "person": "153",
              "zone-1 • car": "183",
              "zone-2 • car": "82",
              "truck": "166"
            },
            "2023_09_28_01": {
              "bus": "134",
              "car": "4",
              "person": "153",
              "zone-1 • car": "183",
              "zone-2 • car": "82",
              "truck": "136"
            },
            "2023_09_28_02": {
              "bus": "196",
              "car": "13",
              "person": "13",
              "zone-1 • car": "185",
              "zone-2 • car": "32",
              "truck": "126"
            },
            "2023_09_28_03": {
              "bus": "188",
              "car": "163",
              "person": "153",
              "zone-1 • car": "183",
              "zone-2 • car": "82",
              "truck": "166"
            },
            "2023_09_29_18": {
              "bus": "134",
              "car": "4",
              "person": "153",
              "zone-1 • car": "183",
              "zone-2 • car": "82",
              "truck": "136"
            },
            "2023_09_29_19": {
              "bus": "196",
              "car": "13",
              "person": "13",
              "zone-1 • car": "185",
              "zone-2 • car": "32",
              "truck": "126"
            },
            "2023_09_29_20": {
              "bus": "188",
              "car": "163",
              "person": "153",
              "zone-1 • car": "183",
              "zone-2 • car": "82",
              "truck": "166"
            },
            "2023_09_29_23": {
              "bus": "134",
              "car": "4",
              "person": "153",
              "zone-1 • car": "183",
              "zone-2 • car": "82",
              "truck": "136"
            },
            "2023_09_30_00": {
              "bus": "196",
              "car": "13",
              "person": "13",
              "zone-1 • car": "185",
              "zone-2 • car": "32",
              "truck": "126"
            },
            "2023_09_30_01": {
              "bus": "188",
              "car": "163",
              "person": "153",
              "zone-1 • car": "183",
              "zone-2 • car": "82",
              "truck": "166"
            },
            "2023_09_30_02": {
              "bus": "134",
              "car": "4",
              "person": "153",
              "zone-1 • car": "183",
              "zone-2 • car": "82",
              "truck": "136"
            },
            "2023_09_30_03": {
              "bus": "196",
              "car": "13",
              "person": "13",
              "zone-1 • car": "185",
              "zone-2 • car": "32",
              "truck": "126"
            },
            "2023_09_30_04": {
              "bus": "196",
              "car": "13",
              "person": "13",
              "zone-1 • car": "185",
              "zone-2 • car": "32",
              "truck": "126"
            },
            "2023_09_30_13": {
              "bus": "134",
              "car": "4",
              "person": "153",
              "zone-1 • car": "183",
              "zone-2 • car": "82",
              "truck": "136"
            },
            "2023_09_30_14": {
              "bus": "196",
              "car": "13",
              "person": "13",
              "zone-1 • car": "185",
              "zone-2 • car": "32",
              "truck": "126"
            },
            "2023_09_30_15": {
              "bus": "196",
              "car": "13",
              "person": "13",
              "zone-1 • car": "185",
              "zone-2 • car": "32",
              "truck": "126"
            },
            "2023_09_30_16": {
              "bus": "134",
              "car": "4",
              "person": "153",
              "zone-1 • car": "183",
              "zone-2 • car": "82",
              "truck": "136"
            },
            "2023_09_30_17": {
              "bus": "196",
              "car": "13",
              "person": "13",
              "zone-1 • car": "185",
              "zone-2 • car": "32",
              "truck": "126"
            },
            "2023_09_30_18": {
              "bus": "196",
              "car": "13",
              "person": "13",
              "zone-1 • car": "185",
              "zone-2 • car": "32",
              "truck": "126"
            },
            "2023_09_30_19": {
              "bus": "134",
              "car": "4",
              "person": "153",
              "zone-1 • car": "183",
              "zone-2 • car": "82",
              "truck": "136"
            },
            "2023_09_30_20": {
              "bus": "196",
              "car": "13",
              "person": "13",
              "zone-1 • car": "185",
              "zone-2 • car": "32",
              "truck": "126"
            },
            "2023_09_30_21": {
              "bus": "196",
              "car": "13",
              "person": "13",
              "zone-1 • car": "185",
              "zone-2 • car": "32",
              "truck": "126"
            },
            "2023_09_30_22": {
              "bus": "134",
              "car": "4",
              "person": "153",
              "zone-1 • car": "183",
              "zone-2 • car": "82",
              "truck": "136"
            },
            "2023_09_30_23": {
              "bus": "196",
              "car": "13",
              "person": "13",
              "zone-1 • car": "185",
              "zone-2 • car": "32",
              "truck": "126"
            }
          }
        },
        "statistics_menu": { 'S': {}, 'A': {}, 'D': {}, 'P': {}, 'N': { 'car': 58 } }
      }
    }

    const [mlAnalysis, setMlAnalysis] = useState(mlAnalysisAux);

    const [configFile, setConfigFile] = useState({
      "VISUALISATION_DETAILS": {
        "VISUALISE_OUTPUT": true,
        "MASKS_SAVING_MODE": "none",
        "UPSCALE_OUTPUT": false,
        "SHOW_PREDICTIONS": true,
        "MAX_QUEUE_SIZE": 50,
        "DEBUG_VISUALS":
        {
          "USE_UNIQUE_COLOURS_PER_ID": false,
          "SHOW_IDS": true,
          "SHOW_ID_TRAILS": false,
          "SHOW_CAPTIONS": true,
          "DELIMITED_AREAS": {
            "SHOW": true,
            "SHOW_CAPTIONS_WHEN_INSIDE": true,
            "SHOW_DELIMITED_AREA_PREFIX": false,
          },
          "SHOW_CONFIDENCE_SCORES": false,
          "SHOW_CAPTIONS_EXTRAS": true,
          "SHOW_ASSOCIATION_LINES": false,
          "SIDE_PANEL": {
            "SHOW": true,
            "WIDTH": 800,
          }
        },
        "STATISTICS_MENU":
        {
          "USE": true,
          "MODE": "per_frame",
          "SHOW_FPS": false,
          "POSITION": "bottom_right",
        },
        "WATERMARK": {
          "SIMPLE": {
            "USE": false,
            "COLOUR": "black",
            "POSITION": "bottom_left",
            "DOWNSCALE_FACTOR": 3.0,
          },
          "TILING": {
            "USE": true,
            "COLOUR": "black",
          }
        }
      }
    });

    // add event listeners
    useEffect(() => {

      // listen for theme changes
      function theme_change() {
        set_theme(localStorage.getItem("theme"));
      }
      window.addEventListener('theme_change', theme_change);

      // listen for grid layout changes
      function grid_layout_change() {
        let new_grid_span_for_all_sources = (localStorage.getItem(json_settings_menu_en.tabs[0].items[3].title.toLowerCase().replace(" (s)", "").replaceAll(" ", "_")) === (json_settings_menu_en.tabs[0].items[3].options[0].text) ? "2" : "1");
        set_times_icon(new_grid_span_for_all_sources === "2" ? "1" : "2");

      }
      window.addEventListener('grid_layout_change', grid_layout_change);

      // listen for info div and fullscreen div status changes
      function info_or_fullscreen_div_status_change() {

        if (localStorage.getItem("info_div_status") === "hidden" && localStorage.getItem("fullscreen_div_status") === "hidden")
          setState(false);
      }
      window.addEventListener('info_or_fullscreen_div_status_change', info_or_fullscreen_div_status_change);

      // listen for window resizes
      function resize() {
        set_window_width(window.innerWidth);
      }
      window.addEventListener('resize', resize);

      // listen for AI sources changes
      function ai_sources_change() {

        let ai_sources_aux = localStorage.getItem("ai_sources") !== null ? JSON.parse(localStorage.getItem("ai_sources")) : [];

        if (!are_the_two_lists_equal(ai_sources_aux, ai_sources))
          set_ai_sources(ai_sources_aux);
      }
      window.addEventListener('ai_sources_change', ai_sources_change);

      change_sources_image_data(currentSource, { "image_data": null, "height": 0, "width": 0 });

      /*setTimeout(() => {
        set_anomaly_detected("red");
        add_anomaly("red");
      }, 1500);
      
      setTimeout(() => {
        
        set_anomaly_detected(null);
        remove_anomaly();
        
      }, 30500);*/

      // do some cleanup (i.e., remove the event listeners if this component ends up being unmounted)
      return (() => {
        window.removeEventListener('theme_change', theme_change);
        window.removeEventListener('grid_layout_change', grid_layout_change);
        window.removeEventListener('info_or_fullscreen_div_status_change', info_or_fullscreen_div_status_change);
        window.removeEventListener('resize', resize);
        window.removeEventListener('ai_sources_change', ai_sources_change);
      })
    }, []);

    useEffect(() => {

      set_is_mobile(window_width < parseInt(localStorage.getItem("desktop_version_min_width")));

    }, [window_width]);

    //var aux = 0;
    //var fps = 0;
    //var frameCount = 0;

    const drawHeatmap = (context, rgbaMatrix, width, height) => {

      // Ensure the context and matrix are valid
      if (!context || !rgbaMatrix || rgbaMatrix.length === 0) return;

      // Iterate through the matrix and draw pixels on the canvas
      for (let y = 0; y < height; y++) {
        for (let x = 0; x < width; x++) {
          const [red, green, blue, alpha] = rgbaMatrix[y][x];

          context.fillStyle = `rgba(${red}, ${green}, ${blue}, ${alpha})`;
          context.fillRect(x, y, 1, 1);
        }
      }
    };

    // Provided dictionary
    const originalObject = {
      "2023_09_29_23": "Value1",
      "2023_09_30_00": "Value2",
      "2023_09_30_01": "Value3"
    };

    //var aux = 0;
    //var fps = 0;
    //var frameCount = 0;
    //var has_sent_thumbnail = false;


    //const ref = useRef();
    const [fps, setFps] = useState(0);
    const [frameCount, setFrameCount] = useState(0);
    const [aux, setAux] = useState(performance.now());

    const timeoutHandle = useRef(null);
    const socketIO = useRef(null);
    const disconnectDetected = useRef(false);
    const has_sent_thumbnail = useRef(false);
    const timeoutInterval = 5000;
    const reconnectInterval = 5000;

    const resetTimeout = useCallback(() => {
      if (timeoutHandle.current) {
        clearTimeout(timeoutHandle.current);
      }
      timeoutHandle.current = setTimeout(() => {
        if (!disconnectDetected.current) {
          console.warn("Timeout: No message received, attempting to reconnect...");
          if (socketIO.current) {
            socketIO.current.disconnect();
          }
        }
      }, timeoutInterval);
    }, [timeoutInterval]);

    const setupSocket = useCallback(() => {

      if(DEBUG_MODE){
        const image = new Image();
        image.src = "/media/images/debug/sample1.png";

        let ctx = null;

        if (state) {
          ctx = ref.current.getContext("2d");
        } else {
          try {
            ctx = thisCanvas.current.getContext("2d");
          } catch (e) {
            return;
          }
        }

        image.onload = function(res) {

            // @ts-ignore
            ctx.drawImage(image, 0, 0);

            if(image.height !== frameHeight)
                setFrameHeight(image.height);
    
            if(image.width !== frameWidth)
                setFrameWidth(image.width);    
              
            set_is_loading(false);
        };
      }

      else{

        if (!socketIO.current || disconnectDetected.current) {
          socketIO.current = io(REACT_APP_API_URL, {
            reconnection: false,
          });

          socketIO.current.on("connect", () => {
            console.info("Socket connected");
            disconnectDetected.current = false;
            resetTimeout();
          });

          socketIO.current.on("disconnect", (reason) => {
            console.warn(`Socket disconnected: ${reason}`);
            if (!disconnectDetected.current) {
              disconnectDetected.current = true;
              socketIO.current = null;
              setTimeout(setupSocket, reconnectInterval);
            }
          });

          socketIO.current.on("error", (message) => {
            console.error("Socket error:", message);
            if (!disconnectDetected.current) {
              disconnectDetected.current = true;
              socketIO.current = null;
              setTimeout(setupSocket, reconnectInterval);
            }
          });

          socketIO.current.on("stream", (message, callback) => {
            handleSocketIoCallback(message, callback);
          });

          if (currentSource) {
            socketIO.current.emit("stream", {
              message: "Stream",
              source_unique_identifier: currentSource,
              clip: sendClip,
              clip_seconds: 10,
              recording: false,
            });
          }
        }
      }

    }, [DEBUG_MODE, currentSource, resetTimeout, reconnectInterval, frameHeight, frameWidth, state, sendClip, currentHeatmap, showHeatmap]);

    function handleSocketIoCallback (message, callback) { // handle newly arrived data
      
      resetTimeout();
      disconnectDetected.current = false;
      const pixels = new Uint8ClampedArray(message["frame"]);
      let ctx = null;

      if (state) {
        ctx = ref.current.getContext("2d");
      } else {
        try {
          ctx = thisCanvas.current.getContext("2d");
        } catch (e) {
          callback("Received");
          return;
        }
      }

      const height = parseInt(message["shape"][0]);
      const width = parseInt(message["shape"][1]);

      if (Number.isNaN(height)) {
        callback("Received");
        return;
      }

      if (height !== 0 && width !== 0) {
        localStorage.setItem("current_source_frame_width", width);
        localStorage.setItem("current_source_frame_height", height);
        window.dispatchEvent(new Event("current_source_frame_dimensions_change"));
      }

      let image_data_aux = ctx.createImageData(width, height);
      image_data_aux.data.set(pixels);

      if (height !== frameHeight) {
        setFrameHeight(height);
        set_is_loading(false);
      }
      if (width !== frameWidth) {
        setFrameWidth(width);
        set_is_loading(false);
      }

      setTimeout(() => {
        if (!has_sent_thumbnail.current && image_data_aux !== null) {
          change_sources_image_data(currentSource, { "image_data": image_data_aux, "height": height, "width": width });
          has_sent_thumbnail.current = true;
        }
      }, 100);

      if (!is_visible) return;

      ctx.putImageData(image_data_aux, 0, 0);

      let received_heatmap = message["heatmap"];

      if (showHeatmap && received_heatmap !== undefined && received_heatmap !== null) {
        if (JSON.stringify(received_heatmap) !== JSON.stringify(currentHeatmap["rawHeatmap"]) && received_heatmap.length !== 0) {
          let heatmap_with_correct_colours = received_heatmap.map((line) => line.map((value) => heatmapColours[parseInt(value)]));
          setCurrentHeatmap({
            "rawHeatmap": received_heatmap,
            "colourCorrectedHeatmap": heatmap_with_correct_colours
          });
        }
        drawHeatmap(ctx, currentHeatmap["colourCorrectedHeatmap"], width, height);
      }

      if (performance.now() - aux > 1000) {
        setFps(frameCount);
        setFrameCount(0);
        setAux(performance.now());
      }

      setFrameCount(prevFrameCount => prevFrameCount + 1);

      if ("mlAnalysis" in message && message["mlAnalysis"] !== '') {
        let newMlAnalysis = structuredClone(message["mlAnalysis"]);
        let keys = Object.keys(newMlAnalysis["predictions"]);

        for (let i = 0; i < keys.length; i++) {
          if (newMlAnalysis["predictions"][keys[i]].length === 5) {
            newMlAnalysis["predictions"][keys[i]] = [
              newMlAnalysis["predictions"][keys[i]][0],
              newMlAnalysis["predictions"][keys[i]][1],
              newMlAnalysis["predictions"][keys[i]][2],
              newMlAnalysis["predictions"][keys[i]][3],
            ];
          }
        }

        newMlAnalysis["stats"]["line_chart • detections_per_class_per_hour"] = sortByDateAndHour(structuredClone(newMlAnalysis["stats"]["line_chart • detections_per_class_per_hour"]));

        setMlAnalysis(newMlAnalysis);
      }

      callback("Received");
    }


    useEffect(() => {
      setupSocket();

      return () => {
        if (socketIO.current) {
          socketIO.current.disconnect();
        }
        clearTimeout(timeoutHandle.current);
      };
    }, [setupSocket]);


    useEffect(() => {

      if (!is_visible)
        return;

      let stuffToStore = {
        "mlAnalysis": mlAnalysis,
        "configFile": configFile
      }

      // find existing data
      let existingData = localStorage.getItem("visualisationManagerArguments");
      let updatedData = {}

      if (existingData === null)
        updatedData[currentSource] = stuffToStore;

      else {
        updatedData = JSON.parse(existingData);
        updatedData[currentSource] = stuffToStore;
      }

      localStorage.setItem("visualisationManagerArguments", JSON.stringify(updatedData));
      window.dispatchEvent(new Event("visualisationManagerArgumentsChange"));

    }, [mlAnalysis, configFile, currentSource, is_visible]);

    const handleSaveClip = () => {
      return;
      setSendClip(!sendClip);
    };

    const show_info_div = (type) => {

      localStorage.setItem(type + "_status", "visible");
      localStorage.setItem((type === "info_div" ? "fullscreen_div" : "info_div") + "_status", "hidden");

      localStorage.setItem("info_or_fullscreen_div_current_source", currentSource);

      window.dispatchEvent(new Event("info_or_fullscreen_div_status_change"));

      set_hovered(false);
      if (!state) setState(true);
    }

    const handleHeatmapClick = () => {
      setShowHeatmap(!showHeatmap);
    }

    const handle_times_click = () => {

      const new_icon = times_icon === "1" ? "2" : "1";

      set_times_icon(new_icon);

      let current_saved_span_sources = JSON.parse(localStorage.getItem("saved_span_sources"));
      current_saved_span_sources[current_user + "_" + currentSource] = (new_icon === "1" ? "2" : "1");

      localStorage.setItem("saved_span_sources", JSON.stringify(current_saved_span_sources));
      window.dispatchEvent(new Event("source_grid_span_change"));
    }

    const add_anomaly = (type) => {

      let anomalies_detected_aux = localStorage.getItem("anomalies_detected") !== null ? JSON.parse(localStorage.getItem("anomalies_detected")) : [];
      if (!anomalies_detected_aux.includes(currentSource))
        anomalies_detected_aux.push([currentSource, type]);

      localStorage.setItem("anomalies_detected", JSON.stringify(anomalies_detected_aux));
      localStorage.setItem("alert_colour", type);
      window.dispatchEvent(new Event("anomaly_detected_change"));

      change_anomaly_detected(type);
    }

    const remove_anomaly = () => {

      let anomalies_detected_aux = localStorage.getItem("anomalies_detected") !== null ? JSON.parse(localStorage.getItem("anomalies_detected")) : [];
      anomalies_detected_aux = anomalies_detected_aux.filter((item) => item[0] !== currentSource)

      localStorage.setItem("anomalies_detected", JSON.stringify(anomalies_detected_aux));

      // tell other components which colour to show (if any)
      if (anomalies_detected_aux.length === 0) {
        localStorage.setItem("alert_colour", "none");
        change_anomaly_detected(null);
      }

      else {

        if (anomalies_detected_aux.filter((item) => item[1] === "red").length !== 0)
          localStorage.setItem("alert_colour", "red");

        else
          localStorage.setItem("alert_colour", "yellow");
      }

      window.dispatchEvent(new Event("anomaly_detected_change"));
    }

    const CustomTooltip = styled(({ className, ...props }) => (
      <Tooltip {...props} classes={{ popper: className }} />
    ))(() => ({
      [`& .${tooltipClasses.tooltip}`]: {
        display: (is_mobile ? "none" : ""),
        backgroundColor: (anomaly_detected !== "none" ? (anomaly_detected === "red" ? red_alert_colour : yellow_alert_colour) : (ai_sources.includes(currentSource) ? ai_colour : "rgba(60, 60, 60, 1.0)")).replace("1.0", "0.75"),
        color: "rgba(255, 255, 255, 0.85)",
        fontFamily: "regular",
        fontSize: 10,
        zIndex: "10000 !important",
      },
    }));

    return (
      <div
        className={anomaly_detected !== "none" ? (anomaly_detected === "red" ? styles.root_red : styles.root_yellow) : styles.super_root}
        enter_wiggle_mode={enter_wiggle_mode}
        style={{
          verticalAlign: "middle",
          flex: "1",
          margin: "0 auto",
          height: "100%",
          width: "100%",
          position: "relative",
          zIndex: ((hovered) ? "2000" : "3000"),
          overflow: "hidden",
          borderRadius: "8px",
          border: "3px solid " + (anomaly_detected !== "none" ? (anomaly_detected === "red" ? red_alert_colour : yellow_alert_colour) : (ai_sources.includes(currentSource) ? ai_colour : "rgba(60, 60, 60, 1.0)")),
          transform: (anomaly_detected !== "none" ? "scale(1.005)" : (active_id === currentSource ? "scale(1.05)" : "scale(1.0)")),
          transition: "all 0.25s ease-in-out",
          //backgroundColor: "green",
        }}>
        <div
          className={styles.root}
          onMouseEnter={() => set_hovered(true)}
          onMouseLeave={() => set_hovered(false)}
          style={{
            cursor: "grab",
            height: "100%",
            width: "100%",
            position: "relative",
            margin: "0 auto",
            textAlign: "center",
            overflow: "hidden",
            imageRendering: "high-quality",
            display: "inline-flex",
            borderRadius: "5px",
            flexDirection: "row",
            backgroundColor: (frameWidth !== 0 ? "rgba(0, 0, 0, 1.0)" : (theme === "Light" ? "rgba(180, 180, 180, 1.0)" : "rgba(35, 35, 35, 1.0)")),
            //overflow: "hidden",
            opacity: (active_id !== null && active_id !== currentSource && anomaly_detected === null ? "0.65" : "1.0"),
            aspectRatio: parseInt(localStorage.getItem("aspect_ratio")),
            transition: "all 0.25s ease-in-out",
          }}
        >

          {is_visible ?
            <div className={styles.visualisationManagerDiv}>
              <VisualisationManager
                currentSource={currentSource}
                mlAnalysis={mlAnalysis}
                frameWidth={frameWidth}
                frameHeight={frameHeight}
                scale={times_icon === "1" ? "2" : "1"}
                configFile={configFile} />
            </div>
            : <></>}

          <div style={{
            position: "absolute",
            zIndex: (anomaly_detected !== "none" ? "300000" : "1000"),
            height: "100%",
            width: "100%",
            aspectRatio: parseInt(localStorage.getItem("aspect_ratio")),
          }} />
          <div className={styles.circular_progress_div}>
            <CircularProgress
              size={50}
              style={{
                //display: "none",
                color: (theme === "Light" ? "var(--colour_three)" : "rgba(80, 80, 80, 1.0)"),
                opacity: (is_loading ? "1.0" : "0.0")
              }} />
          </div>
          <div
            className={is_loading ? (theme === "Light" ? styles.skeleton_light_mode : styles.skeleton_dark_mode) : null}
            style={{
              height: "100%",
              width: "100%",
              flexBasis: "0px",
              flexGrow: "1",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
              //borderRadius: "8px",
              //overflow: "hidden",
              aspectRatio: localStorage.getItem("aspect_ratio"),
            }}
          >
            <CanvasExternalContainer currentSourceCounter={currentSourceCounter}>
              {source_name ? (
                <div
                  className="head-image"
                  style={{
                    height: "100%",
                    width: "100%",
                    margin: "0 auto",
                    //border: (anomaly_detected !== "none" ? ("1px solid " + (anomaly_detected === "red" ? red_alert_colour : yellow_alert_colour)) : theme === "Light" ? "1px solid var(--colour_one)" : "1px solid rgba(60, 60, 60, 1.0)"),
                    //border: "1px solid red",
                  }}>
                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                      //overflow: "hidden",
                      //border: "1px solid green",
                    }}
                  >
                    <div
                      ref={parent_ref}
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "-8px",
                        height: "100%",
                        width: "104%",
                        //border: "1px solid magenta",
                      }}>
                      <CanvasContainer
                        id={currentSource}
                        ref={thisCanvas}
                        width={frameWidth}
                        height={frameHeight}
                        style={{
                          //borderRadius: "8px",
                        }}
                      ></CanvasContainer>
                    </div>

                    {/* ai_sources.includes(currentSource) ? <AIIndicator anomaly_detected = {anomaly_detected}/> : <></> */}

                    <div
                      className={styles.control_bar_super_div}
                      style={{
                        //backgroundColor: (anomaly_detected !== "none" ? (anomaly_detected === "red" ? red_alert_colour : yellow_alert_colour) : theme === "Light" ? 'var(--colour_one_slightly_transparent)' : "rgba(0, 0, 0, 0.45)"),
                        backgroundColor: (anomaly_detected !== "none" ? (anomaly_detected === "red" ? red_alert_colour : yellow_alert_colour).replace("1)", "0.65)") : (ai_sources.includes(currentSource) ? ai_colour.replace("1)", "0.75") : "rgba(0, 0, 0, 0.4)")),
                        visibility: (hovered ? "visible" : "hidden"),
                        opacity: (hovered ? "1.0" : "0.0"),
                      }}>

                      <div
                        className={styles.source_name_super_div}>
                        <div
                          className={styles.source_name}
                          style={{
                            padding: "8px",
                            paddingTop: "9px",
                            color: "white",
                            fontSize: "50%",
                            backgroundColor: "rgba(0, 0, 0, 0.0)",
                          }}
                        >
                          {source_name.toUpperCase().replaceAll("_", " ")}
                        </div>
                      </div>

                      <ButtonGroup
                        onMouseEnter={() => { localStorage.setItem("draggable_should_be_disabled", true); window.dispatchEvent(new Event("draggable_should_be_disabled_change")); }}
                        onMouseLeave={() => { localStorage.setItem("draggable_should_be_disabled", false); window.dispatchEvent(new Event("draggable_should_be_disabled_change")); }}
                        justify="center"
                        variant="contained"
                        aria-label="outlined button group"
                        color="secondary"
                        className={styles.button_group}
                        style={{
                          visibility: (hovered ? "visible" : "hidden"),
                          opacity: (hovered ? "1.0" : "0.0"),
                          height: "100%",
                          backgroundColor: "rgba(0, 0, 0, 0.0)",
                          boxShadow: "none",
                          //border: "1px solid red"
                        }}
                        size="small"
                      >
                        <Button
                          onClick={() => show_info_div("info_div")}
                          style={{
                            overflow: "visible",
                            height: "fit-content",
                            width: "20px",
                            borderLeft: (anomaly_detected !== "none" ? "1px solid rgba(190, 190, 190, 0.5)" : "1px solid rgba(50, 50, 50, 0.55)"),
                            borderRight: (anomaly_detected !== "none" ? "1px solid rgba(190, 190, 190, 0.5)" : "1px solid rgba(50, 50, 50, 0.55)"),
                            backgroundColor: "rgba(0, 0, 0, 0.0)",
                            borderRadius: "0px",
                            cursor: "pointer",
                          }}>
                          <InfoIcon
                            style={{
                              margin: "1px",
                              height: "21px",
                              cursor: "pointer",
                            }} />
                        </Button>

                        <Button
                          onClick={() => show_info_div("fullscreen_div")}
                          style={{
                            height: "fit-content",
                            width: "20px",
                            borderRight: (anomaly_detected !== "none" ? "1px solid rgba(190, 190, 190, 0.5)" : "1px solid rgba(50, 50, 50, 0.55)"),
                            backgroundColor: "rgba(0, 0, 0, 0.0)",
                            cursor: "pointer",
                          }}>
                          <div
                            className={styles.expand_icon}
                            style={{
                              height: "23px",
                              cursor: "pointer",
                            }} />
                        </Button>

                        <Button
                          onClick={() => handleSaveClip()}
                          style={{
                            height: "fit-content",
                            width: "20px",
                            borderRight: (anomaly_detected !== "none" ? "1px solid rgba(190, 190, 190, 0.5)" : (window_width > 500 ? "1px solid rgba(50, 50, 50, 0.55)" : "1px solid rgba(50, 50, 50, 0.0)")),
                            backgroundColor: "rgba(0, 0, 0, 0.0)",
                            cursor: "pointer",
                          }}>
                          <MovieIcon
                            style={{
                              margin: "1px",
                              height: "21px",
                              cursor: "pointer",
                            }} />
                        </Button>

                        <Button
                          onClick={() => handleHeatmapClick()}
                          style={{
                            height: "fit-content",
                            width: "20px",
                            position: "relative",
                            borderRight: (anomaly_detected !== "none" ? "1px solid rgba(190, 190, 190, 0.5)" : (window_width > 500 ? "1px solid rgba(50, 50, 50, 0.55)" : "1px solid rgba(50, 50, 50, 0.0)")),
                            backgroundColor: "rgba(0, 0, 0, 0.0)",
                            cursor: "pointer",
                          }}>

                          <LensBlurIcon
                            style={{
                              margin: "1px",
                              height: "21px",
                              cursor: "pointer",
                              opacity: (showHeatmap ? "1.0" : "0.45"),
                              transition: "all 0.15s ease-in-out",
                            }} />
                          <div
                            className={styles.lens_blur_button_state}
                            style={{
                              visibility: (showHeatmap ? "visible" : "hidden"),
                              opacity: (showHeatmap ? "1.0" : "0.0"),
                            }}>
                            <CheckIcon
                              style={{
                                height: "12px",
                                cursor: "pointer",
                              }} />
                          </div>
                        </Button>

                        <Button
                          onClick={() => handle_times_click()}
                          style={{
                            height: "fit-content",
                            width: "20px",
                            backgroundColor: "rgba(0, 0, 0, 0.0)",
                            display: (window_width > 500 ? "" : "none"),
                            cursor: "pointer",
                          }}>
                          <div
                            className={times_icon === "1" ? styles.times_one_icon : styles.times_two_icon}
                            style={{
                              height: "23px",
                              cursor: "pointer",
                            }} />
                        </Button>

                      </ButtonGroup>
                    </div>
                  </div>
                </div>
              ) : <></>}
            </CanvasExternalContainer>
          </div>
        </div>
      </div>
    );
  }
);