//import { css } from "@emotion/react";
import styled from "@emotion/styled";

const CanvasExternalContainer = styled.div`
  display: flex;
  height: 100%;
  width: 101%;
  align-items: baseline;
  overflow: visible;
  z-index: 10;
  //border: 1px solid blue;
`;

export default CanvasExternalContainer;
