import React from 'react';
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import TextField from '@mui/material/TextField';
import Button from "../reusable_components/Button.js";
import styles from '../../styles/login_menu.module.scss';
import { logInSuccess } from "../../store/slices/userSlice";
import json_login_menu_en from '../../jsons/en/login_menu_en.json';
import json_login_menu_pt from '../../jsons/pt/login_menu_pt.json';
import { usePostCredentialsMutation } from "../../services/AuthService";

const FormData = require("form-data");

const LoginComponent = () => {

    // variables and states
    const [language, set_language] = useState(localStorage.getItem("language"));
    const json_data = (language === "English" ? json_login_menu_en : json_login_menu_pt);
    const [theme, set_theme] = useState(localStorage.getItem("theme"));
    const [is_loading, set_is_loading] = useState(false);

    const dispatch = useDispatch();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [username_helper_text, set_username_helper_text] = useState("");
    const [password_helper_text, set_password_helper_text] = useState("");
    const [postCredentials, { data, error, isSuccess, isError }] =
    usePostCredentialsMutation();

    // add event listeners
    useEffect(() => {

        // listen for theme changes
        function theme_change() {
            set_theme(localStorage.getItem("theme"));
        }
        window.addEventListener('theme_change', theme_change);

        // listen for language changes
        function language_change() {
            set_language(localStorage.getItem("language"));
        }
        window.addEventListener('language_change', language_change);

        // do some cleanup (i.e., remove the event listeners if this component ends up being unmounted)
        return(() => {
            window.removeEventListener('theme_change', theme_change);
            window.removeEventListener('language_change', language_change);
        })

    }, []);

    useEffect(() => {
    if (isSuccess && data) {
        dispatch(
        logInSuccess({
            accessToken: data.access_token,
            tokenType: data.token_type,
        })
        );
        
        window.dispatchEvent(new Event("logged_in_change"));
        window.location.reload();
    }
    }, [isSuccess, data, dispatch]);

    useEffect(() => {
        if (isError && error) {
            
            if (error.data && error.data.detail){
                
                set_username_helper_text(json_data.username_textfield_wrong_username_text);
                set_password_helper_text(json_data.password_textfield_wrong_password_text);
                set_is_loading(false);
            }
        }
    
    // eslint-disable-next-line
    }, [isError, error, dispatch]);

    async function handle_submit(e) {

        // invalid input for the username field (invalid characters)
        if(username === "" || username.match("^[A-Za-z0-9]+$") === null)
            set_username_helper_text(json_data.username_textfield_invalid_characters_error_text);
        
        else
            set_username_helper_text("");

        // invalid input for the username field (invalid characters)
        if(password === "")
            set_password_helper_text(json_data.password_textfield_invalid_characters_error_text);

        // valid input
        else{

            set_password_helper_text("");

            set_is_loading(true);
    
            try {
                localStorage.setItem("current_user", username); 
                let formData = new FormData();
                formData.append("username", username);
                formData.append("password", password);
                postCredentials(formData);

            } catch (e) {
                // Clear login form
                document.getElementById("loginForm").reset();
                set_is_loading(false);
            }
        }
    }

    const sign_up = () => {
        // TODO:
    };

    const handle_key_down = (e) => {
        if(e["code"] === "Enter")
            handle_submit();
    }

    return (
        <div 
            className = {styles.root}
            style = {{
                backgroundColor: (theme === "Light" ? "rgba(255, 255, 255, 0.85)" : "var(--colour_one_dark_mode)"),
                border: (theme === "Light" ? "0.020in solid var(--colour_two)" : "0.020in solid rgba(80, 80, 80, 0.55)"),
                boxShadow: (theme === "Light" ? "rgba(100, 100, 100, 0.35) 0px 0px 10px" : "rgba(10, 15, 21, 0.35) 0px 8px 24px"),
            }}
            onKeyDown = {handle_key_down}
            tabIndex = "0">
            
            <div 
                className = {styles.top_bar_div}
                style = {{
                    border: (theme === "Light" ? "1px solid rgba(248, 248, 248, 1.0)" : "1px solid var(--colour_one_dark_mode)"),
                }}>
                <div 
                    className = {styles.top_bar}
                    style = {{
                        opacity: "1.0",
                        //backgroundImage: (theme === "Light" ? "url(\"/media/images/pattern.jpg\")" : "url(\"/media/images/pattern_2_resized.jpg\")"),
                        backgroundImage: (theme === "Light" ? "url(\"/media/images/background_10.jpg\")" : "url(\"/media/images/login_8.jpg\")"),
                    }}/>
                <div 
                    className = {styles.top_bar_fade}
                    style = {{
                        opacity: "1.0",
                        backgroundImage: (theme === "Light" ? "linear-gradient(to bottom, rgba(255,255,255,0.0), rgba(248, 248, 248, 1.0))" : "linear-gradient(to bottom, rgba(0, 0, 0, 0.0), var(--colour_one_dark_mode))")
                    }}/>
            </div>
            
            <div 
                className = {styles.top_text_div}
                style = {{
                    borderTop: (theme === "Light" ? "" : "2px solid var(--colour_one_dark_mode)"),
                }}>
                <div 
                    className = {styles.top_text}
                    style = {{
                        color: (theme === "Light" ? "var(--colour_one)" : "rgba(230, 230, 230, 1.0)")
                    }}
                    >{json_data.top_text.toUpperCase()}</div>
            </div>
            <div className = {styles.secondary_text_div}>
                <div 
                    className = {styles.secondary_text}
                    style = {{
                        color: (theme === "Light" ? "var(--colour_one)" : "rgba(230, 230, 230, 1.0)")
                    }}>{json_data.secondary_text.replaceAll("-", "‑")}</div>
            </div>

            <div className = {styles.text_field_div}>
                <TextField 
                    onChange = {(e) => setUsername(e.target.value)}
                    className = {styles.text_field} 
                    id = "outlined-basic" 
                    error = {username_helper_text !== "" ? true : false}
                    InputProps = {{ style: { 
                        color: (theme === "Light" ? "var(--colour_one)" : "rgba(200, 200, 200, 1.0)"), 
                        borderRadius: "8px", 
                        fontSize: 13.5,
                        border: (theme === "Light" ? "" : "0.020in solid rgba(80, 80, 80, 0.5)"), 
                    } }}
                    InputLabelProps = {{ style: { 
                        color: (theme === "Light" ? "var(--colour_one)" : "rgba(200, 200, 200, 1.0)"), 
                        fontSize: 13.5 ,
                        backgroundColor: (theme === "Light" ? "" : "var(--colour_one_dark_mode)"),
                        paddingLeft: (theme === "Light" ? "" : "5px"),
                        paddingRight: (theme === "Light" ? "" : "5px"),
                    } }}
                    autoComplete = "username"
                    helperText = {username_helper_text}
                    label = {json_data.username_hint} 
                    variant = "outlined" 
                    type = "text" />
            </div>
            <div className = {styles.text_field_div}>
                <TextField 
                    onChange={(e) => setPassword(e.target.value)}
                    className = {styles.text_field} 
                    id = "outlined-basic" 
                    error = {password_helper_text !== "" ? true : false}
                    InputProps = {{ style: { 
                        color: (theme === "Light" ? "var(--colour_one)" : "rgba(200, 200, 200, 1.0)"), 
                        borderRadius: "8px", 
                        fontSize: 13.5,
                        border: (theme === "Light" ? "" : "0.020in solid rgba(80, 80, 80, 0.5)"), 
                    } }}
                    InputLabelProps = {{ style: { 
                        color: (theme === "Light" ? "var(--colour_one)" : "rgba(200, 200, 200, 1.0)"), 
                        fontSize: 13.5 ,
                        backgroundColor: (theme === "Light" ? "" : "var(--colour_one_dark_mode)"),
                        paddingLeft: (theme === "Light" ? "" : "5px"),
                        paddingRight: (theme === "Light" ? "" : "5px"),
                    } }}
                    autoComplete = "current-password"
                    helperText = {password_helper_text}
                    label = {json_data.password_hint} 
                    variant = "outlined" 
                    type = "password" />
            </div>
            <div className = {styles.forgot_password_text_div}>
                <div 
                    className = {styles.forgot_password_text}
                    style = {{
                        color: (theme === "Light" ? "var(--colour_one)" : "rgba(230, 230, 230, 1.0)")
                    }}>{json_data.forgot_password_text}</div>
            </div>
            
            <div className = {styles.bottom_buttons_div}>
                
                <div 
                    className = {styles.button_div}
                    onClick = {() => sign_up()}>
                    <Button 
                        key = {0}
                        orientation = {"vertical"}
                        text = {json_data.sign_up_button_text}
                        text_size = {"13.5px"}
                        text_family = {"semibold"}
                        text_color = {(theme === "Light" ? "rgba(110, 110, 110, 1.0)" : "rgba(190, 190, 190, 1.0)")}
                        background_color = {(theme === "Light" ? "rgb(200, 200, 200, 0.35)" : "rgba(255, 255, 255, 0.08)")}
                        icon_url = {null}
                        icon_size = {null}
                    />
                </div>
                
                
                <div 
                    className = {styles.button_div}
                    onClick = {() => handle_submit()}
                    >
                    <Button 
                        key = {1}
                        orientation = {"vertical"}
                        text = {json_data.sign_in_button_text}
                        text_size = {"13.5px"}
                        text_family = {"semibold"}
                        text_color = {(theme === "Light" ? "var(--main_button_text_colour)" : "rgba(50, 50, 50, 1.0)")}
                        background_color = {(theme === "Light" ? "var(--colour_one)" : "rgba(230, 230, 230, 1.0)")}
                        icon_url = {null}
                        icon_size = {null}
                        is_loading = {is_loading}
                    />
                </div>
                
            </div>
        </div>
    );
}

export default LoginComponent;