import ReactDOM from 'react-dom';
import useMeasure from "react-use-measure";
import React, { useState, useEffect } from "react";
import ClickAwayListener from 'react-click-away-listener';
import styles from '../../styles/statistics_menu.module.scss';
import json_statistics_menu_en from '../../jsons/en/statistics_menu_en.json';
import json_statistics_menu_pt from '../../jsons/pt/statistics_menu_pt.json';
import StatisticsComponent from '../sources/visualization/StatisticsComponent';

const StatisticsMenu = (props) => {

    // variables and states
    const language = localStorage.getItem("language");
    const json_data = (language === "English" ? json_statistics_menu_en : json_statistics_menu_pt);
    const aux_ref = React.createRef();
    const [theme, set_theme] = useState(localStorage.getItem("theme"));
    const [root_ref, root_dimensions] = useMeasure();
    const [active_tab, set_active_tab] = useState(0);
    const [window_width, set_window_width] = useState(window.innerWidth);
    const [is_mobile, set_is_mobile] = useState(window.innerWidth < parseInt(localStorage.getItem("desktop_version_min_width")));

    // add event listeners
    useEffect(() => {

        // listen for theme changes
        function theme_change() {
            set_theme(localStorage.getItem("theme"));
        }
        window.addEventListener('theme_change', theme_change);

        // listen for window resizes
        function resize() { 
            set_window_width(window.innerWidth); 
        }
        window.addEventListener('resize', resize);

        // listen for theme changes
        function statistics_component_active_tab_change() {
            set_active_tab(parseInt(localStorage.getItem("statistics_component_active_tab")));
        }
        window.addEventListener('statistics_component_active_tab_change', statistics_component_active_tab_change);

        // do some cleanup (i.e., remove the event listeners if this component ends up being unmounted)
        return(() => {
            window.removeEventListener('theme_change', theme_change);
            window.removeEventListener('resize', resize);
            window.removeEventListener('statistics_component_active_tab_change', statistics_component_active_tab_change);
        })

    }, []);

    useEffect(() => {

        set_is_mobile(window_width < parseInt(localStorage.getItem("desktop_version_min_width")));
    
    }, [window_width]);

    function determine_position_and_height() {
        
        if(is_mobile && root_dimensions.height !== 0){
            
            props.change_statistics_menu_style({
                maxHeight: parseInt(root_dimensions.height) + "px",
                visibility: props.statistics_menu_style["visibility"],
                position: "fixed",
                top: "48%",
                transform: "translateY(-48%)",
                opacity: props.statistics_menu_style["opacity"], 
                transition: props.statistics_menu_style["transition"]
            });
        }

        else if(!is_mobile){

            let left_reference_x = ReactDOM.findDOMNode(props.statistics_menu_ref.current).getBoundingClientRect().x;
            let left_reference_width = ReactDOM.findDOMNode(props.statistics_menu_ref.current).getBoundingClientRect().width
            let left_current_width = ReactDOM.findDOMNode(aux_ref.current).getBoundingClientRect().width;
            
            let left = ((left_reference_x + (left_reference_width / 2))) - (left_current_width / 2) + "px";
            
            if(left !== props.statistics_menu_style["left"]){
                props.change_statistics_menu_style({
                    visibility: props.statistics_menu_style["visibility"], 
                    opacity: props.statistics_menu_style["opacity"], 
                    left: left, 
                    top: props.statistics_menu_style["top"],
                    position: "absolute",
                    transition: props.statistics_menu_style["transition"]
                });
            }
        }
    }
    
    useEffect(() => {
        if(is_mobile)
            determine_position_and_height();
    }, is_mobile ? [active_tab] : null);

    useEffect(() => {
        determine_position_and_height();
    }, is_mobile ? [root_dimensions.height] : null);

    const handle_click_away = () => {
        return;
    }

    return (
        <div
            className = {is_mobile ? styles.super_root : null} 
            ref = {is_mobile ? root_ref : null}
            style = {is_mobile ? props.statistics_menu_style : null}>

            <ClickAwayListener onClickAway = {props.close_statistics_menu()}>
                <div 
                    onScroll = {() => props.close_statistics_menu()}
                    //onMouseLeave = {() => props.close_statistics_menu()}
                    className = {styles.root}
                    style = {!is_mobile ? props.statistics_menu_style : null}>

                        <div 
                            className = {styles.root_inner}
                            ref = {aux_ref}
                            style = {{
                                backgroundColor: (theme === "Light" ? "rgba(245, 245, 245, 1.0)" : "var(--colour_one_dark_mode)"),
                                border: (theme === "Light" ? "0.020in solid var(--colour_two)" : "0.020in solid rgba(80, 80, 80, 0.55)"),
                                boxShadow: (theme === "Light" ? "rgba(100, 100, 100, 0.35) 0px 0px 10px" : "rgba(10, 15, 21, 0.75) 0px 8px 24px"),
                            }}>

                            <div className = {styles.hover_square} />

                            <div 
                                className = {styles.close_button_div}
                                style = {{
                                    display: (is_mobile ? "" : "none"),
                                    backgroundColor: (theme === "Light" ? "var(--colour_one)" : "rgba(190, 190, 190, 1.0)")
                                }}
                                onClick = {() => window.dispatchEvent(new Event("all_menus_should_disappear"))}
                                >
                                <div className = {styles.vertical_align}>
                                    <div 
                                        className = {styles.close_button} 
                                        style = {{
                                            backgroundImage: (theme === "Light" ? "url(\"/media/icons/close_white_2.png\")" : "url(\"/media/icons/close_4.png\")")
                                        }}/>
                                </div>
                            </div>
                            
                            <div className = {styles.root_inner_top_part}>
                                
                                <div className = {styles.top_bar_div}>
                                    <div 
                                        className = {styles.top_bar}
                                        style = {{
                                            opacity: "1.0",
                                            backgroundImage: "url(\"/media/images/menu_pattern_THEME_mode.jpg\")".replace("THEME", theme.toLowerCase()),
                                        }}/>
                                    <div 
                                        className = {styles.top_bar_fade}
                                        style = {{
                                            opacity: "1.0",
                                            backgroundImage: (theme === "Light" ? "linear-gradient(to bottom, rgba(235,235,235,0.55), rgba(245, 245, 245, 1.0))" : "linear-gradient(to bottom, rgba(20, 25, 32, 0.6), var(--colour_one_dark_mode))")
                                        }}/>

                                    <div className = {styles.text_super_div}>
                                        <div className = {styles.top_text_div} >
                                            <div 
                                                className = {styles.top_text}
                                                style = {{
                                                    color: (theme === "Light" ? "var(--colour_one)" : "rgba(230, 230, 230, 1.0)")
                                                }}
                                                >{json_data.top_text.toUpperCase()}</div>
                                        </div>
                                        <div className = {styles.secondary_text_div}>
                                            <div 
                                                className = {styles.secondary_text}
                                                style = {{
                                                    color: (theme === "Light" ? "var(--secondary_text_colour)" : "rgba(230, 230, 230, 0.75)")
                                                }}>{json_data.secondary_text.replaceAll("-", "‑")}</div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            
                            <div className = {styles.root_inner_bottom_part}>
                                {props.is_visible ? 
                                    <StatisticsComponent 
                                        key = {1}
                                        source = {"general"}
                                        visibleTabs = {"all"} /> 
                                : <></>}
                            </div>
                        
                        </div>
                    
                    </div>
            </ClickAwayListener>
        </div>
    );
}

export default StatisticsMenu;