import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import REACT_APP_API_URL from "../config/Api";
import { store } from "../store/store";

export const sourceApi = createApi({
  reducerPath: "source",
  tagTypes: ["availableSources", "availableSourceGroups"],
  baseQuery: fetchBaseQuery({
    baseUrl: REACT_APP_API_URL,
    prepareHeaders: (headers) => {
      const token = store.getState().user.user.accessToken;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    postCreateNewSource: builder.mutation({
      query(body) {
        return {
          url: `live/createNewSource`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["availableSources"],
    }),
    postUpdateSource: builder.mutation({
      query(body) {
        return {
          url: `live/updateSource`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["availableSources"],
    }),
    deleteSource: builder.mutation({
      query(body) {
        return {
          url: `live/deleteSourceByUID`,
          method: "DELETE",
          body,
        };
      },
      invalidatesTags: ["availableSources"],
    }),
    getAvailableSources: builder.query({
      query: (args) => {
        const { linkedOnly, activeOnly, includeLinkedProperty } = args;
        return {
          url: `live/available?`,
          params: { linkedOnly, activeOnly, includeLinkedProperty },
        };
      },
      providesTags: ["availableSources"],
    }),
    getAvailableSourceGroups: builder.query({
      query: () => `live/availableGroups?`,
      providesTags: ["availableSourceGroups"],
    }),
    postCreateNewSourceGroup: builder.mutation({
      query(body) {
        return {
          url: `live/createNewSourceGroup`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["availableSourceGroups"],
    }),
    postLinkSourceToUserByUID: builder.mutation({
      query(body) {
        return {
          url: `live/linkSourceToUserByUID`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["availableSources"],
    }),
    deleteLinkSourceToUserByUID: builder.mutation({
      query(body) {
        return {
          url: `live/unlinkSourceFromUserByUID`,
          method: "DELETE",
          body,
        };
      },
      invalidatesTags: ["availableSources"],
    }),

    deleteSourceGroup: builder.mutation({
      query(body) {
        return {
          url: `live/deleteSourceGroupByName`,
          method: "DELETE",
          body,
        };
      },
      invalidatesTags: ["availableSourceGroups", "availableSources"],
    }),
    postActivateSource: builder.mutation({
      query(body) {
        return {
          url: `live/updateSource/setActive`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["availableSources"],
    }),
    postDeactivateSource: builder.mutation({
      query(body) {
        return {
          url: `live/updateSource/setInactive`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["availableSources"],
    }),
  }),
});

export const {
  usePostCreateNewSourceMutation,
  usePostUpdateSourceMutation,
  useDeleteSourceMutation,
  useGetAvailableSourcesQuery,
  useGetAvailableSourceGroupsQuery,
  usePostCreateNewSourceGroupMutation,
  usePostLinkSourceToUserByUIDMutation,
  useDeleteLinkSourceToUserByUIDMutation,
  useDeleteSourceGroupMutation,
  usePostActivateSourceMutation,
  usePostDeactivateSourceMutation,
} = sourceApi;
