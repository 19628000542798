import styles from "../../styles/button.module.scss";
import CircularProgress from '@material-ui/core/CircularProgress';

const Button = (props) => {

    return (
        <div 
            className = {styles.root}
            style = {{
                border: (props.border !== null ? props.border : ""),
                height: ((props.text_size !== null && !props.force_full_height) ? "fit-content" : "100%"),
            }}>
            <div className = {styles.vertical_align}>
                <div 
                    className = {styles.root_inner}
                    style = {{
                        backgroundColor: (props.background_color !== null ? props.background_color : "var(--colour_one)"),
                    }}>
                    <div 
                        className = {styles.root_inner_inner}
                        style = {{
                            paddingTop: ((props.text_size !== null  && !props.force_full_height) ? (parseFloat(props.text_size.replace("px", "")) + "px") : ""),
                            paddingBottom: ((props.text_size !== null  && !props.force_full_height) ? (parseFloat(props.text_size.replace("px", "")) + "px") : ""),
                            width: (props.orientation === "horizontal" ? "fit-content" : "100%"),
                        }}>
                        <div 
                            className = {styles.icon} 
                            style = {{
                                flexBasis: (props.orientation === "horizontal" ? "" : "100%"),
                                height: (props.icon_size !== null ? props.icon_size : "18px"),
                                width: (props.icon_size !== null ? props.icon_size : "18px"),
                                display: (props.icon_url !== null ? "" : "none"),
                                backgroundImage: (props.icon_url !== null ? props.icon_url : ""),
                                backgroundPosition: (props.orientation === "horizontal" ? "right" : "center"),
                            }}/>

                        <div 
                            className = {styles.text}
                            style = {{
                                display: (props.text !== null ? "" : "none"),
                                opacity: (props.is_loading ? "0.0" : "1.0"),
                                flexBasis: (props.orientation === "horizontal" ? "" : "100%"),
                                textAlign: (props.orientation === "horizontal" ? "left" : "center"),
                                color: (props.text_color !== null ? props.text_color : "white"),
                                fontSize: (props.text_size !== null ? props.text_size : "11.5px"),
                                fontFamily: (props.text_family !== null ? props.text_family : "semibold"),
                            }}>{props.text}</div>

                        <div className = {styles.circular_progress_div}>
                            <CircularProgress 
                                size = {20}
                                style = {{
                                    display: (props.text !== null ? "" : "none"),
                                    color: (props.text_color !== null ? props.text_color : "white"),
                                    opacity: (props.is_loading ? "1.0" : "0.0")
                                }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Button;