import styled from "@emotion/styled";

const LeftSideContainer = styled.div`
  margin-left: 0;
  margin-right: auto;
  display: flex;
  text-decoration: none;
  align-items: center;
  vertical-align: middle;
  width: fit-content;
  font-size: 3vh;
  font-weight: 800;
  font: "Sans Serif";
  //border: 1px solid yellow;
`;

export default LeftSideContainer;
