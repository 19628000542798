import styled from "@emotion/styled";

const HomeGuestDivContainerBackgroundImage = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    position: absolute;
    top: 0;
    z-index: -90;
    background-image: linear-gradient(to bottom, rgba(255,0,0,0), var(--primary_colour));
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
`;

export default HomeGuestDivContainerBackgroundImage;
