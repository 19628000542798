export const are_the_two_lists_equal = function (list_1, list_2) {
    
    if(list_1 === null && list_2 === null) return(true);
    if((list_1.length === 0 && list_2.length !== 0) || (list_2.length === 0 && list_1.length !== 0) || (list_1 === null && list_2.length === 0) || (list_2.length === 0 && list_1 === null) || (list_1 === null && list_2 !== null) || (list_2 === null && list_1 !== null) || list_1.length !== list_2.length) return(false);
    
    var are_the_same = true;
    for(let i = 0; i < list_1.length; i++){
        if(i < list_2.length){
            if((list_2[i].unique_identifier !== list_1[i].unique_identifier) || (list_2[i].name !== list_1[i].name) || (list_2[i].url !== list_1[i].url) || (list_2[i].protocol !== list_1[i].protocol)){
                are_the_same = false;
                break;
            }
        }
    }

    return(are_the_same);
}

export const sortByDateAndHour = (obj) => {
    const keys = Object.keys(obj);

    keys.sort((a, b) => {
        const [dateA, hourA] = a.split('_').slice(1);
        const [dateB, hourB] = b.split('_').slice(1);

        if (dateA !== dateB) {
            return dateA.localeCompare(dateB);
        }

        return parseInt(hourA, 10) - parseInt(hourB, 10);
    });

    const sortedObject = {};
    keys.forEach(key => {
        sortedObject[key] = obj[key];
    });

    return(sortedObject);
}