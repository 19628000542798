import { colors } from "@mui/material";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loggedIn: !!localStorage.getItem("bearerToken"),
  user: {
    accessToken: localStorage.getItem("bearerToken"),
    tokenType: localStorage.getItem("tokenType"),
    username: "",
  },
};


// Remove Cookies - Another Function
const removeAllCookies = () => {
  document.cookie.split(";").forEach((c) => {
    document.cookie = c.trim().split("=")[0] + '=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/';
  });

  console.log("Cookies Removed!")
};

// Delete Local Storage
const clearUserLocalStorage = () => {
  const keysToRemove = [
    "bearerToken",
    "tokenType",
    "current_user",
    "visible_sources",
    "saved_span_sources",
  ];

  keysToRemove.forEach((key) => {
    localStorage.removeItem(key);
  });

  //window.dispatchEvent(new Event("forceReloadWindow"));
  if (!sessionStorage.getItem("reloadFlag")) {
    sessionStorage.setItem("reloadFlag", "true");
    window.location.reload();
  } else {
    sessionStorage.removeItem("reloadFlag");
  }
};


const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logInSuccess: {
      reducer: (state, { payload: { accessToken, tokenType } }) => {
        state.loggedIn = true;
        state.user.accessToken = accessToken;
        state.user.tokenType = tokenType;
        localStorage.setItem("bearerToken", accessToken);
        localStorage.setItem("tokenType", tokenType);
      },
    },
    logOutSuccess: {
      reducer: (state, action) => {
        state.loggedIn = false;
        //state.user.accessToken = null;
        //state.user.tokenType = null;
        //localStorage.removeItem("bearerToken");
        //localStorage.removeItem("tokenType");
        clearUserLocalStorage();
      },
    },
  },
});

export const { logInSuccess, logOutSuccess } = userSlice.actions;
export default userSlice.reducer;
