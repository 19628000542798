import React, { Suspense, useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// Style
import "normalize.css";
import { Global } from "@emotion/react";

// Redux
import { store } from "./store/store";
import { Provider } from "react-redux";

// Components
import Home from "./components/home/Home";
import Header from "./components/header/Header";
import AuthValidator from "./components/auth/AuthValidator";
import MultiSourceViewMain from "./components/sources/visualization/MultiSourceViewMain";
import RequireAuth from "./components/auth/RequireAuth";

import styles from './styles/app.module.scss';

function App() {
  
  const VERSION = "2.3.0"
  const [theme, set_theme] = useState(localStorage.getItem("theme"));

  /*
    ----------------------------------------------
    EVENTS USED IN THIS APPLICATION
    ----------------------------------------------
    theme_change
    language_change
    sources_change
    source_grid_span_change
    clicked_source_change
    info_or_fullscreen_div_status_change
    draggable_should_be_disabled_change
    history_period_change
    clip_length_change
    ai_analysis_change
    new_source_change
    grid_layout_change
    navbar_behaviour_change
    current_source_frame_dimensions_change
    anomaly_detected_change
    header_menus_visibility_change
    add_source_menu_visibility_change
    logged_in_change
    ai_sources_change
    info_or_fullscreen_div_status_should_disappear
    add_source_menu_click
    add_source_menu_should_disappear
    edit_source_menu_should_disappear
    all_menus_should_disappear
    statistics_component_active_tab_change
    edit_source_menu_should_appear
  */

  // add event listeners
  useEffect(() => {

    // tell every component to start with the right theme and language
    window.dispatchEvent(new Event("theme_change"));
    window.dispatchEvent(new Event("language_change"));

  }, []);
  
  // set some default values on the browser's storage
  if(localStorage.getItem("language") === null)
      localStorage.setItem("language", "English"); 

  if(localStorage.getItem("theme") === null)
      localStorage.setItem("theme", "Light"); 

  if(localStorage.getItem("history_period") === null)
    localStorage.setItem("history_period", "1 hour"); 

  localStorage.setItem('version', VERSION); 
  localStorage.setItem('aspect_ratio', 16 / 9);
  localStorage.setItem("header_menus_visibility", "hidden");
  localStorage.setItem("should_be_disabled", false);
  localStorage.setItem("info_div_status", "hidden");
  localStorage.setItem("fullscreen_div_status", "hidden");
  localStorage.setItem("anomalies_detected", JSON.stringify([]));
  localStorage.setItem("draggable_should_be_disabled", false);
  localStorage.setItem("current_source_frame_width", "0");
  localStorage.setItem("current_source_frame_height", "0");
  localStorage.setItem("clicked_source", "-1");
  localStorage.setItem("alert_colour", "none");
  localStorage.setItem("info_or_fullscreen_div_current_source", "none");
  localStorage.setItem("new_source", "Visible");
  localStorage.setItem("alert_colour", null);
  
  // save the "desktop_version_min_width" variable to the local storage (when other components first render, they can't access this variable properly)
  useEffect(() => {
    const desktop_version_min_width = getComputedStyle(document.documentElement).getPropertyValue('--desktop_version_min_width');
    
    if(isNaN(desktop_version_min_width))
      localStorage.setItem("desktop_version_min_width", desktop_version_min_width.replace("px", ""));
  })

  if(localStorage.getItem("hidden_sources") === null)
    localStorage.setItem("hidden_sources", JSON.stringify([]));

  // add a way for "SocketIOComponent" to send thumbnails to "SourcesMenu"
  const [sources_image_data, set_sources_image_data] = useState({});
  function change_sources_image_data(source_unique_identifier, new_image_data) { set_sources_image_data(previous_sources_image_data => ({...previous_sources_image_data, [source_unique_identifier]: new_image_data})); }
  
  return (
    <div className = {styles.app}>
      <Provider store = {store}>
        <BrowserRouter>

          <Global styles = {styles}/>
        
          <AuthValidator />

          <Suspense>
            <Routes>
              <Route
                exact path = "/dashboard/*"
                element = {
                  <RequireAuth>

                    <div 
                        className = {styles.backgroundPattern}
                        style = {{
                            backgroundImage: "url(\"/media/images/blackDots.svg\")",
                            opacity: (theme === "Light" ? "0.4" : "0.5"),
                        }} />
                    
                    <Header 
                      sources_image_data = {sources_image_data}
                      change_sources_image_data = {change_sources_image_data}/>
                    
                    <MultiSourceViewMain 
                      sources_image_data = {sources_image_data}
                      change_sources_image_data = {change_sources_image_data}/>
                  
                  </RequireAuth>
                }
              />
              <Route 
                exact path = "/" 
                element = { <Home /> } />
            </Routes>
          </Suspense>

        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
