
import React from 'react';
import { useEffect, useState } from "react";
import Button from "../reusable_components/Button.js";
import styles from '../../styles/language_and_theme_selector.module.scss';
import json_language_and_theme_selector_en from '../../jsons/en/language_and_theme_selector_en.json';
import json_language_and_theme_selector_pt from '../../jsons/pt/language_and_theme_selector_pt.json';

const LanguageAndThemeSelector = () => {

    // variables and states
    const [language, set_language] = useState(localStorage.getItem("language"));
    const json_data = (language === "English" ? json_language_and_theme_selector_en : json_language_and_theme_selector_pt);
    const [theme, set_theme] = useState(localStorage.getItem("theme"));
    const [window_width, set_window_width] = useState(window.innerWidth);
    const [is_mobile, set_is_mobile] = useState(window.innerWidth < parseInt(localStorage.getItem("desktop_version_min_width")));
    const [is_settings_icon_hovered, set_is_settings_icon_hovered] = useState(false);
    const [is_line_div_language_hovered, set_is_line_div_language_hovered] = useState(false);
    const [is_line_div_theme_hovered, set_is_line_div_theme_hovered] = useState(false);
    const [animate_language, set_animate_language] = useState(0);
    const [animate_theme, set_animate_theme] = useState(0);

    const on_click = (what_to_change) => {

        if(what_to_change === "language"){
            set_animate_language(1);
            
            const new_language = language === "English" ? "Portuguese" : "English"
            set_language(new_language)
            localStorage.setItem("language", new_language);
            window.dispatchEvent(new Event("language_change"));
        }

        else if(what_to_change === "theme"){
            set_animate_theme(1);

            const new_theme = theme === "Light" ? "Dark" : "Light"
            set_theme(new_theme)
            localStorage.setItem("theme", new_theme);
            window.dispatchEvent(new Event("theme_change"));
        }
    }

    const on_click_settings_button = () => {

        if(is_mobile){
            if(is_settings_icon_hovered)
                set_is_settings_icon_hovered(false);

            else
                set_is_settings_icon_hovered(true);
        }
    }

    // add event listeners
    useEffect(() => {

        // listen for theme changes
        function theme_change() {
            set_theme(localStorage.getItem("theme"));
        }
        window.addEventListener('theme_change', theme_change);

        // listen for language changes
        function language_change() {
            set_language(localStorage.getItem("language"));
        }
        window.addEventListener('language_change', language_change);

        // listen for window resizes
        function resize() { 
            set_window_width(window.innerWidth); 
        }
        window.addEventListener('resize', resize);

        // do some cleanup (i.e., remove the event listeners if this component ends up being unmounted)
        return(() => {
            window.removeEventListener('theme_change', theme_change);
            window.removeEventListener('language_change', language_change);
            window.removeEventListener('resize', resize);
        })

    }, []);

    useEffect(() => {

        set_is_mobile(window_width < parseInt(localStorage.getItem("desktop_version_min_width")));
    
    }, [window_width]);

    return (
        <div 
            className = {styles.root}
            style = {{
                height: (is_mobile ? "150px" : "140px"),
                width: (is_mobile ? "180px" : "175px"),
            }}>

            <div 
                className = {styles.menu_background}
                onClick = {() => set_is_settings_icon_hovered(false)}
                style = {{
                    opacity: (is_settings_icon_hovered ? "1.0" : "0.0"),
                    visibility: (is_settings_icon_hovered ? "visible" : "hidden")
                }}/>

            <div 
                onClick = {() => on_click_settings_button()}
                onMouseEnter = {is_mobile ? null : () => set_is_settings_icon_hovered(true)}
                onMouseLeave = {is_mobile ? null : () => set_is_settings_icon_hovered(false)}
                className = {styles.settings_icon_div}
                style = {{
                    backgroundColor: (is_settings_icon_hovered ? "rgba(134, 134, 134, 0.55)" : (theme === "Light" ? "var(--colour_one)": "var(--colour_one_dark_mode)")),
                }}>
                <div className = {styles.vertical_align}>
                    <div 
                        className = {styles.settings_icon} 
                        style = {{
                            width: (is_settings_icon_hovered && is_mobile ? "37%" : "45%"),
                            height: (is_settings_icon_hovered && is_mobile ? "37%" : "45%"),
                            backgroundImage: (is_settings_icon_hovered && is_mobile ? (theme === "Light" ? json_data.close_icon_light_mode : json_data.close_icon_dark_mode) : (theme === "Light" ? json_data.settings_icon_light_mode : json_data.settings_icon_dark_mode)),
                        }}/>
                </div>
            </div>

            <div
                onMouseEnter = {is_mobile ? null : () => set_is_settings_icon_hovered(true)}
                onMouseLeave = {is_mobile ? null : () => set_is_settings_icon_hovered(false)}
                className = {styles.settings_popup_menu}
                style = {{
                    visibility: (is_settings_icon_hovered ? "visible" : "hidden"),
                    opacity: (is_settings_icon_hovered ? "1.0" : "0.0"),
                    backgroundColor: (theme === "Light" ? "rgba(255, 255, 255, 0.85)" : "var(--colour_one_dark_mode)"),
                    border: (theme === "Light" ? "0.020in solid var(--colour_two)" : "0.020in solid rgba(80, 80, 80, 0.55)"),
                    boxShadow: (theme === "Light" ? "rgba(100, 100, 100, 0.35) 0px 0px 10px" : "rgba(10, 15, 21, 0.35) 0px 8px 24px"),
                }}>
                    
                    <div 
                        onMouseEnter = {() => set_is_line_div_language_hovered(true)}
                        onMouseLeave = {() => set_is_line_div_language_hovered(false)}
                        onClick = {() => on_click("language")}
                        className = {styles.line_div}
                        onAnimationEnd = {() => set_animate_language(0)}
                        animate_language = {animate_language}
                        style = {{
                            backgroundColor: (is_line_div_language_hovered && !is_mobile ? (theme === "Light" ? "rgba(150, 150, 150, 0.08)" : "rgba(200, 200, 200, 0.1)") : ""),
                            border: (is_line_div_language_hovered && !is_mobile ? (theme === "Light" ? "1px solid rgba(75, 75, 75, 0.1)" : "1px solid rgba(255, 255, 255, 0.07)") : "1px solid rgba(0, 0, 0, 0.0)"),
                        }}>
                        <div 
                            className = {styles.line_text_div}
                            style = {{
                                color: (theme === "Light" ? "var(--colour_one)" : "rgba(235, 235, 235, 1.0)")
                            }}>
                            {json_data.language_text}
                        </div>

                        <div className = {styles.line_icon_div}>
                            <div 
                                className = {styles.line_icon}
                                style = {{
                                    backgroundImage: (language === "English" ? (theme === "Light" ? json_data.language_icon_english_light_mode : json_data.language_icon_english_dark_mode) : (theme === "Light" ? json_data.language_icon_portuguese_light_mode : json_data.language_icon_portuguese_dark_mode)),
                                }}/>
                        </div>
                    </div>

                    <div className = {styles.line_separator}/>

                    <div 
                        onMouseEnter = {() => set_is_line_div_theme_hovered(true)}
                        onMouseLeave = {() => set_is_line_div_theme_hovered(false)}
                        onClick = {() => on_click("theme")}
                        className = {styles.line_div}
                        onAnimationEnd = {() => set_animate_theme(0)}
                        animate_theme = {animate_theme}
                        style = {{
                            backgroundColor: (is_line_div_theme_hovered && !is_mobile ? (theme === "Light" ? "rgba(150, 150, 150, 0.08)" : "rgba(200, 200, 200, 0.1)") : ""),
                            border: (is_line_div_theme_hovered && !is_mobile ? (theme === "Light" ? "1px solid rgba(75, 75, 75, 0.1)" : "1px solid rgba(255, 255, 255, 0.07)") : "1px solid rgba(0, 0, 0, 0.0)"),
                        }}>
                        <div 
                            className = {styles.line_text_div}
                            style = {{
                                color: (theme === "Light" ? "var(--colour_one)" : "rgba(235, 235, 235, 1.0)")
                            }}>
                            {json_data.theme_text}
                        </div>

                        <div className = {styles.line_icon_div}>
                                <div 
                                    className = {styles.line_icon}
                                    style = {{
                                        backgroundImage: (theme === "Light" ? json_data.theme_icon_light_mode : json_data.theme_icon_dark_mode),
                                    }}/>
                            </div>
                    </div>
            </div>

        </div>
    );
}

export default LanguageAndThemeSelector;