import Papa from "papaparse";
import DebugVisuals from "./DebugVisuals";
import useMeasure from "react-use-measure";
import React, { useState, useEffect } from "react";
import listOfColours from "./listOfColours.json";
import styles from "./visualisationManager.module.scss";
import BoundingBoxAndCaptions from "./BoundingBoxAndCaptions";
import DetectionsStatisticsMenu from "./DetectionsStatisticsMenu";

const VisualisationManager = ( { currentSource, mlAnalysis, frameWidth, frameHeight, scale, configFile } ) => {

    const [previousMlAnalysis, setPreviousMlAnalysis] = useState({});
    const [rootDivRef, rootDivDimensions] = useMeasure();
    const [classesDangerLevels, setClassesDangerLevels] = useState();
    
    // main variables
    const [bBoxesAndCaptionsFullDetails, setBBoxesAndCaptionsFullDetails] = useState([]);
    const [debugVisuals, setDebugVisuals] = useState({
        "polygons": [],
        "lines": [],
        "masks": []
    });
    const statusBarClassNames = [
        "low_speed", "stopped", "wrong_way", "out_of_bounds", "employee", "slow"
    ]

    useEffect(() => {

        // retrieve the .csv file
        Papa.parse("/media/files/colours_per_class_name.csv", {
            delimiter: ",",
            download: true,
            complete: function (results) {

                let resultsAux = results.data;
                let classesDangerLevelsAux = {};

                for (let i = 0; i < resultsAux.length; i++) {

                    let dangerLevel = "";

                    if(resultsAux[i][1] === "(255 117 26)")
                        dangerLevel = "PROBLEMATIC";
                    
                    else if(resultsAux[i][1] === "(230 0 0)")
                        dangerLevel = "DANGEROUS";

                    else
                        dangerLevel = "NORMAL";
                    
                    // use a generic colour for delimited areas, for now
                    if(resultsAux[i][1] === "PER_DELIMITED_AREA")
                        classesDangerLevelsAux[resultsAux[i][0]] = ["DELIMITED_AREA", "rgba(100, 100, 100, 1.0)"];

                    else
                        classesDangerLevelsAux[resultsAux[i][0]] = [dangerLevel, "rgba(" + resultsAux[i][1].replaceAll(" ", ", ").replace("(", "").replace(")", "") + ", 1.0)"];
                }

                setClassesDangerLevels(classesDangerLevelsAux);
            },
        });

    }, [currentSource]);

    useEffect(() => {

        if(JSON.stringify(configFile) !== "{}" && JSON.stringify(mlAnalysis) !== JSON.stringify(previousMlAnalysis) && rootDivDimensions["height"] !== 0 && frameWidth !== 0){

            // -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
            // take care of the detections
            // -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
            setPreviousMlAnalysis(mlAnalysis);

            let bBoxesAndCaptionsFullDetailsAux = {}
            
            // normalise the bounding box coordinates and associate captions to bounding boxes
            Object.entries(mlAnalysis["predictions"]).forEach(([caption, bBox]) => {

                // put the bounding box in the correct scale and stringify it
                let normalisedBBox = bBox.map((item, index) => (index % 2 === 0 ? ((item / frameWidth) * rootDivDimensions["width"]) : ((item / frameHeight) * rootDivDimensions["height"])));
                let stringifiedBBox = JSON.stringify(normalisedBBox);

                if(!(stringifiedBBox in bBoxesAndCaptionsFullDetailsAux))
                    bBoxesAndCaptionsFullDetailsAux[stringifiedBBox] = {
                        "captions": [caption],
                        "strokeColour": ""
                    };
                
                else
                    bBoxesAndCaptionsFullDetailsAux[stringifiedBBox]["captions"] = bBoxesAndCaptionsFullDetailsAux[stringifiedBBox]["captions"].concat([caption]);
            });

            let bBoxesAndCaptionsFullDetailsAuxList = [];

            for (var key in bBoxesAndCaptionsFullDetailsAux) {
                if (bBoxesAndCaptionsFullDetailsAux.hasOwnProperty(key)) {
                    bBoxesAndCaptionsFullDetailsAuxList.push([key, bBoxesAndCaptionsFullDetailsAux[key]]);
                }
            }
            
            // sort the captions by severity
            for(let i = 0; i < bBoxesAndCaptionsFullDetailsAuxList.length; i++){

                let sortedCaptionsAux = [];
                let captionsS = [];
                let captionsA = [];
                let captionsD = [];
                let captionsP = [];
                let captionsN = [];

                for(let j = 0; j < bBoxesAndCaptionsFullDetailsAuxList[i][1]["captions"].length; j++){

                    let captionId = bBoxesAndCaptionsFullDetailsAuxList[i][1]["captions"][j].split("_").slice(-2)[0];
                    let ogClassName = (bBoxesAndCaptionsFullDetailsAuxList[i][1]["captions"][j].split("_").slice(0, -2)).join("_").split(" • ")[0];
                    let captionClassName = ogClassName.split("-")[0];
                    let dangerLevel = ""

                    // a special, status bar class
                    if(statusBarClassNames.includes(captionClassName)){
                        captionsS.push([bBoxesAndCaptionsFullDetailsAuxList[i][1]["captions"][j], classesDangerLevels[captionClassName][1]]);
                        dangerLevel = "S";
                    }

                    else if(classesDangerLevels[captionClassName][0] === "DELIMITED_AREA"){

                        let formattedColour = "";
                        for(let d = 0; d < mlAnalysis["debug_visuals"]["polygons"].length; d++){
                            if(mlAnalysis["debug_visuals"]["polygons"][d]["DESCRIPTION"].split(" • ")[0].split("-")[1] === ogClassName.split(" • ")[0].split("-")[1]){
                                formattedColour = "rgba(" + mlAnalysis["debug_visuals"]["polygons"][d]["COLOUR"].slice(0, -1).join(", ") + ", 1.0)";
                                break;
                            }
                        }

                        if(formattedColour !== "")
                            captionsA.push([bBoxesAndCaptionsFullDetailsAuxList[i][1]["captions"][j], formattedColour]);
                        else
                            captionsA.push([bBoxesAndCaptionsFullDetailsAuxList[i][1]["captions"][j], classesDangerLevels[captionClassName][1]]);

                        dangerLevel = "A";
                    }

                    else if(classesDangerLevels[captionClassName][0] === "DANGEROUS"){
                        captionsD.push([bBoxesAndCaptionsFullDetailsAuxList[i][1]["captions"][j], classesDangerLevels[captionClassName][1]]);
                        dangerLevel = "D";
                    }

                    else if(classesDangerLevels[captionClassName][0] === "PROBLEMATIC"){
                        captionsP.push([bBoxesAndCaptionsFullDetailsAuxList[i][1]["captions"][j], classesDangerLevels[captionClassName][1]]);
                        dangerLevel = "P";
                    }

                    else{
                        captionsN.push([bBoxesAndCaptionsFullDetailsAuxList[i][1]["captions"][j], classesDangerLevels[captionClassName][1]]);
                        dangerLevel = "N";
                    }
                }

                sortedCaptionsAux = sortedCaptionsAux.concat(captionsS).concat(captionsA).concat(captionsD).concat(captionsP).concat(captionsN);
                
                // determine the bounding box's stroke colour
                if(configFile["VISUALISATION_DETAILS"]["DEBUG_VISUALS"]["USE_UNIQUE_COLOURS_PER_ID"])
                    bBoxesAndCaptionsFullDetailsAuxList[i][1]["strokeColour"] = "rgba(" + listOfColours.colours[parseInt(sortedCaptionsAux[0][0].split("_").slice(-2)[0])].join(", ") + ", 1.0)";
                
                else{
                    let ogClassName = (sortedCaptionsAux[0][0].split("_").slice(0, -2)).join("_").split(" • ")[0];
                    let captionClassName = ogClassName.split("-")[0];
                    
                    let type = classesDangerLevels[captionClassName][0]

                    if(type === "DELIMITED_AREA"){
                        for(let d = 0; d < mlAnalysis["debug_visuals"]["polygons"].length; d++){
                            if(mlAnalysis["debug_visuals"]["polygons"][d]["DESCRIPTION"].split(" • ")[0].split("-")[1] === ogClassName.split(" • ")[0].split("-")[1]){
                                let formattedColour = "rgba(" + mlAnalysis["debug_visuals"]["polygons"][d]["COLOUR"].slice(0, -1).join(", ") + ", 1.0)";
                                bBoxesAndCaptionsFullDetailsAuxList[i][1]["strokeColour"] = formattedColour;
                                break;
                            }
                        }
                    }

                    else{
                        bBoxesAndCaptionsFullDetailsAuxList[i][1]["strokeColour"] = classesDangerLevels[captionClassName][1];
                    }
                }

                bBoxesAndCaptionsFullDetailsAuxList[i][1]["captions"] = [...sortedCaptionsAux];
            }

            // ---------------------------------------------------------------------------------
            // obtain extra details
            // ---------------------------------------------------------------------------------
            for(let j = 0; j < bBoxesAndCaptionsFullDetailsAuxList.length; j++){

                for(let c = 0; c < bBoxesAndCaptionsFullDetailsAuxList[j][1]["captions"].length; c++){

                    let captionClassName = bBoxesAndCaptionsFullDetailsAuxList[j][1]["captions"][c][0].split("_").slice(0, -2).join("_");

                    // check if this caption should be excluded from having extra details
                    if(statusBarClassNames.includes(captionClassName))
                        continue

                    bBoxesAndCaptionsFullDetailsAuxList[j][1]["captions"][c].push([])

                    // if required, add the confidence score
                    if(configFile["VISUALISATION_DETAILS"]["DEBUG_VISUALS"]["SHOW_CONFIDENCE_SCORES"])
                        
                        bBoxesAndCaptionsFullDetailsAuxList[j][1]["captions"][c][2].push(
                            ["confidence_score", bBoxesAndCaptionsFullDetailsAuxList[j][1]["captions"][c][0].split("_").pop()]
                        )

                    let captionId = bBoxesAndCaptionsFullDetailsAuxList[j][1]["captions"][c][0].split("_")[bBoxesAndCaptionsFullDetailsAuxList[j][1]["captions"][c][0].split("_").length - 2];

                    // use case details and extra bits of information
                    if(configFile["VISUALISATION_DETAILS"]["DEBUG_VISUALS"]["SHOW_CAPTIONS_EXTRAS"]){


                        let available_use_cases = [
                            "age_gender_recognition", "license_plate_detection", "vehicle_analysis", "personal_protection_equipment"
                        ]
                        
                        for(let i = 0; i < available_use_cases.length; i++){

                            if(available_use_cases[i] in mlAnalysis["use_case_controllers"]){

                                if(captionId in mlAnalysis["use_case_controllers"][available_use_cases[i]]){

                                    let value = mlAnalysis["use_case_controllers"][available_use_cases[i]][captionId];

                                    bBoxesAndCaptionsFullDetailsAuxList[j][1]["captions"][c][2].push(
                                        [available_use_cases[i], value]
                                    )
                                }
                            }
                        }
                    }

                    // if required, add the speed information
                    if(configFile["VISUALISATION_DETAILS"]["DEBUG_VISUALS"]["SHOW_SPEEDS"]){

                        if(!("traffic_control" in mlAnalysis["use_case_controllers"]))
                            continue

                        let speed_suffix = ""
                        
                        if(currentSource in  mlAnalysis["use_case_controllers"]["traffic_control"] && captionId in mlAnalysis["use_case_controllers"]["traffic_control"][currentSource]["vehicles"]){
                            let speed = mlAnalysis["use_case_controllers"]["traffic_control"][currentSource]["vehicles"][captionId]["speed"];

                            if(speed === "null" || speed === null || speed === "None")
                                continue

                            bBoxesAndCaptionsFullDetailsAuxList[j][1]["captions"][c][2].push(
                                ["speed", speed + speed_suffix]
                            )
                        }
                    }

                }
            }

            setBBoxesAndCaptionsFullDetails(bBoxesAndCaptionsFullDetailsAuxList);

            // ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
            // take care of the debug visuals
            // ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
            let debugVisualsAux = structuredClone(mlAnalysis["debug_visuals"]);
            
            // scale the debug shapes accordingly
            Object.entries(debugVisualsAux).forEach(([visualType, visualsList]) => {
                for(let i = 0; i < visualsList.length; i++){
                    debugVisualsAux[visualType][i]["POINTS"] = debugVisualsAux[visualType][i]["POINTS"].map((item) => [(item[0] / frameWidth) * rootDivDimensions["width"], (item[1] / frameHeight) * rootDivDimensions["height"]]);
                }
            });

            setDebugVisuals(debugVisualsAux);
        }

    }, [currentSource, mlAnalysis, previousMlAnalysis, frameWidth, frameHeight, rootDivDimensions, classesDangerLevels, configFile, statusBarClassNames]);

    useEffect(() => {
        setPreviousMlAnalysis({});
    }, [rootDivDimensions]);

    return(
        <div 
            ref = {rootDivRef}
            className = {styles.rootDiv}>
            
            <div className = {styles.bBoxesAndCaptionsDiv}>
                {
                    bBoxesAndCaptionsFullDetails.map((item, index) => (
                        <BoundingBoxAndCaptions 
                            key = {index}
                            bBoxCoords = {JSON.parse(item[0])}
                            captions = {item[1]["captions"]}
                            strokeColour = {item[1]["strokeColour"]}
                            parentDivDimensions = {rootDivDimensions}
                            scale = {scale}
                            configFile = {configFile} />
                    ))
                }
            </div>
            
            {JSON.stringify(configFile) !== "{}" && configFile["VISUALISATION_DETAILS"]["STATISTICS_MENU"]["USE"] ?
                <DetectionsStatisticsMenu 
                    mlAnalysis = {mlAnalysis}
                    parentDivDimensions = {rootDivDimensions}
                    configFile = {configFile} /> : 
            <></>}

            <DebugVisuals 
                debugVisuals = {debugVisuals}
                parentDivDimensions = {rootDivDimensions}
                configFile = {configFile} />

        </div>
    );
}

export default VisualisationManager;