import styled from "@emotion/styled";

const CanvasContainer = styled.canvas`
  position: relative;
  text-align: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  border-radius: 8px;
  border: px solid black;
`;

export default CanvasContainer;
