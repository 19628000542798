import colorString from "color-string";
import useMeasure from "react-use-measure";
import React, { useState, useEffect } from "react";
import styles from "./boundingBoxAndCaptions.module.scss";
import knownColours from "../../../../../utils/knownColours.json";

const BoundingBoxAndCaptions = ( { bBoxCoords, captions, strokeColour, parentDivDimensions, configFile } ) => {

    const [captionsDivRef, captionsDivDimensions] = useMeasure();
    const [fontSize, setFontSize] = useState(13);
    const [optimalPadding, setOptimalPadding] = useState(5)
    const statusBarClassNames = [
        "low_speed", "stopped", "wrong_way", "out_of_bounds", "employee", "slow"
    ]

    useEffect(() => {

        // -----------------------------------------
        // determine the optimal font size
        // -----------------------------------------
        if(parentDivDimensions["width"] > 700){
            setFontSize(11);
            setOptimalPadding(5);
        }

        else if(parentDivDimensions["width"] > 400){
            setFontSize(9);
            setOptimalPadding(4);
        }

        else{
            setFontSize(7);
            setOptimalPadding(3);
        }

    }, [parentDivDimensions]);

    const determineOptimalTextColour = (backgroundColour) => {

        let parsedBackgroundColor = backgroundColour.replace("rgba(", "").replaceAll("(", "").replaceAll(")", "").split(", ")
        let optimalColour = ""

        if(((parseInt(parsedBackgroundColor[0]) * 0.299) + (parseInt(parsedBackgroundColor[1]) * 0.587) + (parseInt(parsedBackgroundColor[2]) * 0.114)) <= 110)
            optimalColour = "rgba(255, 255, 255, 255)"
        else
            optimalColour = "rgba(0, 0, 0, 255)"

        return(optimalColour)
    }

    const checkForClassNameMappings = (ogClassName) => {

        /*if(ogClassName === "low speed")
            return("slow");*/

        return(ogClassName);
    }

    function getColourFromString (colourString) {

        let rgbaValue = colorString.get.rgb(colourString);

        return("rgba(" + rgbaValue.join(", ") + ")");
    }

    return(
        <div className = {styles.rootDiv}>
            
            <div 
                className = {styles.bBoxDiv}
                style = {{
                    left: bBoxCoords[0] + "px",
                    top: bBoxCoords[1] + "px",
                    width: bBoxCoords[2] + "px",
                    height: bBoxCoords[3] + "px",
                    border: (fontSize > 10 ? "3px" : "2px") + " solid " + strokeColour
                }} />

            <div 
                className = {styles.captionsDiv}
                style = {{
                    left: (bBoxCoords[0] + captionsDivDimensions["width"]) > parentDivDimensions["width"] ? (bBoxCoords[0] - ((bBoxCoords[0] + captionsDivDimensions["width"]) - parentDivDimensions["width"])) + "px" : bBoxCoords[0] + "px",
                    top: Math.max(0, bBoxCoords[1] - captionsDivDimensions["height"]) + "px",
                }}>
                    <div 
                        ref = {captionsDivRef}
                        className = {styles.captionsInnerDiv}>
                        {
                            captions.map((item, index) => (
                                <div
                                    key = {index} 
                                    className = {styles.captionOuterDiv}>
                                        <div
                                            key = {index} 
                                            className = {styles.captionInnerDiv}
                                            style = {{
                                                backgroundColor: item[1],
                                                color: determineOptimalTextColour(item[1]),
                                                padding: (optimalPadding),
                                                fontSize: (fontSize + "px"),
                                            }}>

                                                <div 
                                                    className = {styles.captionID}
                                                    style = {{
                                                        display: (configFile["VISUALISATION_DETAILS"]["DEBUG_VISUALS"]["SHOW_IDS"] && !statusBarClassNames.includes((item[0].split("_").slice(0, -2)).join("_")) ? "" : "none"),
                                                    }}>
                                                    {item[0].split("_").slice(-2)[0]}
                                                </div>

                                                <div className = {styles.captionName}>
                                                    {checkForClassNameMappings((item[0].split("_").slice(0, -2)).join(" "))}
                                                </div>

                                                {/* if possible, add extra information  */}
                                                {
                                                    item.length === 3 ?
                                                    <div className = {styles.captionExtrasDiv}>
                                                        {
                                                            item[2].map((extras_item, extras_index) => (
                                                                <div 
                                                                    key = {extras_index}
                                                                    className = {styles.captionExtra}
                                                                    style = {{
                                                                        backgroundColor: (knownColours["values"].includes(extras_item[1]) ? getColourFromString(extras_item[1]) : ""),
                                                                        color: (knownColours["values"].includes(extras_item[1]) ? determineOptimalTextColour(getColourFromString(extras_item[1])) : determineOptimalTextColour(item[1])),
                                                                        display: (configFile["VISUALISATION_DETAILS"]["DEBUG_VISUALS"]["SHOW_CAPTIONS_EXTRAS"] ? "" : "none"),
                                                                    }}>
                                                                    {extras_item[1]}
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                    :
                                                    <></>
                                                }
                                        </div>

                                </div>
                            ))
                        }
                    </div>
            </div>

        </div>
    );
}

export default BoundingBoxAndCaptions;