import { useDispatch } from "react-redux";
import { SocketIOComponent } from "./SocketIOComponent";
import React, { useState, useEffect, forwardRef } from "react";

export const SourceViewMain = forwardRef(
  ({ item, index, faded, style, ...props }, ref) => {

    // variables and states
    const { drag_and_drop_ref, info_div_ref } = ref;

    const dispatch = useDispatch();
    const [currentSource, setCurrentSource] = useState(item["unique_identifier"]);
    const [clicked_source, set_clicked_source] = useState(parseInt(localStorage.getItem("clicked_source")));
    const current_user = localStorage.getItem("current_user");
    const saved_span_sources = JSON.parse(localStorage.getItem("saved_span_sources"));
    if(saved_span_sources === null) window.dispatchEvent(new Event("grid_layout_change"));
    const [grid_span, set_grid_span] = useState(saved_span_sources === null ? "1" : saved_span_sources[current_user + "_" + currentSource]);

    if(grid_span === undefined || grid_span === null){
      saved_span_sources[current_user + "_" + currentSource] = "1";

      localStorage.setItem("saved_span_sources", JSON.stringify(saved_span_sources));
      window.dispatchEvent(new Event("source_grid_span_change"));
      set_grid_span("1");
    }

    // add event listeners
    useEffect(() => {

      // listen for source grid span changes
      function source_grid_span_change() {

        let saved_span_sources = JSON.parse(localStorage.getItem("saved_span_sources"));
        set_grid_span(saved_span_sources[current_user + "_" + currentSource]);
      }
      window.addEventListener('source_grid_span_change', source_grid_span_change);

      // know which source is being dragged
      function clicked_source_change() {
        set_clicked_source(parseInt(localStorage.getItem("clicked_source")));
      }
      window.addEventListener('clicked_source_change', clicked_source_change);

      // do some cleanup (i.e., remove the event listeners if this component ends up being unmounted)
      return(() => {
        window.removeEventListener('source_grid_span_change', source_grid_span_change);
        window.removeEventListener('clicked_source_change', clicked_source_change);
      })

    }, []);

    return (
      <div 
        ref = {drag_and_drop_ref} 
        style = {{
          transformOrigin: "0 0",
          borderRadius: "8px",
          cursor: "grab",
          flex: "1",
          flexBasis: "10%",
          aspectRatio: parseInt(localStorage.getItem("aspect_ratio")),
          width: "100%",
          //maxHeight: (grid_span === "1" ? "215px" : "350px"),
          gridRowStart: "span " + grid_span,
          gridColumnStart: "span " + grid_span,
          display: "flex",
          alignItems: "stretch",
          overflow: "visible",
          //border: "1px solid magenta",
          ...style
        }} 
        {...props}>
        <div 
          style = {{
            opacity: (clicked_source === -1 || clicked_source === index ? "1.0" : "0.75"),
            verticalAlign: "middle",
            flex: "1",
            margin: "0 auto",
            height: "100%",
            width: "100%",
            position: "relative",
            borderRadius: "8px",
            transition: "all 0.25s ease-in-out",
          }}>
            <SocketIOComponent
              enter_wiggle_mode = {props.enter_wiggle_mode}
              is_being_dragged_prop = {props.is_being_dragged}
              sources_image_data = {props.sources_image_data}
              change_sources_image_data = {props.change_sources_image_data}
              grid_span = {grid_span}
              position = {index}
              source_name = {props.visible_sources[index]["name"]}
              currentSource = {currentSource}
              setCurrentSource = {setCurrentSource}
              dispatch = {dispatch}
              currentSourceCounter = {props.visible_sources.length}
              is_visible = {true}
              change_anomaly_detected = {props.change_anomaly_detected}
              active_id = {props.active_id}
              ref = {info_div_ref}
            />
          </div>
      </div>
    );
  }
);