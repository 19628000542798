const style = {
  MAIN_COLOR: "#0070A8",
  SECONDARY_COLOR: "#0FA8E2",
  ALTERNATIVE_COLOR: "#A2D9F7",
  LIGHT_BORDER_COLOR: "#757575",
  DARK_BORDER_COLOR: "#595959",
  BACKGROUND_COLOR: "#fff",
  DARK_BACKGROUND_COLOR: "#212529",

  BACKGROUND_DANGER_MESSAGE_COLOR: "#ffb0b0",
  DANGER_MESSAGE_COLOR: "#721c1c",

  BACKGROUND_SUCCESS_MESSAGE_COLOR: "#d4edda",
  SUCCESS_MESSAGE_COLOR: "#155724",


  
};

export default style;
