import {CSS} from '@dnd-kit/utilities';
import {useSortable} from '@dnd-kit/sortable';
import { SourceViewMain } from "./SourceViewMain.js";
import React, { useState, useEffect, forwardRef } from "react";

export const SourceViewMainSortable = forwardRef(
  ({ ...props }, ref) => {

    // variables and states
    const [anomaly_detected, set_anomaly_detected] = useState(null);
    function change_anomaly_detected(new_value) { set_anomaly_detected(new_value); }

    const [draggable_should_be_disabled, set_draggable_should_be_disabled] = useState(localStorage.getItem("draggable_should_be_disabled"));
    const sortable = useSortable({id: props.item, disabled: draggable_should_be_disabled === "true" ? true : false});
    const {
        attributes,
        listeners,
        isDragging,
        setNodeRef,
        transform,
        transition,
    } = sortable;

    const style = {
      transform: CSS.Translate.toString(transform),
      transition,
      zIndex: (props.active_id === props.item["unique_identifier"] ? "30000000" : "5"),
      borderRadius: "8px",
      padding: "1px",
      marginLeft: "-5px",
      overflow: "visible",
      //resize: "horizontal",
    };

    // add event listeners
    useEffect(() => {

      // listen for info div status changes
      function draggable_should_be_disabled_change() { 
        set_draggable_should_be_disabled(localStorage.getItem("draggable_should_be_disabled"));
      }
      window.addEventListener('draggable_should_be_disabled_change', draggable_should_be_disabled_change);

      // do some cleanup (i.e., remove the event listeners if this component ends up being unmounted)
      return(() => {
        window.removeEventListener('draggable_should_be_disabled_change', draggable_should_be_disabled_change);
      })

    }, []);

    return (
        <SourceViewMain
          style = {style}
          is_being_dragged = {props.active_id === props.item ? "true" : "false"}
          sources_image_data = {props.sources_image_data}
          change_sources_image_data = {props.change_sources_image_data}
          anomaly_detected = {anomaly_detected}
          change_anomaly_detected = {change_anomaly_detected}
          {...props}
          {...attributes}
          {...listeners}
          ref = {{
            drag_and_drop_ref: setNodeRef, // for the drag and drop functionality
            info_div_ref: ref, // for the source info div
          }}>
        </SourceViewMain>
    );
  }
);