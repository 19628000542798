import React from "react";
import { useState, useEffect } from "react";
import styles from '../../styles/header_logos.module.scss';

const HeaderLogos = (props) => {

    // variables and states
    const [theme, set_theme] = useState(localStorage.getItem("theme"));
    const [cursor_pointer, set_cursor_pointer] = useState("auto");
    const [window_width, set_window_width] = useState(window.innerWidth);
    const mobile_navbar_height = getComputedStyle(document.documentElement).getPropertyValue('--mobile_navbar_height');
    const [is_mobile, set_is_mobile] = useState(window.innerWidth < parseInt(localStorage.getItem("desktop_version_min_width")));
    const [current_user, set_current_user] = useState(localStorage.getItem("current_user"));

    // add event listeners
    useEffect(() => {

        // listen for theme changes
        function theme_change() {
            set_theme(localStorage.getItem("theme"));
        }
        window.addEventListener('theme_change', theme_change);

        // listen for window resizes
        function resize() { 
            set_window_width(window.innerWidth); 
        }
        window.addEventListener('resize', resize);

        // do some cleanup (i.e., remove the event listeners if this component ends up being unmounted)
        return(() => {
            window.removeEventListener('theme_change', theme_change);
            window.removeEventListener('resize', resize);
        })

    }, []);

    useEffect(() => {

        set_is_mobile(window_width < parseInt(localStorage.getItem("desktop_version_min_width")));
    
    }, [window_width]);

    const get_logo = (type) => {

        let image_path = "/media/images/logos/" + type + "/" + theme.toLowerCase() + "_mode/"

        // check whether we need the login or dashboard version
        image_path += (props.current_url === "/" ? "login_" : "dashboard_")

        // check whether we need the desktop or mobile version
        image_path += (window_width >= 950 ? "desktop" : "mobile") + ".png"

        return(image_path)
    }

    const handle_click = () => {
        
        if(props.current_url === "/")
            window.open("https://www.deepneuronic.com", "_blank");
        
        else{

            window.dispatchEvent(new Event("info_or_fullscreen_div_status_should_disappear"));
            window.dispatchEvent(new Event("add_source_menu_should_disappear"));
            window.dispatchEvent(new Event("edit_source_menu_should_disappear"));
            window.dispatchEvent(new Event("all_menus_should_disappear"));
            
            localStorage.setItem("draggable_should_be_disabled", is_mobile ? true : false); 
            window.dispatchEvent(new Event("draggable_should_be_disabled_change"));
        }
    }

    return ( 
        <div
            style={{ 
                cursor: cursor_pointer,
                width: (props.current_url === "/" ? "fit-content" : "100%"),
                marginLeft: "auto",
                marginRight: "auto",
                margin: "0 auto",
                display: "flex",
                //border: "1px solid green",
            }}
            onMouseEnter={() => set_cursor_pointer("pointer")}
            onMouseLeave={() => set_cursor_pointer("auto")}
            onClick = {() => handle_click()} 
            >
            <div className = {styles.header_inner_div}>
                <div 
                    className = {styles.header_logo_div}
                    style = {{
                        justifyContent: "right",
                    }}>
                        <img 
                            alt = "DeepNeuronic logo"
                            src = {get_logo("deepneuronic")}
                            style = {{
                                position: "relative",
                                cursor: "pointer",
                                height: ((is_mobile && props.current_url !== "/") ? ((0.95 * parseInt(mobile_navbar_height.replace("px", ""))) + "px") : "50px"),
                                width: "auto",
                                //border: "1px solid red",
                            }} 
                        />{" "}
                    </div>
                <div 
                    className = {styles.header_logo_separator}
                    style = {{
                        display: (current_user === "rocknrio" ? "" : "none"),
                        borderRight: "0.020in solid " + (props.current_url === "/" ? (theme === "Light" ? "var(--colour_one)" : "rgba(80, 80, 80, 1.0)") : (theme === "Light" ? "rgba(125, 125, 125, 0.35)" : "rgba(110, 110, 110, 0.35)")),
                    }}/>
                <div 
                    className = {styles.header_logo_div}
                    style = {{
                        display: (current_user === "rocknrio" ? "" : "none"),
                        justifyContent: "flex-start",
                    }}>
                    <img 
                        alt = "Partner logo"
                        src = {get_logo("intelicity")}
                        style = {{
                            position: "relative",
                            cursor: "pointer",
                            height: ((is_mobile && props.current_url !== "/") ? ((0.95 * parseInt(mobile_navbar_height.replace("px", ""))) + "px") : "50px"),
                            width: "auto",
                            //border: "1px solid red",
                        }} 
                    />{" "}
                </div>
            </div>
        </div>
    );
}

export default HeaderLogos;