import React, { useEffect, useState } from "react";
import styles from "../../styles/ai_indicator.module.scss";
import json_ai_indicator_en from '../../jsons/en/ai_indicator_en.json';
import json_ai_indicator_pt from '../../jsons/pt/ai_indicator_pt.json';

const AIButton = (props) => {

    // variables and states
    const language = localStorage.getItem("language");
    const json_data = (language === "English" ? json_ai_indicator_en : json_ai_indicator_pt);
    const [theme, set_theme] = useState(localStorage.getItem("theme"));
    const ai_colour = getComputedStyle(document.documentElement).getPropertyValue('--ai_colour');
    const red_alert_colour = getComputedStyle(document.documentElement).getPropertyValue('--red_alert_colour_' + theme.toLowerCase() + "_mode");
    const yellow_alert_colour = getComputedStyle(document.documentElement).getPropertyValue('--yellow_alert_colour_' + theme.toLowerCase() + "_mode");

    // add event listeners
    useEffect(() => {

        // listen for theme changes
        function theme_change() {
            set_theme(localStorage.getItem("theme"));
        }
        window.addEventListener('theme_change', theme_change);

        // do some cleanup (i.e., remove the event listeners if this component ends up being unmounted)
        return(() => {
            window.removeEventListener('theme_change', theme_change);
        })

    }, []);

    return (
        <div 
            className = {styles.ai_icon_div}
            style = {{
                backgroundColor: (props.anomaly_detected !== "none" ? (props.anomaly_detected === "red" ? red_alert_colour : yellow_alert_colour).replace("1)", "0.65)") : ai_colour.replace("1)", "0.75")),
            }}>
            <div 
            className = {styles.ai_icon} 
            style = {{
                backgroundImage: json_data.ai_icon.replace("COLOUR", "white").replace("LANGUAGE", language === "English" ? "en" : "pt"),
            }}/>
        </div>
    );
}

export default AIButton;