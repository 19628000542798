import React, { useState, useEffect, useRef } from "react";
import styles from "./detectionsStatisticsMenu.module.scss";

const DetectionsStatisticsMenu = ( { mlAnalysis, parentDivDimensions, configFile } ) => {

    const ref = useRef();
    const [iconSize, setIconSize] = useState(20);
    const [fontSize, setFontSize] = useState(13);
    const [itemsList, setItemsList] = useState([]);
    const [isOverflowing, setIsOverflowing] = useState(false);

    useEffect(() => {
        
        if(mlAnalysis["statistics_menu"] === undefined)
            return;

        let itemsListAux = [];
        
        Object.entries(mlAnalysis["statistics_menu"]).forEach(([groupType, items]) => {
            Object.entries(items).forEach(([className, count]) => {
                itemsListAux.push([className, count])
            })
        })

        setItemsList(itemsListAux);

    }, [mlAnalysis]);

    useEffect(() => {

        // ----------------------------------------
        // determine the optimal icon size
        // ----------------------------------------
        if(parentDivDimensions["width"] > 700)
            setIconSize(22);

        else if(parentDivDimensions["width"] > 500)
            setIconSize(18);

        else if(parentDivDimensions["width"] > 350)
            setIconSize(16);

        else if(parentDivDimensions["width"] > 250)
            setIconSize(14);

        else if(parentDivDimensions["width"] > 100)
            setIconSize(12);

        // ----------------------------------------
        // determine the optimal font size
        // ----------------------------------------
        if(parentDivDimensions["width"] > 700)
            setFontSize(12);

        else if(parentDivDimensions["width"] > 400)
            setFontSize(11);

        else
            setFontSize(10);

    }, [parentDivDimensions]);

    // detect if the root element has overflowing children
    /*useEffect(() => {
        
        if(ref !== null && ref.current !== null){
            if(ref.current.offsetHeight < ref.current.scrollHeight)
                setIsOverflowing(true);
            else
                setIsOverflowing(false);
        }
    });*/

    const checkForClassNameMappings = (ogClassName) => {

        /*if(ogClassName === "low speed")
            return("slow");*/

        return(ogClassName);
    }

    return(
        <div 
            ref = {ref}
            className = {styles.rootDiv}
            style = {{
                top: configFile["VISUALISATION_DETAILS"]["STATISTICS_MENU"]["POSITION"].includes("top") ? "3px" : "",
                bottom: configFile["VISUALISATION_DETAILS"]["STATISTICS_MENU"]["POSITION"].includes("bottom") ? "3px" : "",
                left: configFile["VISUALISATION_DETAILS"]["STATISTICS_MENU"]["POSITION"].includes("left") ? "3px" : "",
                right: configFile["VISUALISATION_DETAILS"]["STATISTICS_MENU"]["POSITION"].includes("right") ? "3px" : "",
                paddingTop: (isOverflowing ? "7px" : "0px"),
                paddingBottom: (isOverflowing ? "7px" : "0px"),
                //maskImage: (isOverflowing ? "linear-gradient(to bottom, transparent 0%, black 10px, black calc(100% - 10px), transparent 100%)" : ""),
            }}>
                {
                    itemsList.map((item, index) => (
                        <div 
                            key = {index}
                            className = {styles.itemOuterDiv}
                            style = {{
                                justifyContent: (configFile["VISUALISATION_DETAILS"]["STATISTICS_MENU"]["POSITION"].includes("left") ? "left" : "right"),
                            }}>
                                <div 
                                    className = {styles.itemInnerDiv}
                                    style = {{
                                        padding: "2px",
                                    }}>
                                    
                                    <div 
                                        className = {styles.iconBackgroundDiv}
                                        style = {{
                                            backgroundColor: (!item[0].includes("-") ? "transparent" : (mlAnalysis["debug_visuals"]["polygons"].filter((delimitedArea) => delimitedArea["DESCRIPTION"].split(" • ")[0].split("-")[1] === item[0].split(" • ")[0].split("-")[1]).length === 0 ? "transparent" : "rgba(" + mlAnalysis["debug_visuals"]["polygons"].filter((delimitedArea) => delimitedArea["DESCRIPTION"].split(" • ")[0].split("-")[1] === item[0].split(" • ")[0].split("-")[1])[0]["COLOUR"].slice(0, -1).join(", ") + ", 1.0)"))
                                        }}>

                                        <div 
                                            className = {styles.icon} 
                                            style = {{
                                                height: (iconSize + "px"),
                                                width: (iconSize + "px"),
                                                backgroundImage: "url(\"/media/icons/icon_pack/" + item[0].split("-")[0] + ".png\")",
                                            }}/> 
                                    </div> 

                                    <div 
                                        className = {styles.name}
                                        style = {{
                                            fontSize: (fontSize + "px"),
                                        }}>
                                        {checkForClassNameMappings(item[0].replace("_", " "))}
                                    </div>

                                    <div 
                                        className = {styles.count}
                                        style = {{
                                            fontSize: (fontSize + "px"),
                                            marginRight: ("7px"),
                                        }}>
                                        {item[1]}
                                    </div>
                                </div>
                        </div>
                    ))
                }
        </div>
    );
}

export default DetectionsStatisticsMenu;