import ReactDOM from 'react-dom';
import useMeasure from "react-use-measure";
import StatisticsComponent from "./StatisticsComponent";
import VisualisationManager from "./VisualisationManager";
import AIIndicator from '../../reusable_components/AIIndicator';
import CircularProgress from '@material-ui/core/CircularProgress';
import { are_the_two_lists_equal } from "../../utilities/Utils.js";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import styles from "../../../styles/source_info_fullscreen_div.module.scss";
import CanvasContainer from "../../../containers/Sources/Visualization/CanvasContainer";

export const SourceInfoFullscreenDiv = forwardRef(
    ( { ...props }, ref ) => {

        // variables and states
        const [theme, set_theme] = useState(localStorage.getItem("theme"));
        const [rootDivRef, rootDivDimensions] = useMeasure();
        const [videoAndStatsSuperDivRef, videoAndStatsSuperDivDimensions] = useMeasure();
        const [anomaly_detected, set_anomaly_detected] = useState(null);
        const ai_colour = getComputedStyle(document.documentElement).getPropertyValue('--ai_colour');
        const [red_alert_colour, set_red_alert_colour] = useState(getComputedStyle(document.documentElement).getPropertyValue('--red_alert_colour_' + theme.toLowerCase() + "_mode"));
        const [yellow_alert_colour, set_yellow_alert_colour] = useState(getComputedStyle(document.documentElement).getPropertyValue('--yellow_alert_colour_' + theme.toLowerCase() + "_mode"));
        const [ai_sources, set_ai_sources] = useState(localStorage.getItem("ai_sources") !== null ? JSON.parse(localStorage.getItem("ai_sources")) : []);
        const [header_menus_visibility, set_header_menus_visibility] = useState(localStorage.getItem("header_menus_visibility"));
        const [navbar_behaviour, set_navbar_behaviour] = useState(localStorage.getItem("navbar_behaviour"));
        const navbar_height = getComputedStyle(document.documentElement).getPropertyValue('--navbar_height');
        const [can_leave, set_can_leave] = useState(true);
        const [info_div_video_height, set_info_div_video_height] = useState(0);
        const [info_div_video_width, set_info_div_video_width] = useState(0);
        const [window_width, set_window_width] = useState(window.innerWidth);
        const [window_height, set_window_height] = useState(window.innerHeight);
        const [current_source, set_current_source] = useState(localStorage.getItem("info_or_fullscreen_div_current_source"));
        const inner_div_ref = useRef(null);
        const [frame_height, set_frame_height] = useState(0);
        const [frame_width, set_frame_width] = useState(0);
        const [is_loading, set_is_loading] = useState(true);
        const [info_div_status, set_info_div_status] = useState(localStorage.getItem("info_div_status"));
        const [fullscreen_div_status, set_fullscreen_div_status] = useState(localStorage.getItem("fullscreen_div_status"));
        const [is_mobile, set_is_mobile] = useState(window.innerWidth < parseInt(localStorage.getItem("desktop_version_min_width")));
        const [layoutMode, setLayoutMode] = useState("horizontal");
        const [exitTimeoutDone, setExitTimeoutDone] = useState(true);

        const [mlAnalysis, setMlAnalysis] = useState({
            "predictions": {},
            "use_case_controllers": {},
            "debug_visuals": {
                "polygons": [],
                "lines": []
            },
            "stats": {},
            "vitals": {},
        });

        const [configFile, setConfigFile] = useState({});

        useEffect(() => {

            // listen for theme changes
            function theme_change() {

                let storedValue = localStorage.getItem("theme");

                set_theme(storedValue);
                set_red_alert_colour(getComputedStyle(document.documentElement).getPropertyValue('--red_alert_colour_' + storedValue.toLowerCase() + "_mode"));
                set_yellow_alert_colour(getComputedStyle(document.documentElement).getPropertyValue('--yellow_alert_colour_' + storedValue.toLowerCase() + "_mode"));
            }
            window.addEventListener('theme_change', theme_change);

            // listen for frame width changes
            function current_source_frame_dimensions_change() {
                set_frame_height(parseInt(localStorage.getItem("current_source_frame_height")));
                set_frame_width(parseInt(localStorage.getItem("current_source_frame_width")));
            }
            window.addEventListener('current_source_frame_dimensions_change', current_source_frame_dimensions_change);

            window.addEventListener('visualisationManagerArgumentsChange', visualisationManagerArgumentsChange);

            // listen for header menu visibility changes
            function header_menus_visibility_change() {
                set_header_menus_visibility(localStorage.getItem("header_menus_visibility"));
            }
            window.addEventListener('header_menus_visibility_change', header_menus_visibility_change);

            // listen for navbar behaviour changes
            function navbar_behaviour_change() {
                set_navbar_behaviour(localStorage.getItem("navbar_behaviour"));
            }
            window.addEventListener('navbar_behaviour_change', navbar_behaviour_change);

            // know when an anomaly has been detected
            function anomaly_detected_change() {
                set_anomaly_detected(localStorage.getItem("alert_colour"));
            }
            window.addEventListener('anomaly_detected_change', anomaly_detected_change);

            // listen for window resizes
            function resize() { 
                set_window_width(window.innerWidth); 
                set_window_height(window.innerHeight);
            }
            window.addEventListener('resize', resize);

            // listen for info div and fullscreen div status changes
            function info_or_fullscreen_div_status_change() {
                set_info_div_status(localStorage.getItem("info_div_status"));
                set_fullscreen_div_status(localStorage.getItem("fullscreen_div_status"));
                
                if(localStorage.getItem("info_div_status") === "visible" || localStorage.getItem("fullscreen_div_status") === "visible")
                    set_current_source(localStorage.getItem("info_or_fullscreen_div_current_source"));
            }
            window.addEventListener('info_or_fullscreen_div_status_change', info_or_fullscreen_div_status_change);

            // listen for AI sources changes
            function ai_sources_change() {

                let ai_sources_aux = localStorage.getItem("ai_sources") !== null ? JSON.parse(localStorage.getItem("ai_sources")) : [];
                
                if(!are_the_two_lists_equal(ai_sources_aux, ai_sources))
                set_ai_sources(ai_sources_aux);
            }
            window.addEventListener('ai_sources_change', ai_sources_change);

            // know when to hide this component
            function info_or_fullscreen_div_status_should_disappear() {
                handle_click_away(true);
            }
            window.addEventListener('info_or_fullscreen_div_status_should_disappear', info_or_fullscreen_div_status_should_disappear);
            
            // do some cleanup (i.e., remove the event listeners if this component ends up being unmounted)
            return(() => {
                window.removeEventListener('theme_change', theme_change);
                window.removeEventListener('current_source_frame_dimensions_change', current_source_frame_dimensions_change);
                window.removeEventListener('header_menus_visibility_change', header_menus_visibility_change);
                window.removeEventListener('navbar_behaviour_change', navbar_behaviour_change);
                window.removeEventListener('anomaly_detected_change', anomaly_detected_change);
                window.removeEventListener('resize', resize);
                window.removeEventListener('info_or_fullscreen_div_status_change', info_or_fullscreen_div_status_change);
                window.removeEventListener('info_or_fullscreen_div_status_should_disappear', info_or_fullscreen_div_status_should_disappear);
                window.removeEventListener('ai_sources_change', ai_sources_change);
            });
        
        }, [current_source, can_leave]);

        function visualisationManagerArgumentsChange() {
            
            let stuffThatWasStoredAux = localStorage.getItem("visualisationManagerArguments");

            if(stuffThatWasStoredAux === null)
                return;

            let stuffThatWasStored = JSON.parse(stuffThatWasStoredAux);

            if(!(current_source in stuffThatWasStored))
                return;

            setMlAnalysis(stuffThatWasStored[current_source]["mlAnalysis"]);
            setConfigFile(stuffThatWasStored[current_source]["configFile"]);
        }

        useEffect(() => {

            if(frame_height !== 0 && frame_width !== 0)
                set_is_loading(false);

        }, [frame_height, frame_width]);

        useEffect(() => {

            let storedValue = localStorage.getItem("alert_colour");
            if(storedValue === "null");
                storedValue = null;

            set_anomaly_detected(storedValue);    
            set_is_mobile(window_width < parseInt(localStorage.getItem("desktop_version_min_width")));
            
        }, []);

        useEffect(() => {
        
            visualisationManagerArgumentsChange();

            let newInfoDivHeightValue = 0

            if(info_div_status === "hidden" && fullscreen_div_status === "hidden"){
                set_info_div_video_height(0);
                return;
            }

            // fullscreen mode
            if(info_div_status === "hidden"){ 

                newInfoDivHeightValue = parseInt(rootDivDimensions["width"] / parseFloat(localStorage.getItem("aspect_ratio")));
                newInfoDivHeightValue = Math.min(newInfoDivHeightValue, window_height - (2 * parseInt(navbar_height.replace("px", ""))));

                set_info_div_video_height(newInfoDivHeightValue);
                set_info_div_video_width(rootDivDimensions["width"] - 7);
                return;
            }

            // first breakpoint
            if (rootDivDimensions["width"] >= 1100){
                setLayoutMode("horizontal");
                newInfoDivHeightValue = (navbar_behaviour === "Collapsed" ? rootDivDimensions["height"] * 0.5 : rootDivDimensions["height"] * 0.45);
            }
            
            else{

                setLayoutMode("vertical");
                
                // second breakpoint
                if (rootDivDimensions["width"] >= 900)
                    newInfoDivHeightValue = (navbar_behaviour === "Collapsed" ? rootDivDimensions["height"] * 0.35 : rootDivDimensions["height"] * 0.3);

                // second breakpoint
                else
                    newInfoDivHeightValue = rootDivDimensions["height"] * 0.3;
            }

            // update the states
            set_info_div_video_height(newInfoDivHeightValue);
            set_info_div_video_width(parseInt(parseFloat(localStorage.getItem("aspect_ratio")) * newInfoDivHeightValue));
        
        }, [info_div_status, fullscreen_div_status, rootDivDimensions, window_height]);

        const handle_click_away = (override_is_mobile) => {

            if(!can_leave)
                return;
            
            localStorage.setItem("info_div_status", "hidden");
            localStorage.setItem("fullscreen_div_status", "hidden");
            set_info_div_status("hidden");

            window.dispatchEvent(new Event("info_or_fullscreen_div_status_change"));

            setExitTimeoutDone(false);
            setTimeout(() => {
                setExitTimeoutDone(true);
            }, 2500);

            localStorage.setItem("draggable_should_be_disabled", is_mobile ? true : false); 
            window.dispatchEvent(new Event("draggable_should_be_disabled_change"));
        }

        document.addEventListener('keydown', function (event) {
            if (event.key === "Escape") {
                handle_click_away(true)
            }
        });

        const determineBorderColour = () => {

            if(anomaly_detected === "red")
                return(red_alert_colour);

            if(anomaly_detected === "yellow")
                return(yellow_alert_colour);

            if(ai_sources.includes(current_source))
                return(ai_colour);

            return(theme === "Light" ? "var(--colour_one)" : "rgba(80, 80, 80, 0.55)");
        }

        return (
            <div 
                ref = {rootDivRef}
                className = {styles.root}
                style = {{
                    visibility: (fullscreen_div_status === "visible" || info_div_status === "visible" ? "visible" : "hidden"),
                    opacity: (fullscreen_div_status === "visible" || info_div_status === "visible" ? "1.0" : "0.0"),
                    zIndex: (fullscreen_div_status === "visible" || info_div_status === "visible" ? "4000000000000" : "-40"),
                    transition: "all 0.25s ease-in-out",
                    top: (is_mobile ? "10px" : "20px"),
                    bottom: (is_mobile ? "10px" : "20px"),
                }}>

                <div 
                    className = {styles.close_button_div}
                    style = {{
                        display: (is_mobile ? "" : "none"),
                        backgroundColor: (theme === "Light" ? "var(--colour_one)" : "rgba(190, 190, 190, 1.0)"),
                    }}
                    onClick = {() => {set_can_leave(true); handle_click_away(true)}}>
                    <div 
                        className = {styles.close_button} 
                        style = {{
                            backgroundImage: (theme === "Light" ? "url(\"/media/icons/close_white_2.png\")" : "url(\"/media/icons/close_4.png\")")
                        }}/>
                </div>

                <div className = {styles.super_div}>

                    <div
                        className = {styles.background_div}
                        onClick = {() => {set_can_leave(true); handle_click_away(true)}}/>

                    <div
                        ref = {inner_div_ref}
                        className = {styles.inner_div}
                        style = {{
                            flexDirection: (info_div_status === "hidden" ? "row" : "column"),
                            alignItems: (info_div_status === "hidden" ? "center" : ""),
                        }}>

                        <div 
                            ref = {videoAndStatsSuperDivRef}
                            className = {styles.videoAndStatsSuperDiv}
                            style = {{
                                height: (info_div_status === "hidden" && exitTimeoutDone ? "100%" : layoutMode === "horizontal" ? "" : "65%"),
                                flexDirection: (rootDivDimensions["width"] < 1100 ? "column" : "row"),
                            }}>

                            <div
                                className = {`${styles.video_div} ${anomaly_detected === null ? null: (anomaly_detected === "red" ? styles.video_div_red : styles.video_div_yellow)}`}
                                style={{
                                    height: info_div_video_height + "px",
                                    backgroundColor: (frame_width !== 0 ? "rgba(0, 0, 0, 1.0)" : (theme === "Light" ? "rgba(180, 180, 180, 1.0)" : "rgba(35, 35, 35, 1.0)")),
                                    width: (info_div_video_width + "px"),
                                    margin: "0 auto",
                                    borderRadius: "8px",
                                    position: (info_div_status === "hidden" && exitTimeoutDone ? "absolute" : "relative"),
                                    left: "0",
                                    right: "0",
                                    top: "0",
                                    bottom: "0",
                                    margin: "auto",
                                    overflow: "hidden",
                                    border: "3px solid " + determineBorderColour(),
                                    //border: "1px solid red",
                                }}>

                                {/*{ ai_sources.includes(current_source) ? <AIIndicator anomaly_detected = {anomaly_detected}/> : <></> }*/}

                                <div className={styles.circular_progress_div}>
                                    <CircularProgress
                                        size={50}
                                        style={{
                                        //display: "none",
                                        color: (theme === "Light" ? "var(--colour_three)" : "rgba(80, 80, 80, 1.0)"),
                                        opacity: (is_loading ? "1.0" : "0.0")
                                        }} />
                                </div>
                                
                                <div 
                                    className = {theme === "Light" ? styles.skeleton_light_mode : styles.skeleton_dark_mode} 
                                    style = {{
                                        visibility: (is_loading ? "visible" : "hidden"),
                                    }}/>

                                <div 
                                    className = {styles.visualisationManagerDiv}
                                    style = {{
                                        display: (is_loading ? "none" : ""),
                                    }}>
                                    <VisualisationManager 
                                        mlAnalysis = {mlAnalysis}
                                        frameWidth = {frame_width}
                                        frameHeight = {frame_height}
                                        scale = {"2"}
                                        configFile = {configFile} />
                                </div>
                                
                                <CanvasContainer
                                    ref = {ref}
                                    width = {frame_width === 0 ? 720 : parseInt((1280 / 720) * frame_height)}
                                    height = {frame_height === 0 ? 1280 : frame_height}
                                    style = {{
                                        maxHeight: "100%",
                                        width: "100%",
                                        backgroundColor: (frame_width !== 0 ? "rgb(0, 0, 0, 1.0)" : "rgb(70, 70, 70, 1.0)"),
                                        borderRadius: "3px",
                                        //border: "1px solid magenta",
                                        visibility: (is_loading ? "hidden" : "visible"),
                                    }} />
                            </div>

                            <div
                                className = {styles.stats_div}
                                style = {{
                                    height: (layoutMode === "horizontal" ? info_div_video_height + "px" : (videoAndStatsSuperDivDimensions["height"] - info_div_video_height - 10) + "px"),
                                    width: (layoutMode === "horizontal" ? "auto" : "100%"),
                                    display: (info_div_status === "hidden" ? "none" : ""),
                                    backgroundColor: (theme === "Light" ? "rgba(245, 245, 245, 1.0)" : "var(--colour_one_dark_mode)"),
                                    //backgroundColor: "rgba(255, 128, 128, 1.0)",
                                    border: (theme === "Light" ? "0.020in solid var(--colour_three)" : "0.020in solid rgba(80, 80, 80, 0.55)"),
                                    boxShadow: (theme === "Light" ? "rgba(100, 100, 100, 0.35) 0px 0px 10px" : "rgba(10, 15, 21, 0.75) 0px 8px 24px"),
                                }}>
                                {               
                                    fullscreen_div_status === "hidden" ? 
                                    <StatisticsComponent 
                                        key = {2}
                                        source = {current_source}
                                        visibleTabs = {"allButLast"} /> : 
                                    <></>
                                }
                            </div>
                        </div>

                        <div
                            className = {styles.history_div}
                            style = {{
                                height: (layoutMode === "horizontal" ? (rootDivDimensions["height"] - info_div_video_height) + "px" : "30%"),
                                display: (info_div_status === "hidden" ? "none" : ""),
                                backgroundColor: (theme === "Light" ? "rgba(245, 245, 245, 1.0)" : "var(--colour_one_dark_mode)"),
                                //backgroundColor: "rgba(153, 204, 255, 1.0)",
                                border: (theme === "Light" ? "0.020in solid var(--colour_three)" : "0.020in solid rgba(80, 80, 80, 0.55)"),
                                boxShadow: (theme === "Light" ? "rgba(100, 100, 100, 0.35) 0px 0px 10px" : "rgba(10, 15, 21, 0.75) 0px 8px 24px"),
                            }}>
                            {               
                                fullscreen_div_status === "hidden" ? 
                                <StatisticsComponent 
                                    key = {3}
                                    source = {current_source}
                                    visibleTabs = {"last"} /> : 
                                <></>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
);
