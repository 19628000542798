import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { authenticateApi } from "../services/AuthService";
import { sourceApi } from "../services/SourceService";
import flashMessagesReducer from "./slices/flashMessagesSlice";
import userReducer from "./slices/userSlice";
import { combineReducers } from "@reduxjs/toolkit";

export const store = configureStore({
  reducer: combineReducers({
    user: userReducer,
    flashMessages: flashMessagesReducer,
    [authenticateApi.reducerPath]: authenticateApi.reducer,
    [sourceApi.reducerPath]: sourceApi.reducer,
  }),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(authenticateApi.middleware)
      .concat(sourceApi.middleware),
});

setupListeners(store.dispatch);
