import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import REACT_APP_API_URL from "../config/Api";
import { store } from "../store/store";

export const authenticateApi = createApi({
  reducerPath: "authenticate",
  baseQuery: fetchBaseQuery({
    baseUrl: REACT_APP_API_URL,
    prepareHeaders: (headers) => {
      const token = store.getState().user.user.accessToken;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    postCredentials: builder.mutation({
      query(body) {
        return {
          url: `auth/token`,
          method: "POST",
          body,
        };
      },
    }),
    getCheckToken: builder.query({
      query() {
        return {
          url: `auth/checkToken`,
        };
      },
    }),
  }),
});

export const { usePostCredentialsMutation, useGetCheckTokenQuery } =
  authenticateApi;
