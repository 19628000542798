import ReactDOM from 'react-dom';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import useMeasure from "react-use-measure";
import Button from "../reusable_components/Button.js";
import ClickAwayListener from 'react-click-away-listener';
import React, { useState, useEffect, useRef } from "react";
import styles from "../../styles/sources_menu.module.scss";
import AIIndicator from '../reusable_components/AIIndicator.js';
import { are_the_two_lists_equal } from "../utilities/Utils.js";
import CircularProgress from '@material-ui/core/CircularProgress';
import json_sources_menu_en from '../../jsons/en/sources_menu_en.json';
import json_sources_menu_pt from '../../jsons/pt/sources_menu_pt.json';
import {
    useDeleteLinkSourceToUserByUIDMutation,
    useDeleteSourceMutation,
    usePostActivateSourceMutation,
    usePostDeactivateSourceMutation,
    usePostLinkSourceToUserByUIDMutation,
} from "../../services/SourceService";

const SourcesMenu = (props) => {

    // variables and states
    const language = localStorage.getItem("language");
    const json_data = (language === "English" ? json_sources_menu_en : json_sources_menu_pt);
    const aux_ref = React.createRef();
    const thumbnail_parent_ref = useRef(null);
    const [theme, set_theme] = useState(localStorage.getItem("theme"));
    const [active_tab, set_active_tab] = useState(0);
    const [source_button_loading, set_source_button_loading] = useState(null);
    const divider = "//";
    const [window_width, set_window_width] = useState(window.innerWidth);
    const ai_colour = getComputedStyle(document.documentElement).getPropertyValue('--ai_colour');
    const alternative_ai_colour = getComputedStyle(document.documentElement).getPropertyValue('--alternative_ai_colour');
    const [hover_state, set_hover_state] = useState(-1);
    const [ai_sources, set_ai_sources] = useState(localStorage.getItem("ai_sources") !== null ? JSON.parse(localStorage.getItem("ai_sources")) : []);
    const [hidden_sources, set_hidden_sources] = useState(localStorage.getItem("hidden_sources") !== null ? JSON.parse(localStorage.getItem("hidden_sources")) : []);
    const [visible_sources, set_visible_sources] = useState(localStorage.getItem("visible_sources") === null ? [] : JSON.parse(localStorage.getItem("visible_sources")).filter((item) => !hidden_sources.includes(item.unique_identifier)));
    const [root_ref, root_dimensions] = useMeasure();
    const [visible_sources_div_ref, visible_sources_div_dimensions] = useMeasure();
    const [hidden_sources_div_ref, hidden_sources_div_dimensions] = useMeasure();
    const sources_refs = useRef({});
    const [sources_aspect_ratios, set_sources_aspect_ratios] = useState({});
    const [is_mobile, set_is_mobile] = useState(window.innerWidth < parseInt(localStorage.getItem("desktop_version_min_width")));

    // add event listeners
    useEffect(() => {

        // listen for theme changes
        function theme_change() {
            set_theme(localStorage.getItem("theme"));
        }
        window.addEventListener('theme_change', theme_change);

        // listen for window resizes
        function resize() { 
            set_window_width(window.innerWidth); 
        }
        window.addEventListener('resize', resize);

        // listen for AI sources changes
        function ai_sources_change() {

            let ai_sources_aux = localStorage.getItem("ai_sources") !== null ? JSON.parse(localStorage.getItem("ai_sources")) : [];
            
            if(!are_the_two_lists_equal(ai_sources_aux, ai_sources))
                set_ai_sources(ai_sources_aux);
        }
        window.addEventListener('ai_sources_change', ai_sources_change);

        // listen for source changes
        function sources_change() {

            // visible sources
            let visible_sources_aux = localStorage.getItem("visible_sources") !== null ? JSON.parse(localStorage.getItem("visible_sources")) : [];
            
            if(!are_the_two_lists_equal(visible_sources_aux, visible_sources))
                set_visible_sources(visible_sources_aux);

            // hidden sources
            let hidden_sources_aux = localStorage.getItem("hidden_sources") !== null ? JSON.parse(localStorage.getItem("hidden_sources")) : [];
            if(!are_the_two_lists_equal(hidden_sources_aux, hidden_sources))
                set_hidden_sources(hidden_sources_aux);
        }
        window.addEventListener('sources_change', sources_change);

        // do some cleanup (i.e., remove the event listeners if this component ends up being unmounted)
        return(() => {
            window.removeEventListener('theme_change', theme_change);
            window.removeEventListener('resize', resize);
            window.removeEventListener('ai_sources_change', ai_sources_change);
            window.removeEventListener('sources_change', sources_change);
        })

    }, []);

    useEffect(() => {

        set_is_mobile(window_width < parseInt(localStorage.getItem("desktop_version_min_width")));
    
    }, [window_width]);

    const [
        deleteSource,
        { isSuccess: deleteSourceIsSuccess, isError: deleteSourceIsError },
    ] = useDeleteSourceMutation();

    const [
        postActivateSource,
        {
            isSuccess: postActivateSourceIsSuccess,
            isError: postActivateSourceIsError,
        },
    ] = usePostActivateSourceMutation();

    const [
        postDeactivateSource,
        {
            isSuccess: postDeactivateSourceIsSuccess,
            isError: postDeactivateSourceIsError,
        },
    ] = usePostDeactivateSourceMutation();

    const draw_sources_thumbnails = (source_type) => {

        const target_sources = (source_type === "visible" ? visible_sources : hidden_sources)
        
        if(target_sources.length === 0) return;

        for(let i = 0; i < target_sources.length; i++){
            
            // update the image data
            if((target_sources[i]["unique_identifier"] in sources_refs.current) && (target_sources[i]["unique_identifier"] in props.sources_image_data) && props.sources_image_data[target_sources[i]["unique_identifier"]]["image_data"] !== null){
                
                let ctx = sources_refs.current[target_sources[i]["unique_identifier"]].getContext("2d")
                ctx.putImageData(props.sources_image_data[target_sources[i]["unique_identifier"]]["image_data"], 0, 0);

                // update the aspect ratios
                set_sources_aspect_ratios(previous_sources_aspect_ratios => ({...previous_sources_aspect_ratios, [target_sources[i]["unique_identifier"]]: (props.sources_image_data[target_sources[i]["unique_identifier"]]["width"] / (props.sources_image_data[target_sources[i]["unique_identifier"]]["height"] !== 0 ? props.sources_image_data[target_sources[i]["unique_identifier"]]["height"] : 1.8))}));
            }
        }
    }

    useEffect(() => {
        
        if(sources_refs.current !== {} && Object.keys(props.sources_image_data).length !== 0){
            
            draw_sources_thumbnails("visible");
            draw_sources_thumbnails("hidden");
        }

    }, [props.sources_image_data, visible_sources, hidden_sources, sources_refs.current, active_tab]);

    function determine_position_and_height() {
        
        if(is_mobile && (root_dimensions.height !== 0)){
            
            props.change_sources_menu_style({
                //maxHeight: parseInt(root_dimensions.height) + "px",
                visibility: props.sources_menu_style["visibility"],
                position: "fixed",
                top: "48%",
                transform: "translateY(-48%)",
                opacity: props.sources_menu_style["opacity"], 
                transition: props.sources_menu_style["transition"]
            });
        }

        else if(!is_mobile){

            let left_reference_x = ReactDOM.findDOMNode(props.sources_menu_ref.current).getBoundingClientRect().x;
            let left_reference_width = ReactDOM.findDOMNode(props.sources_menu_ref.current).getBoundingClientRect().width
            let left_current_width = ReactDOM.findDOMNode(aux_ref.current).getBoundingClientRect().width;
            
            let left = ((left_reference_x + (left_reference_width / 2))) - (left_current_width / 2) + "px";
            
            if(left !== props.sources_menu_style["left"]){
                props.change_sources_menu_style({
                    visibility: props.sources_menu_style["visibility"], 
                    opacity: props.sources_menu_style["opacity"], 
                    left: left, 
                    top: props.sources_menu_style["top"],
                    position: "absolute",
                    transition: props.sources_menu_style["transition"]
                });
            }
        }
    }
    
    useEffect(() => {
        if(is_mobile)
            determine_position_and_height();
    }, is_mobile ? [active_tab] : null);

    useEffect(() => {
        determine_position_and_height();
    }, is_mobile ? [root_dimensions] : null);

    function tab_props(index) {
        return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handle_tab_change = (e, new_tab) => {
        
        set_active_tab(new_tab);
    }

    const add_new_source_ref = (ref, unique_identifier) => {
        
        if(!(unique_identifier in sources_refs.current))
            sources_refs.current[unique_identifier] = ref;
    }

    const handle_click = (action, source) => {

        if(props.sources_menu_style["visibility"] === "hidden") return;

        if(action === "edit"){

            //props.close_sources_menu();

            window.dispatchEvent(new Event("info_or_fullscreen_div_status_should_disappear"));
            window.dispatchEvent(new Event("add_source_menu_should_disappear"));
            window.dispatchEvent(new Event("all_menus_should_disappear"));

            localStorage.setItem("header_menus_visibility", "hidden");
            window.dispatchEvent(new Event("header_menus_visibility_change"));

            localStorage.setItem("source_to_edit", JSON.stringify(source));
            window.dispatchEvent(new Event("edit_source_menu_should_appear"));
        }

        else {

            if(action === "delete"){
                
                // delete the chosen source from the database
                const payload = new FormData();
                payload.set("unique_identifier", source.unique_identifier);
                payload.set("delete_links", 1);
                deleteSource(payload);
                
                set_source_button_loading(source.unique_identifier);
                
                setTimeout(() => {

                    set_source_button_loading(null);

                    // remove this source from the visible list
                    var visible_sources_aux = visible_sources.slice().filter((item) => item.unique_identifier !== source.unique_identifier);
                    set_visible_sources(visible_sources_aux.slice());

                    // remove this source from the hidden list
                    var hidden_sources_aux = hidden_sources.slice().filter((item) => item.unique_identifier !== source.unique_identifier);
                    set_hidden_sources(hidden_sources_aux.slice());

                    localStorage.setItem("visible_sources", JSON.stringify(visible_sources_aux));
                    localStorage.setItem("hidden_sources", JSON.stringify(hidden_sources_aux));
                    window.dispatchEvent(new Event("sources_change"));
                    
                }, 2000);

            }

            else if(action === "show"){
                
                // add this source to the visible list
                var visible_sources_aux = visible_sources.slice();
                visible_sources_aux.push(source);
                set_visible_sources(visible_sources_aux.slice());

                // remove this source from the hidden list
                var hidden_sources_aux = hidden_sources.slice().filter((item) => item.unique_identifier !== source.unique_identifier);
                var hidden_sources_aux = (hidden_sources_aux === null || hidden_sources_aux === undefined) ? [] : hidden_sources_aux;
                set_hidden_sources(hidden_sources_aux.slice());
            }

            else if(action === "hide" && hidden_sources.filter((item) => item.unique_identifier === source.unique_identifier).length === 0){
                
                // add this source to the hidden list
                var hidden_sources_aux = hidden_sources.slice();
                hidden_sources_aux.push(source);
                set_hidden_sources(hidden_sources_aux.slice());
                
                // remove this source from the visible list
                var visible_sources_aux = visible_sources.slice().filter((item) => item.unique_identifier !== source.unique_identifier);
                var visible_sources_aux = (visible_sources_aux === null || visible_sources_aux === undefined) ? [] : visible_sources_aux;
                set_visible_sources(visible_sources_aux.slice());
            }

            // save everything
            if(action !== "delete"){
            
                localStorage.setItem("visible_sources", JSON.stringify(visible_sources_aux));
                localStorage.setItem("hidden_sources", JSON.stringify(hidden_sources_aux));
                window.dispatchEvent(new Event("sources_change"));
            }
        }
    }

    const determine_button_text_color = (item_index) => {
        
        return((parseInt(item_index.split("_")[1]) === (json_data.buttons.length - 1)) ? "rgba(242, 168, 168, 1.0)" : "rgba(255, 255, 255, 1.0)");
    }

    const determine_button_background_color = (item, item_index) => {
        
        if(hover_state === item_index)
            return((parseInt(item_index.split("_")[1]) === json_data.buttons.length - 1) ? "rgba(155, 75, 70, 1.0)" : (ai_sources.includes(item.unique_identifier) ? ai_colour.replace("1)", "0.85)") : "rgba(255, 255, 255, 0.13)"));
        
        else if(hover_state === -1 || hover_state !== item_index)
            return((parseInt(item_index.split("_")[1]) === json_data.buttons.length - 1) ? "rgba(145, 75, 70, 1.0)" : (ai_sources.includes(item.unique_identifier) ? ai_colour : "rgba(255, 255, 255, 0.1)"));
    }

    const determine_settings_button_icon_url = (item) => {

        //return(item.icon);
        return(null);
    }

    return (
        <div
            className = {is_mobile ? styles.super_root : null} 
            ref = {is_mobile ? root_ref : null}
            style = {is_mobile ? props.sources_menu_style : null}>

                <ClickAwayListener>
                    <div 
                        onScroll = {() => props.close_sources_menu()}
                        //onMouseLeave = {() => props.close_sources_menu()}
                        className = {styles.root}
                        style = {!is_mobile ? props.sources_menu_style : null}>

                        <div 
                            className = {styles.root_inner} 
                            ref = {aux_ref}
                            style = {{
                                backgroundColor: (theme === "Light" ? "rgba(245, 245, 245, 1.0)" : "var(--colour_one_dark_mode)"),
                                border: (theme === "Light" ? "0.020in solid var(--colour_two)" : "0.020in solid rgba(80, 80, 80, 0.55)"),
                                boxShadow: (theme === "Light" ? "rgba(100, 100, 100, 0.35) 0px 0px 10px" : "rgba(10, 15, 21, 0.75) 0px 8px 24px"),
                            }}>

                            <div className = {styles.hover_square} />

                            <div 
                                className = {styles.close_button_div}
                                style = {{
                                    display: (is_mobile ? "" : "none"),
                                    backgroundColor: (theme === "Light" ? "var(--colour_one)" : "rgba(190, 190, 190, 1.0)")
                                }}
                                onClick = {() => window.dispatchEvent(new Event("all_menus_should_disappear"))}
                                >
                                <div className = {styles.vertical_align}>
                                    <div 
                                        className = {styles.close_button} 
                                        style = {{
                                            backgroundImage: (theme === "Light" ? "url(\"/media/icons/close_white_2.png\")" : "url(\"/media/icons/close_4.png\")")
                                        }}/>
                                </div>
                            </div>

                            <div className = {styles.top_bar_div}>
                                <div 
                                    className = {styles.top_bar}
                                    style = {{
                                        opacity: "1.0",
                                        backgroundImage: "url(\"/media/images/menu_pattern_THEME_mode.jpg\")".replace("THEME", theme.toLowerCase()),
                                    }}/>
                                <div 
                                    className = {styles.top_bar_fade}
                                    style = {{
                                        opacity: "1.0",
                                        backgroundImage: (theme === "Light" ? "linear-gradient(to bottom, rgba(235,235,235,0.55), rgba(245, 245, 245, 1.0))" : "linear-gradient(to bottom, rgba(20, 25, 32, 0.6), var(--colour_one_dark_mode))")
                                    }}/>

                                <div className = {styles.text_super_div}>
                                    <div className = {styles.top_text_div} >
                                        <div 
                                            className = {styles.top_text}
                                            style = {{
                                                color: (theme === "Light" ? "var(--colour_one)" : "rgba(230, 230, 230, 1.0)")
                                            }}
                                            >{json_data.top_text.toUpperCase()}</div>
                                    </div>
                                    <div className = {styles.secondary_text_div}>
                                        <div 
                                            className = {styles.secondary_text}
                                            style = {{
                                                color: (theme === "Light" ? "var(--secondary_text_colour)" : "rgba(230, 230, 230, 0.75)")
                                            }}>{json_data.secondary_text.replaceAll("-", "‑")}</div>
                                    </div>
                                </div>
                            </div>

                            <Box sx={{ width: '100%', position: "relative", marginTop: "-10px" }}>
                                <Box sx={{ borderBottom: 1, borderColor: (theme === "Light" ? "rgba(3, 52, 77, 0.25)" : "rgba(150, 150, 150, 0.25)") }}>
                                    <Tabs 
                                        value = {active_tab} 
                                        onChange = {handle_tab_change} 
                                        aria-label = "basic tabs example"
                                        TabIndicatorProps = {{ style: {  height: "1px", background: (theme === "Light" ? "rgba(3, 52, 77, 1.0)" : "rgba(200, 200, 200, 1.0)") } }}>
                                        {json_data.tabs.map((tab) => (
                                            <Tab 
                                                key = {json_data.tabs.indexOf(tab)}
                                                style = {{
                                                    width: (100 / json_data.tabs.length) + "%", 
                                                    maxWidth: (100 / json_data.tabs.length) + "%", 
                                                    color: (theme === "Light" ? "var(--colour_one)" : (active_tab === json_data.tabs.indexOf(tab) ? "rgba(200, 200, 200, 1.0)" : "rgba(150, 150, 150, 1.0)")),
                                                    fontWeight: (active_tab === json_data.tabs.indexOf(tab) ? "bold" : "lighter"),
                                                }} 
                                                label = {tab + " (" + (json_data.tabs.indexOf(tab) === 0 ? (visible_sources !== null ? visible_sources.length : "-") : (hidden_sources !== null ? hidden_sources.length : "-")) + ")"} {...tab_props(json_data.tabs.indexOf(tab))} />
                                        ))}
                                    </Tabs>
                                </Box>
                                
                                <div 
                                    className = {styles.panel_root}
                                    style = {{
                                        height: (active_tab === 0 ? visible_sources_div_dimensions : hidden_sources_div_dimensions).height + 10 + "px",
                                    }}>
                                    {json_data.tabs.map((tab) => (
                                        <div
                                            key = {json_data.tabs.indexOf(tab)}
                                            role = "tabpanel"
                                            id = {`simple-tabpanel-${json_data.tabs.indexOf(tab)}`}
                                            aria-labelledby = {`simple-tab-${json_data.tabs.indexOf(tab)}`}
                                            className = {styles.tab_root}
                                            style = {{
                                                //backgroundColor: (theme === "Light" ? "white" : "var(--colour_one_dark_mode)"),
                                                visibility: (active_tab === json_data.tabs.indexOf(tab) ? "visible" : "hidden"),
                                                opacity: (active_tab === json_data.tabs.indexOf(tab) ? "1.0" : "0.0"),
                                            }}>
                                            <div className = {styles.tab_root_inner}>

                                                {(json_data.tabs.indexOf(tab) === 0 ? visible_sources : hidden_sources) === null || (json_data.tabs.indexOf(tab) === 0 ? visible_sources : hidden_sources).length === 0 ? 
                                                    <div 
                                                        className = {styles.empty_tab_div}
                                                        style = {{
                                                            color: (theme === "Light" ? "var(--colour_one)" : "white"),
                                                        }}>
                                                            {(visible_sources.length === 0 && hidden_sources.length === 0) ? json_data.no_sources_empty_text : (json_data.tabs.indexOf(tab) === 0 ? json_data.visible_tab_empty_text : json_data.hidden_tab_empty_text)}
                                                    </div> 
                                                : <></>}
                                                
                                                <div 
                                                    ref = {json_data.tabs.indexOf(tab) === 0 ? visible_sources_div_ref : hidden_sources_div_ref}
                                                    className = {styles.sources_div}
                                                    style = {{
                                                        overflowY: (((json_data.tabs.indexOf(tab) === 0 ? visible_sources : hidden_sources) !== null && (json_data.tabs.indexOf(tab) === 0 ? visible_sources : hidden_sources).length > 4 || is_mobile) ? "scroll" : "hidden"),
                                                        maskImage: (((json_data.tabs.indexOf(tab) === 0 ? visible_sources : hidden_sources) !== null && (json_data.tabs.indexOf(tab) === 0 ? visible_sources : hidden_sources).length > 4 || is_mobile) ? "linear-gradient(to bottom, transparent 0%, black 10px, black calc(100% - 10px), transparent 100%)" : ""),
                                                        maxHeight: (is_mobile ? (window_width < 400 ? "200px" : "275px") : "318px"),
                                                    }}>
                                                    {(json_data.tabs.indexOf(tab) === 0 ? visible_sources : hidden_sources) !== null && (json_data.tabs.indexOf(tab) === 0 ? visible_sources : hidden_sources).length !== 0 ? 
                                                        (json_data.tabs.indexOf(tab) === 0 ? visible_sources : hidden_sources).map((item, index, list) => (
                                                            <div 
                                                                key = {index}
                                                                className = {styles.source_div}
                                                                style = {{
                                                                    border: "2px solid " + (ai_sources.includes(item.unique_identifier) ? alternative_ai_colour : theme === "Light" ? "rgba(150, 150, 150, 1.0)" : "rgba(50, 50, 50, 1.0)"),
                                                                    flexBasis: "48%",
                                                                    //backgroundColor: (ai_sources.includes(item.unique_identifier) ? ai_colour : (theme === "Light" ? "var(--colour_five)" : "rgba(255, 255, 255, 0.05)"))
                                                                }}>
                                                                
                                                                <div 
                                                                    ref = {thumbnail_parent_ref}
                                                                    className = {styles.source_thumbnail_main_div}
                                                                    style = {{
                                                                        overflow: ((Object.keys(props.sources_image_data).length !== 0 && (item.unique_identifier in props.sources_image_data) && props.sources_image_data[item.unique_identifier]["image_data"] === null) ? "hidden" : ""),
                                                                    }}>

                                                                    {/* { ai_sources.includes(item.unique_identifier) ? <AIIndicator anomaly_detected = {"none"}/> : <></> } */}

                                                                    <canvas 
                                                                        className = {styles.source_thumbnail}
                                                                        ref = {(ref) => ref ? (add_new_source_ref(ref, item.unique_identifier)) : null}
                                                                        height = {thumbnail_parent_ref.current !== null ? ReactDOM.findDOMNode(thumbnail_parent_ref.current).getBoundingClientRect().height * 1.0 : "0"}
                                                                        //width = {thumbnail_parent_ref.current !== null ? ReactDOM.findDOMNode(thumbnail_parent_ref.current).getBoundingClientRect().width * 1.0 : "0"}
                                                                        style = {{
                                                                            backgroundColor: (Object.keys(props.sources_image_data).length !== 0 && (item.unique_identifier in props.sources_image_data) && props.sources_image_data[item.unique_identifier]["image_data"] === null ? (theme === "Light" ? "rgba(180, 180, 180, 1.0)" : "rgba(35, 35, 35, 1.0)") : "black"),
                                                                        }} />

                                                                    <div className = {styles.circular_progress_div}>
                                                                        <CircularProgress 
                                                                            size = {25}
                                                                            style = {{
                                                                                color: (theme === "Light" ? "white" : "rgba(120, 120, 120, 1.0)"),
                                                                                opacity: (Object.keys(props.sources_image_data).length !== 0 && (item.unique_identifier in props.sources_image_data) && props.sources_image_data[item.unique_identifier]["image_data"] === null ? "1.0" : "0.0")
                                                                            }} />
                                                                    </div>

                                                                    <div 
                                                                        className = {(theme === "Light" ? styles.skeleton_light_mode : styles.skeleton_dark_mode)}
                                                                        style = {{
                                                                            display: (Object.keys(props.sources_image_data).length !== 0 && (item.unique_identifier in props.sources_image_data) && props.sources_image_data[item.unique_identifier]["image_data"] === null ? "" : "none"),
                                                                        }} />
                                                                    <div 
                                                                        className = {styles.source_thumbnail_fade} 
                                                                        style = {{
                                                                            background: "linear-gradient(to bottom, rgba(60, 60, 60, 0.3), " + (ai_sources.includes(item.unique_identifier) ? (alternative_ai_colour.replace("1)", "0.85)") + ", " + alternative_ai_colour) : theme === "Light" ? "rgba(22, 27, 34, 0.55), rgba(22, 27, 34, 0.85)" : "rgba(22, 27, 34, 0.85), rgba(22, 27, 34, 1.0)") + ")",
                                                                        }}
                                                                        />
                                                                </div>
                                                                
                                                                <div className = {styles.bottom_div}>
                                                                    <div className = {styles.bottom_inner_div}>
                                                                        <div className = {styles.source_name_url_div}>

                                                                            <div 
                                                                                className = {styles.source_name}
                                                                                style = {{
                                                                                    color: "white",
                                                                                }}>
                                                                                {item.name.toUpperCase()}
                                                                            </div>
                                                                            <div 
                                                                                className = {styles.separator}
                                                                                style = {{
                                                                                    color: "rgba(230, 230, 230, 1.0)",
                                                                                }}>{divider}
                                                                            </div>
                                                                            <div 
                                                                                className = {styles.source_url}
                                                                                style = {{
                                                                                    color: "white",
                                                                                }}>
                                                                                {item.url}
                                                                            </div>

                                                                        </div>

                                                                        <div className = {styles.buttons_div}>
                                                                            {json_data.buttons.map((button) => (
                                                                                <div 
                                                                                    className = {styles.button_div}
                                                                                    style = {{
                                                                                        cursor: (props.sources_menu_style["visibility"] === "hidden" ? "normal" : "pointer"),
                                                                                    }}
                                                                                    key = {index + "_" + json_data.buttons.indexOf(button)}
                                                                                    onClick = {() => handle_click((json_data.buttons.indexOf(button) === 0 && json_data.tabs.indexOf(tab) === 1 ? button.alternative_title : button.title).toLowerCase(), item)}
                                                                                    onMouseEnter = {() => set_hover_state(index + "_" + json_data.buttons.indexOf(button))}
                                                                                    onMouseLeave = {() => set_hover_state(-1)}>
                                                                                    <Button 
                                                                                        orientation = {"vertical"}
                                                                                        text = {json_data.buttons.indexOf(button) === 0 && json_data.tabs.indexOf(tab) === 1 ? button.alternative_title : button.title}
                                                                                        text_size = {"11.5px"}
                                                                                        text_family = {"semibold"}
                                                                                        text_color = {determine_button_text_color(index + "_" + json_data.buttons.indexOf(button))}
                                                                                        background_color = {determine_button_background_color(item, index + "_" + json_data.buttons.indexOf(button))}
                                                                                        icon_url = {determine_settings_button_icon_url(index + "_" + json_data.buttons.indexOf(button))}
                                                                                        icon_size = {"18px"}
                                                                                        is_loading = {source_button_loading === item.unique_identifier && (json_data.buttons.indexOf(button) === (json_data.buttons.length - 1)) ? true : false}
                                                                                    />
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    
                                                                    </div>
                                                                
                                                                </div>

                                                            </div>
                                                        ))
                                                    
                                                    : <></>}
                                                </div>

                                            </div>
                                            
                                        </div>
                                    ))}
                                </div>

                            </Box>
                        
                        </div>
                    
                    </div>
                </ClickAwayListener>
        </div>
    );
}

export default SourcesMenu;