import React, { useEffect } from "react";
import { useGetCheckTokenQuery } from "../../services/AuthService";
import { useDispatch } from "react-redux";
import { logOutSuccess } from "../../store/slices/userSlice";
import { addErrorFlashMessage } from "../../store/slices/flashMessagesSlice";

function CheckToken(props) {
  const dispatch = useDispatch();

  const { data, isSuccess, isError } = useGetCheckTokenQuery({
    refetchOnMountOrArgChange: true,
    skip: false,
  });

  useEffect(() => {
    if (isError) {
      dispatch(logOutSuccess());
    } else if (isSuccess && data) {
      if (!data.is_active) {
        dispatch(logOutSuccess());
        dispatch(addErrorFlashMessage("Session expired. Please log in again"));
      }
    }
  }, [data, dispatch, isError, isSuccess]);

  return <></>;
}

export default CheckToken;
