import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

function RequireAuth({ children }) {
  const isAuthenticated = useSelector((state) => state.user.loggedIn);
  const location = useLocation();

  return isAuthenticated ? (
    children
  ) : (
    <Navigate
      to={"/"}
      replace={true}
      state={{
        path: location.pathname,
        message: "You do not have permission to access this resource",
        messageType: "Error",
      }}
    />
  );
}

export default RequireAuth;
