import styled from "@emotion/styled";
import BaseDivContainer from "../Divs/BaseDivContainer";

const RightSideContainer = styled(BaseDivContainer)`
  height: 100%;
  flex: 1;
  margin-left: auto;
  margin-right: 0;
  align: right;
  display: flex;
  flex-direction: row;
  //border: 1px solid yellow;
`;

export default RightSideContainer;
