import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  flashMessages: [],
  flashErrorMessages: [],
};

const flashMessagesSlice = createSlice({
  name: "flashMessages",
  initialState,
  reducers: {
    addFlashMessage(state, action) {
      state.flashMessages.push(action.payload);
    },
    addErrorFlashMessage(state, action) {
      state.flashErrorMessages.push(action.payload);
    },
  },
});

export const { addFlashMessage, addErrorFlashMessage } =
  flashMessagesSlice.actions;
export default flashMessagesSlice.reducer;
