import { useState } from "react";
import React, { useEffect } from "react";
import Select from '@mui/material/Select';
import useMeasure from "react-use-measure";
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Button from "../../reusable_components/Button.js";
import styles from "../../../styles/edit_source_menu.module.scss";
import json_edit_source_menu_en from '../../../jsons/en/edit_source_menu_en.json';
import json_edit_source_menu_pt from '../../../jsons/pt/edit_source_menu_pt.json';
import {
    useGetAvailableSourceGroupsQuery,
    usePostLinkSourceToUserByUIDMutation,
    usePostUpdateSourceMutation,
    useDeleteLinkSourceToUserByUIDMutation,
} from "../../../services/SourceService";

const EditSourceMenu = (props) => {

    // variables and states
    const [is_loading, set_is_loading] = useState(false);
    const [ai_sources, set_ai_sources] = useState(localStorage.getItem("ai_sources") !== null ? JSON.parse(localStorage.getItem("ai_sources")) : []);
    const [ai_button_state, set_ai_button_state] = useState(props.source_to_edit !== null && ai_sources.includes(props.source_to_edit["unique_identifier"]) ? true : false);

    const [language, set_language] = useState(localStorage.getItem("language"));
    const json_data = (language === "English" ? json_edit_source_menu_en : json_edit_source_menu_pt);
    const [theme, set_theme] = useState(localStorage.getItem("theme"));
    const [header_menus_visibility, set_header_menus_visibility] = useState(localStorage.getItem("header_menus_visibility"));
    const [hidden_sources, set_hidden_sources] = useState(localStorage.getItem("hidden_sources") !== null ? JSON.parse(localStorage.getItem("hidden_sources")) : []);
    const [visible_sources, set_visible_sources] = useState(localStorage.getItem("visible_sources") === null ? [] : JSON.parse(localStorage.getItem("visible_sources")).filter((item) => !hidden_sources.includes(item.unique_identifier)));
    const [initial_setup_is_done, set_initial_setup_is_done] = useState(false);

    const [root_ref, root_dimensions] = useMeasure();
    const [window_width, set_window_width] = useState(window.innerWidth);
    const [is_mobile, set_is_mobile] = useState(window.innerWidth < parseInt(localStorage.getItem("desktop_version_min_width")));
    const [navbar_behaviour, set_navbar_behaviour] = useState(localStorage.getItem("navbar_behaviour"));

    // name textfield states
    const [name, set_name] = useState("");
    const [name_helper_text, set_name_helper_text] = useState("");

    // url textfield state
    const [url, set_url] = useState();
    const [url_helper_text, set_url_helper_text] = useState("");

    // protocol textfield state
    const [protocol, set_protocol] = useState("");

    // source_group textfield state
    const [source_group, set_source_group] = useState("");

    useEffect(() => {
        
        if(!initial_setup_is_done && props.source_to_edit !== null){

            set_name(props.source_to_edit["name"]);
            set_url(props.source_to_edit["url"]);
            set_protocol(props.source_to_edit["protocol"]);
            set_source_group(props.source_to_edit["sourcegroup"]["name"]);
            
            set_initial_setup_is_done(true);
        }
    });

    // add event listeners
    useEffect(() => {

        // listen for theme changes
        function theme_change() {
            set_theme(localStorage.getItem("theme"));
        }
        window.addEventListener('theme_change', theme_change);

        // listen for language changes
        function language_change() {
            set_language(localStorage.getItem("language"));
        }
        window.addEventListener('language_change', language_change);

        // listen for window resizes
        function resize() { 
            set_window_width(window.innerWidth); 
        }
        window.addEventListener('resize', resize);

        // listen for header menus visibility changes
        function header_menus_visibility_change() {
            set_header_menus_visibility(localStorage.getItem("header_menus_visibility"));
        }
        window.addEventListener('header_menus_visibility_change', header_menus_visibility_change);

        // listen for changes in the "add source" menu
        function edit_source_menu_click() {
            handle_close();
            set_header_menus_visibility("visible");
        }
        window.addEventListener('edit_source_menu_click', edit_source_menu_click);

        // do some cleanup (i.e., remove the event listeners if this component ends up being unmounted)
        return(() => {
            window.removeEventListener('theme_change', theme_change);
            window.removeEventListener('language_change', language_change);
            window.removeEventListener('resize', resize);
            window.removeEventListener('header_menus_visibility_change', header_menus_visibility_change);
            window.removeEventListener('edit_source_menu_click', edit_source_menu_click);
            window.removeEventListener('edit_source_menu_should_disappear', edit_source_menu_should_disappear);
        })

    }, []);

    // (has to be placed outside of the useEffect due to state updates) listen for logo clicks
    function edit_source_menu_should_disappear() {
        handle_close();
    }
    window.addEventListener('edit_source_menu_should_disappear', edit_source_menu_should_disappear);

    useEffect(() => {

        set_navbar_behaviour(localStorage.getItem("navbar_behaviour"));
    
      // eslint-disable-next-line
    }, [localStorage.getItem("navbar_behaviour")]);

    useEffect(() => {

        set_is_mobile(window_width < parseInt(localStorage.getItem("desktop_version_min_width")));
    
    }, [window_width]);

    useEffect(() => {

        set_ai_button_state(props.source_to_edit !== null && ai_sources.includes(props.source_to_edit["unique_identifier"]) ? true : false);
    
    }, [props.source_to_edit]);

    const base_input_props_style = { 
        color: (theme === "Light" ? "var(--colour_one)" : "rgba(230, 230, 230, 1.0)"),
        borderRadius: "8px",
        fontSize: 13.5 ,
        border: (theme === "Light" ? "" : "0.020in solid rgba(80, 80, 80, 0.5)"), 
    };

    const base_input_props_selector_style = { 
        color: (theme === "Light" ? "var(--colour_one)" : "rgba(230, 230, 230, 1.0)"),
        borderRadius: "8px",
        fontSize: 13.5 ,
    };

    const base_input_label_style = { 
        color: (theme === "Light" ? "var(--colour_one)" : "rgba(200, 200, 200, 1.0)"), 
        fontSize: 13.5 ,
        backgroundColor: (theme === "Light" ? "" : "var(--colour_one_dark_mode)"),
        paddingLeft: (theme === "Light" ? "" : "5px"),
        paddingRight: (theme === "Light" ? "" : "5px"),
    };

    const [
        postUpdateSource,
        {
            data: postUpdateSourceData,
            //isLoading: postUpdateSourceIsLoading,
            isSuccess: postUpdateSourceIsSuccess,
            //isError: postUpdateSourceIsError,
        },
    ] = usePostUpdateSourceMutation();
    
    
    const [
    deleteLinkSourceToUserByUID,
    ] = useDeleteLinkSourceToUserByUIDMutation();
    
    
    function unlinkThisSource(uid) {
        
        const payload = new FormData();
        payload.set("unique_identifier", uid);
        deleteLinkSourceToUserByUID(payload);
    }

    const {
        data: getAvailableSourceGroupsData,
        isSuccess: getAvailableSourceGroupsIsSuccess,
        //isError: getAvailableSourceGroupsIsError,
        //isLoading: getAvailableSourceGroupsIsLoading,
    } = useGetAvailableSourceGroupsQuery();
    
    const [
        postLinkSourceToUserByUID,
    ] = usePostLinkSourceToUserByUIDMutation();
    
    const linkThisSource = (unique) => {
    
        const payload = new FormData();
        payload.set("unique_identifier", unique);
        postLinkSourceToUserByUID(payload);
    };

    const onSubmit = () => {

        const payload = new FormData();
        payload.set("name", name);
        
        if (url !== props.source_to_edit['url'])
            unlinkThisSource(props.source_to_edit['unique_identifier'])
        
        payload.set("url", url);
        payload.set("group", source_group);
        payload.set("protocol", protocol);
        payload.set("unique_identifier", props.source_to_edit["unique_identifier"]);
        
        postUpdateSource(payload);
        
        if (url !== props.source_to_edit['url'])
            linkThisSource(props.source_to_edit['unique_identifier'])
    };

    function determine_position_and_height() {
        
        if(!("maxHeight" in props.edit_source_menu_style) && (root_dimensions.height !== 0)){

            props.change_edit_source_menu_style({
                maxHeight: root_dimensions.height + "px",
                visibility: props.edit_source_menu_style["visibility"],
                position: "fixed",
                top: "48%",
                transform: "translateY(-48%)",
                opacity: props.edit_source_menu_style["opacity"], 
                transition: props.edit_source_menu_style["transition"]
            });
        }
    }

    useEffect(() => {
        determine_position_and_height();
    }, [root_dimensions.height]);

    useEffect(() => {
        if (postUpdateSourceIsSuccess && postUpdateSourceData) {
            setTimeout(() => {

                // update the source's values (in React)
                if(visible_sources.filter((item) => item.unique_identifier === props.source_to_edit["unique_identifier"]).length !== 0){
                    var sources_aux = visible_sources.slice()
                    var local_storage_item = "visible_sources";
                }
                    
                else{
                    var sources_aux = hidden_sources.slice()
                    var local_storage_item = "hidden_sources";
                }
                
                // change the source's values, if needed
                let element_index = -1;
                for(let i = 0; i < sources_aux.length; i++){
                    if(sources_aux[i].unique_identifier === props.source_to_edit["unique_identifier"]){
                        element_index = i;
                        break;
                    }
                }

                sources_aux[element_index]["name"] = name;
                sources_aux[element_index]["url"] = url;
                sources_aux[element_index]["group"] = source_group;
                sources_aux[element_index]["protocol"] = protocol;
                sources_aux[element_index]["unique_indentifier"] = props.source_to_edit["unique_identifier"];

                // either save this source in the AI list or not
                let ai_sources_aux = localStorage.getItem("ai_sources") !== null ? JSON.parse(localStorage.getItem("ai_sources")) : [];
                
                if(ai_button_state){
                        
                    if(!ai_sources_aux.includes(props.source_to_edit["unique_identifier"]))
                        ai_sources_aux.push(props.source_to_edit["unique_identifier"]);
                }

                else{
                    if(ai_sources_aux.includes(props.source_to_edit["unique_identifier"]))
                        ai_sources_aux = ai_sources_aux.filter((item) => item !== props.source_to_edit["unique_identifier"]);
                }

                // save everything
                localStorage.setItem("ai_sources", JSON.stringify(ai_sources_aux));
                window.dispatchEvent(new Event("ai_sources_change"));
                localStorage.setItem(local_storage_item, JSON.stringify(sources_aux));
                window.dispatchEvent(new Event("sources_change"));

                set_is_loading(false);
                props.close_edit_source_menu()

            }, 1000);
        }
    
    // eslint-disable-next-line
    });

    const handle_close = () => {
        
        if(props.edit_source_menu_style["visibility"] === "visible" && (is_mobile || header_menus_visibility === "hidden"))
            props.close_edit_source_menu()
    }

    const handle_done = () => {
        
        // invalid input for the name field (too many characters)
        if(name.length > 32)
            set_name_helper_text(json_data.name_textfield_too_long_error_text);

        // invalid input for the url field (invalid characters)
        else if(name === "" || name.match("^[A-Za-z0-9]+$") === null)
            set_name_helper_text(json_data.name_textfield_invalid_characters_error_text);

        // valid input for the name field
        else
            set_name_helper_text("");
        
        // invalid input for the URL field (no characters)
        if(url.length === 0)
            set_url_helper_text(json_data.url_textfield_no_characters_error_text);
        
        else{

            // valid input for the URL field
            set_url_helper_text("");

            set_is_loading(true);

            // actually submit the new source's details
            onSubmit()
        }
    }

    const handle_click = (action) => {

        if(action === "ai"){
            set_ai_button_state(!ai_button_state);
        }

        else if(action === "done") handle_done();
    }

    const handle_key_down = (e) => {
        if(e["code"] === "Enter")
            handle_done();
    }

    return (
        <div
        className = {styles.super_root} 
        ref = {root_ref}
        style = {props.edit_source_menu_style}>

            <div 
                className = {styles.root}
                style = {{
                    marginTop: (is_mobile ? "" : (navbar_behaviour === "Expanded" ? "var(--navbar_height)" : "var(--collapsed_navbar_height)")),
                    width: (is_mobile ? "" : "500px"),
                    backgroundColor: (theme === "Light" ? "rgba(245, 245, 245, 1.0)" : "var(--colour_one_dark_mode)"),
                    border: (theme === "Light" ? "0.020in solid var(--colour_two)" : "0.020in solid rgba(80, 80, 80, 0.55)"),
                    boxShadow: (theme === "Light" ? "rgba(100, 100, 100, 0.35) 0px 0px 10px" : "rgba(10, 15, 21, 0.75) 0px 8px 24px"),
                }}
                onKeyDown = {handle_key_down}
                tabIndex = "0">
                
                <div 
                    className = {styles.close_button_div}
                    style = {{
                        backgroundColor: (theme === "Light" ? "var(--colour_one)" : "rgba(190, 190, 190, 1.0)")
                    }}
                    onClick = {() => handle_close()}>
                    <div className = {styles.vertical_align}>
                    <div 
                        className = {styles.close_button} 
                        style = {{
                            backgroundImage: (theme === "Light" ? "url(\"/media/icons/close_white_2.png\")" : "url(\"/media/icons/close_4.png\")")
                        }}/>
                    </div>
                </div>

                <div className = {styles.top_bar_div}>
                    <div 
                        className = {styles.top_bar}
                        style = {{
                            opacity: "1.0",
                            backgroundImage: "url(\"/media/images/menu_pattern_THEME_mode.jpg\")".replace("THEME", theme.toLowerCase()),
                        }}/>
                    <div 
                        className = {styles.top_bar_fade}
                        style = {{
                            opacity: "1.0",
                            backgroundImage: (theme === "Light" ? "linear-gradient(to bottom, rgba(235,235,235,0.55), rgba(245, 245, 245, 1.0))" : "linear-gradient(to bottom, rgba(20, 25, 32, 0.6), var(--colour_one_dark_mode))")
                        }}/>

                    <div className = {styles.text_super_div}>
                        <div className = {styles.top_text_div} >
                            <div 
                                className = {styles.top_text}
                                style = {{
                                    color: (theme === "Light" ? "var(--colour_one)" : "rgba(230, 230, 230, 1.0)")
                                }}
                                >{json_data.top_text.toUpperCase()}</div>
                        </div>
                        <div className = {styles.secondary_text_div}>
                            <div 
                                className = {styles.secondary_text}
                                style = {{
                                    color: (theme === "Light" ? "var(--secondary_text_colour)" : "rgba(230, 230, 230, 0.75)")
                                }}>{json_data.secondary_text.replaceAll("-", "‑")}</div>
                        </div>
                    </div>
                </div>

                <div className = {styles.text_field_div}>
                    <TextField 
                        onChange = {(e) => set_name(e.target.value)}
                        className = {styles.text_field} 
                        id = "outlined-basic" 
                        error = {name_helper_text !== "" ? true : false}
                        InputProps={{ style: base_input_props_style }}
                        InputLabelProps={{ style: base_input_label_style }}
                        helperText = {name_helper_text}
                        label = {json_data.name_textfield_hint}
                        value = {props.edit_source_menu_style["visibility"] === "visible" && name !== "" ? name : ""}
                        defaultValue = {name}
                        variant = "outlined" 
                        type = "text" />
                </div>
                <div className = {styles.text_field_div}>
                    <TextField 
                        onChange = {(e) => set_url(e.target.value)}
                        className = {styles.text_field} 
                        id = "outlined-basic" 
                        error = {url_helper_text !== "" ? true : false}
                        InputProps={{ style: base_input_props_style }}
                        InputLabelProps={{ style: base_input_label_style }}
                        helperText = {url_helper_text}
                        label = {json_data.url_textfield_hint} 
                        value = {props.edit_source_menu_style["visibility"] === "visible" && url !== "" ? url : ""}
                        defaultValue = {url}
                        variant = "outlined" 
                        type = "text" />
                </div>
                <div className = {styles.selector_div}>
                    <FormControl
                        fullWidth 
                        style = {{
                            border: (theme === "Light" ? "" : "0.020in solid rgba(80, 80, 80, 0.5)"), 
                            borderRadius: "8px"
                        }}>
                        <InputLabel inputlabelprops = {{ style: base_input_label_style }}>
                            <div style = {base_input_label_style}>
                                {json_data.protocol_selector_hint}
                            </div>
                        </InputLabel>
                        <Select 
                            onChange = {(e) => set_protocol(e.target.value)}
                            native defaultValue = {props.source_to_edit !== null ? props.source_to_edit["protocol"] : ""}
                            inputProps={{ style: base_input_props_selector_style }}
                            style = {{ borderRadius: "8px" }}
                            sx = {{
                                '& .MuiSvgIcon-root': {
                                    color: (theme === "Light" ? "var(--colour_one)" : "rgba(180, 180, 180, 1.0)"),
                                }
                            }}
                            id = "grouped-native-select"
                            label = {json_data.protocol_selector_hint.slice(0, -1)}
                            autoWidth>
                            {json_data.protocol_selector_options.map((option) => (
                                <option key = {json_data.protocol_selector_options.indexOf(option)} value = {option.name}>
                                    {option}
                                </option>
                            ))}
                        </Select>
                    </FormControl>
                </div>

                <div className = {styles.selector_div}>
                    <FormControl 
                        fullWidth 
                        style = {{
                            border: (theme === "Light" ? "" : "0.020in solid rgba(80, 80, 80, 0.5)"), 
                            borderRadius: "8px"
                        }} >
                        <InputLabel inputlabelprops = {{ style: base_input_label_style }}>
                            <div style = {base_input_label_style}>
                                {json_data.source_group_selector_hint}
                            </div>
                        </InputLabel>
                        <Select 
                            onChange = {(e) => set_source_group(e.target.value)}
                            native defaultValue = {props.source_to_edit !== null ? props.source_to_edit["sourcegroup"]["name"] : ""}
                            inputProps={{ style: base_input_props_selector_style }}
                            style = {{ borderRadius: "8px" }}
                            sx = {{
                                '& .MuiSvgIcon-root': {
                                    color: (theme === "Light" ? "var(--colour_one)" : "rgba(180, 180, 180, 1.0)"),
                                }
                            }}
                            id = "grouped-native-select"
                            label = {json_data.source_group_selector_hint.slice(0, -1)}
                            autoWidth>
                            {/*<option aria-label = {json_data.source_group_selector_hint} value = "" />*/}
                            {getAvailableSourceGroupsData ? 
                                getAvailableSourceGroupsData.map((group) => (
                                    <option key = {getAvailableSourceGroupsData.indexOf(group)} value = {group.name}>
                                        {group.name}
                                    </option>
                                )) : 
                                <option key = {0} value = {json_data.no_source_group_error_text}>
                                    {json_data.no_source_group_error_text}
                                </option>
                            }
                        </Select>
                    </FormControl>
                </div>

                <div className = {styles.bottom_buttons_div}>

                    <div 
                        className = {styles.button_div}
                        onClick = {() => handle_click("ai")}
                        >
                        <Button 
                            key = {1}
                            orientation = {"vertical"}
                            text = {ai_button_state ? json_data.ai_button_text_on : json_data.ai_button_text_off}
                            text_size = {"13.5px"}
                            text_family = {"semibold"}
                            text_color = {(ai_button_state ? "rgba(163, 194, 194, 1.0)" : (theme === "Light" ? "rgba(110, 110, 110, 1.0)" : "rgba(190, 190, 190, 1.0)"))}
                            background_color = {(ai_button_state ? "var(--ai_colour)" : "rgb(200, 200, 200, 0.35)")}
                            icon_url = {null}
                            icon_size = {null}
                        />
                    </div>

                    <div 
                        className = {styles.button_div}
                        onClick = {() => handle_click("done")}
                        >
                        <Button 
                            key = {1}
                            orientation = {"vertical"}
                            text = {json_data.done_button_text}
                            text_size = {"13.5px"}
                            text_family = {"semibold"}
                            text_color = {(theme === "Light" ? "var(--main_button_text_colour)" : "rgba(50, 50, 50, 1.0)")}
                            background_color = {(theme === "Light" ? "var(--colour_one)" : "rgba(230, 230, 230, 1.0)")}
                            icon_url = {null}
                            icon_size = {null}
                            is_loading = {is_loading}
                        />
                    </div>

                </div>
                
            </div>
        </div>
    );

}

export default EditSourceMenu;