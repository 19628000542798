// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pieChart_rootDiv__N27dh {\n  height: 100%;\n  width: 100%;\n}\n\n.pieChart_tooltipDiv__5o\\+gM {\n  display: flex;\n  flex-wrap: nowrap;\n  align-items: center;\n  gap: 3px;\n  padding: 7px;\n  padding-left: 10px;\n  padding-right: 10px;\n  border-radius: 6px;\n}\n\n.pieChart_tooltipPrefix__rlZ23 {\n  font-family: light;\n  font-size: 13px;\n}\n\n.pieChart_tooltipValue__KPSZD {\n  font-family: semibold;\n  font-size: 13px;\n}", "",{"version":3,"sources":["webpack://./src/components/sources/visualization/Visuals/PieChart/pieChart.module.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,WAAA;AACJ;;AAGA;EACI,aAAA;EACA,iBAAA;EACA,mBAAA;EACA,QAAA;EACA,YAAA;EACA,kBAAA;EACA,mBAAA;EACA,kBAAA;AAAJ;;AAGA;EACI,kBAAA;EACA,eAAA;AAAJ;;AAGA;EACI,qBAAA;EACA,eAAA;AAAJ","sourcesContent":[".rootDiv{\n    height: 100%;\n    width: 100%;\n    //border: 1px solid red;\n}\n\n.tooltipDiv{\n    display: flex;\n    flex-wrap: nowrap;\n    align-items: center;\n    gap: 3px;\n    padding: 7px;\n    padding-left: 10px;\n    padding-right: 10px;\n    border-radius: 6px;\n}\n\n.tooltipPrefix{\n    font-family: light;\n    font-size: 13px;\n}\n\n.tooltipValue{\n    font-family: semibold;\n    font-size: 13px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rootDiv": "pieChart_rootDiv__N27dh",
	"tooltipDiv": "pieChart_tooltipDiv__5o+gM",
	"tooltipPrefix": "pieChart_tooltipPrefix__rlZ23",
	"tooltipValue": "pieChart_tooltipValue__KPSZD"
};
export default ___CSS_LOADER_EXPORT___;
