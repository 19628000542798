import ReactDOM from 'react-dom';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import useMeasure from "react-use-measure";
import React, { useState, useEffect } from "react";
import Button from "../reusable_components/Button.js";
import ClickAwayListener from 'react-click-away-listener';
import styles from '../../styles/settings_menu.module.scss';
import json_settings_menu_en from '../../jsons/en/settings_menu_en.json';
import json_settings_menu_pt from '../../jsons/pt/settings_menu_pt.json';

const SettingsMenu = (props) => {

    // variables and states
    const language = localStorage.getItem("language");
    const json_data = (language === "English" ? json_settings_menu_en : json_settings_menu_pt);
    const aux_ref = React.createRef();
    const [theme, set_theme] = useState(localStorage.getItem("theme"));
    const [settings_values, set_settings_values] = useState({0: {}, 1: {}});
    const [settings_hover_state, set_settings_hover_state] = useState(null);
    const [first_render, set_first_render] = useState(true);
    const [active_tab, set_active_tab] = React.useState(0);
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [root_ref, root_dimensions] = useMeasure();
    const [window_width, set_window_width] = useState(window.innerWidth);
    const [is_mobile, set_is_mobile] = useState(window.innerWidth < parseInt(localStorage.getItem("desktop_version_min_width")));

    // add event listeners
    useEffect(() => {

        // listen for theme changes
        function theme_change() {
            set_theme(localStorage.getItem("theme"));
        }
        window.addEventListener('theme_change', theme_change);

        // listen for window resizes
        function resize() { 
            set_window_width(window.innerWidth); 
        }
        window.addEventListener('resize', resize);

        // listen for source grid span changes
        function source_grid_span_change() {

            let saved_span_sources = JSON.parse(localStorage.getItem("saved_span_sources"));
        
            function check_if_all_sources_have_the_same_grid_span(target_grid_span) {

                let all_sources_have_target_grid_span = true;
                for (const [key, value] of Object.entries(saved_span_sources)) {
                    if(value !== target_grid_span){
        
                        all_sources_have_target_grid_span = false;
                        break;
                    }
                }

                return(all_sources_have_target_grid_span);
            }

            let all_sources_have_grid_span_of_1 = check_if_all_sources_have_the_same_grid_span("1");
            let all_sources_have_grid_span_of_2 = check_if_all_sources_have_the_same_grid_span("2");

            if(localStorage.getItem(json_settings_menu_en.tabs[0].items[3].title.toLowerCase().replace(" (s)", "").replaceAll(" ", "_")) === json_settings_menu_en.tabs[0].items[3].options[1] && all_sources_have_grid_span_of_2){
                localStorage.setItem(json_settings_menu_en.tabs[0].items[3].title.toLowerCase().replace(" (s)", "").replaceAll(" ", "_"), json_settings_menu_en.tabs[0].items[3].options[0]);
                update_settings_values();
            }

            else if(localStorage.getItem(json_settings_menu_en.tabs[0].items[3].title.toLowerCase().replace(" (s)", "").replaceAll(" ", "_")) === json_settings_menu_en.tabs[0].items[3].options[0] && all_sources_have_grid_span_of_1){
                localStorage.setItem(json_settings_menu_en.tabs[0].items[3].title.toLowerCase().replace(" (s)", "").replaceAll(" ", "_"), json_settings_menu_en.tabs[0].items[3].options[1]);
                update_settings_values();
            }
        }
        window.addEventListener('source_grid_span_change', source_grid_span_change);

        set_first_render(false);

        // do some cleanup (i.e., remove the event listeners if this component ends up being unmounted)
        return(() => {
            window.removeEventListener('theme_change', theme_change);
            window.removeEventListener('resize', resize);
            window.removeEventListener('source_grid_span_change', source_grid_span_change);
        })

    }, []);

    useEffect(() => {

        set_is_mobile(window_width < parseInt(localStorage.getItem("desktop_version_min_width")));
    
    }, [window_width]);

    useEffect(() => {
        
        update_settings_values();
        window.dispatchEvent(new Event("source_grid_span_change"));
    
    // eslint-disable-next-line
    }, [localStorage.getItem("language"), first_render]);

    const update_settings_values = () => {

        let settings_values_aux = {}
    
        for(let i = 0; i < json_data.tabs.length; i++){

            settings_values_aux[i] = {};

            for(let j = 0; j < json_data.tabs[i].items.length; j++){

                let variable_name = json_settings_menu_en.tabs[i].items[j]["title"].toLowerCase().replace(" (s)", "").replaceAll(" ", "_")
                let value = null;

                if(localStorage.getItem(variable_name) !== null){
                    
                    for(let k = 0; k < json_settings_menu_en.tabs[i].items[j].options.length; k++){
                        if(json_settings_menu_en.tabs[i].items[j].options[k].text === localStorage.getItem(variable_name)){
                            value = k;
                            break;
                        }
                    }
                }

                else {
                    localStorage.setItem(variable_name, json_settings_menu_en.tabs[i].items[j].options[json_data.tabs[i].items[j]["default_option_index"]].text);
                    value = json_data.tabs[i].items[j]["default_option_index"];
                }

                settings_values_aux[i][j] = value;
            }
        }

        set_settings_values(settings_values_aux);
        forceUpdate();
    };

    function determine_position_and_height() {
        
        if(is_mobile && !("maxHeight" in props.settings_menu_style) && (root_dimensions.height !== 0)){

            props.change_settings_menu_style({
                maxHeight: root_dimensions.height + "px",
                visibility: props.settings_menu_style["visibility"],
                position: "fixed",
                top: "48%",
                transform: "translateY(-48%)",
                opacity: props.settings_menu_style["opacity"], 
                transition: props.settings_menu_style["transition"]
            });
        }

        else if(!is_mobile){

            let left_reference_x = ReactDOM.findDOMNode(props.settings_menu_ref.current).getBoundingClientRect().x;
            let left_reference_width = ReactDOM.findDOMNode(props.settings_menu_ref.current).getBoundingClientRect().width
            let left_current_width = ReactDOM.findDOMNode(aux_ref.current).getBoundingClientRect().width;
            
            let left = ((left_reference_x + (left_reference_width / 2))) - (left_current_width / 2) + "px";
            
            if(left !== props.settings_menu_style["left"]){
                props.change_settings_menu_style({
                    visibility: props.settings_menu_style["visibility"], 
                    opacity: props.settings_menu_style["opacity"], 
                    left: left, 
                    top: props.settings_menu_style["top"],
                    position: "absolute",
                    transition: props.settings_menu_style["transition"]
                });
            }
        }
    }
    
    useEffect(() => {
        if(is_mobile)
            determine_position_and_height();
    }, is_mobile ? [active_tab] : null);

    useEffect(() => {
        determine_position_and_height();
    }, is_mobile ? [root_dimensions.height] : null);

    const handle_changes = (target_button_index) => {

        if(props.settings_menu_style["visibility"] === "hidden") return;
        
        // language setting
        if(target_button_index.split("_").slice(0, 2).join("_") === "0_0"){
            localStorage.setItem(json_settings_menu_en.tabs[0].items[0].title.toLowerCase().replace(" (s)", "").replaceAll(" ", "_"), json_settings_menu_en.tabs[0].items[0].options[parseInt(target_button_index.split("_")[2])].text);
            window.dispatchEvent(new Event("language_change"));
        }

        // theme setting
        else if(target_button_index.split("_").slice(0, 2).join("_") === "0_1"){
            localStorage.setItem(json_settings_menu_en.tabs[0].items[1].title.toLowerCase().replace(" (s)", "").replaceAll(" ", "_"), json_settings_menu_en.tabs[0].items[1].options[parseInt(target_button_index.split("_")[2])].text);
            window.dispatchEvent(new Event("theme_change"));
        }

        // new source setting
        else if(target_button_index.split("_").slice(0, 2).join("_") === "0_2"){
            localStorage.setItem(json_settings_menu_en.tabs[0].items[2].title.toLowerCase().replace(" (s)", "").replaceAll(" ", "_"), json_settings_menu_en.tabs[0].items[2].options[parseInt(target_button_index.split("_")[2])].text);
            window.dispatchEvent(new Event("new_source_change"));
        } 

        // grid layout setting
        else if(target_button_index.split("_").slice(0, 2).join("_") === "0_3"){
            localStorage.setItem(json_settings_menu_en.tabs[0].items[3].title.toLowerCase().replace(" (s)", "").replaceAll(" ", "_"), json_settings_menu_en.tabs[0].items[3].options[parseInt(target_button_index.split("_")[2])].text);
            window.dispatchEvent(new Event("grid_layout_change"));
        } 
        
        // navbar behaviour setting
        else if(target_button_index.split("_").slice(0, 2).join("_") === "0_4"){ 
            localStorage.setItem(json_settings_menu_en.tabs[0].items[4].title.toLowerCase().replace(" (s)", "").replaceAll(" ", "_"), json_settings_menu_en.tabs[0].items[4].options[parseInt(target_button_index.split("_")[2])].text);
            window.dispatchEvent(new Event("navbar_behaviour_change"));
        }
        
        // AI analysis setting
        else if(target_button_index.split("_").slice(0, 2).join("_") === "1_0"){
            localStorage.setItem(json_settings_menu_en.tabs[1].items[0].title.toLowerCase().replace(" (s)", "").replaceAll(" ", "_"), json_settings_menu_en.tabs[1].items[0].options[parseInt(target_button_index.split("_")[2])].text);
            window.dispatchEvent(new Event("ai_analysis_change"));
        }

        // clip length setting
        else if(target_button_index.split("_").slice(0, 2).join("_") === "1_1"){            
            localStorage.setItem(json_settings_menu_en.tabs[1].items[1].title.toLowerCase().replace(" (s)", "").replaceAll(" ", "_"), target_button_index.split("_")[2]);
            window.dispatchEvent(new Event("clip_length_change"));
        }

        update_settings_values();
    }

    const handle_tab_change = (e, new_tab) => {
        
        set_active_tab(new_tab);
    }
    
    function tab_props(index) {
        return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handle_click_away = () => {
        return;
    }

    const determine_settings_button_background_color = (target_button_index) => {

        if(target_button_index === "1_1_1" || ((target_button_index.split("_")[0] + "_" + target_button_index.split("_")[1]) !== "1_1" && settings_values[parseInt(target_button_index.split("_")[0])][parseInt(target_button_index.split("_")[1])] === parseInt(target_button_index.split("_")[2])))
            return(theme === "Light" ? "rgba(21, 79, 107, 0.03)" : "rgba(200, 200, 200, 0.04)");

        if(settings_hover_state === target_button_index)
            return(theme === "Light" ? "rgba(150, 150, 150, 0.05)" : "rgba(200, 200, 200, 0.02)");

        return("rgba(255, 255, 255, 0.0)");
    }

    const determine_settings_button_border = (target_button_index) => {

        if(target_button_index === "1_1_1" || ((target_button_index.split("_")[0] + "_" + target_button_index.split("_")[1]) !== "1_1" && settings_values[parseInt(target_button_index.split("_")[0])][parseInt(target_button_index.split("_")[1])] === parseInt(target_button_index.split("_")[2])))
            return(theme === "Light" ? "1px solid var(--highlight_blue)" : "1px solid rgba(255, 255, 255, 0.1)");

        if(settings_hover_state === target_button_index)
            return(theme === "Light" ? "1px solid rgba(75, 75, 75, 0.1)" : "1px solid rgba(255, 255, 255, 0.03)");

        return("1px solid rgba(75, 75, 75, 0.0)");
    }

    const determine_settings_button_text_color = () => {

        return(theme === "Light" ? "var(--colour_one)" : "rgba(255, 255, 255, 1.0)");
    }

    const determine_settings_button_icon_url = (option, target_button_index) => {

        if((target_button_index.split("_")[0] + "_" + target_button_index.split("_")[1]) === "1_1")
            return(theme === "Light" ? json_data.option_selector_icon_light_mode : json_data.option_selector_icon_dark_mode);
        
        return(theme === "Light" ? option.icon_light_mode : option.icon_dark_mode)
    }

    const determine_settings_button_opacity = (target_button_index) => {

        if(settings_values[parseInt(target_button_index.split("_")[0])][parseInt(target_button_index.split("_")[1])] === parseInt(target_button_index.split("_")[2]) || settings_hover_state === target_button_index)
            return("0.9");

        return("0.2");
    }

    const handle_clip_length_change = (type) => {

        var old_clip_length = parseInt(localStorage.getItem(json_settings_menu_en.tabs[1].items[1].title.toLowerCase().replace(" (s)", "").replaceAll(" ", "_")));

        if(type === "minus")
            var new_clip_length = Math.max(parseInt(json_settings_menu_en.tabs[1].items[1].options[0].text), old_clip_length - 10);

        else
            var new_clip_length = Math.min(parseInt(json_settings_menu_en.tabs[1].items[1].options[json_settings_menu_en.tabs[1].items[1].options.length - 1].text), old_clip_length + 10);

        handle_changes("1_1_" + new_clip_length);
    }

    return (
        <div
        className = {is_mobile ? styles.super_root : null} 
        ref = {is_mobile ? root_ref : null}
        style = {is_mobile ? props.settings_menu_style : null}>

            <ClickAwayListener onClickAway = {props.close_settings_menu()}>
                <div 
                    onScroll = {() => props.close_settings_menu()}
                    //onMouseLeave = {() => props.close_settings_menu()}
                    className = {styles.root}
                    style = {!is_mobile ? props.settings_menu_style : null}>

                    <div 
                        className = {styles.root_inner} 
                        ref = {aux_ref}
                        style = {{
                            width: (is_mobile ? "" : "650px"),
                            backgroundColor: (theme === "Light" ? "rgba(245, 245, 245, 1.0)" : "var(--colour_one_dark_mode)"),
                            border: (theme === "Light" ? "0.020in solid var(--colour_two)" : "0.020in solid rgba(80, 80, 80, 0.55)"),
                            boxShadow: (theme === "Light" ? "rgba(100, 100, 100, 0.35) 0px 0px 10px" : "rgba(10, 15, 21, 0.35) 0px 8px 24px"),
                        }}>

                        <div className = {styles.hover_square} />

                        <div 
                            className = {styles.close_button_div}
                            style = {{
                                display: (is_mobile ? "" : "none"),
                                backgroundColor: (theme === "Light" ? "var(--colour_one)" : "rgba(190, 190, 190, 1.0)")
                            }}
                            onClick = {() => window.dispatchEvent(new Event("all_menus_should_disappear"))}
                            >
                            <div className = {styles.vertical_align}>
                                <div 
                                    className = {styles.close_button} 
                                    style = {{
                                        backgroundImage: (theme === "Light" ? "url(\"/media/icons/close_white_2.png\")" : "url(\"/media/icons/close_4.png\")")
                                    }}/>
                            </div>
                        </div>

                        <div className = {styles.top_bar_div}>
                            <div 
                                className = {styles.top_bar}
                                style = {{
                                    opacity: "1.0",
                                    backgroundImage: "url(\"/media/images/menu_pattern_THEME_mode.jpg\")".replace("THEME", theme.toLowerCase()),
                                }}/>
                            <div 
                                className = {styles.top_bar_fade}
                                style = {{
                                    opacity: "1.0",
                                    backgroundImage: (theme === "Light" ? "linear-gradient(to bottom, rgba(235,235,235,0.55), rgba(245, 245, 245, 1.0))" : "linear-gradient(to bottom, rgba(20, 25, 32, 0.6), var(--colour_one_dark_mode))")
                                }}/>

                            <div className = {styles.text_super_div}>
                                <div className = {styles.top_text_div} >
                                    <div 
                                        className = {styles.top_text}
                                        style = {{
                                            color: (theme === "Light" ? "var(--colour_one)" : "rgba(230, 230, 230, 1.0)")
                                        }}
                                        >{json_data.top_text.toUpperCase()}</div>
                                </div>
                                <div className = {styles.secondary_text_div}>
                                    <div 
                                        className = {styles.secondary_text}
                                        style = {{
                                            color: (theme === "Light" ? "var(--secondary_text_colour)" : "rgba(230, 230, 230, 0.75)")
                                        }}>{json_data.secondary_text.replaceAll("-", "‑")}</div>
                                </div>
                            </div>
                        </div>

                        <Box sx={{ width: '100%', marginTop: "-10px" }}>
                            <Box sx={{ borderBottom: 1, borderColor: (theme === "Light" ? "rgba(3, 52, 77, 0.25)" : "rgba(150, 150, 150, 0.25)") }}>
                                <Tabs 
                                    value = {active_tab} 
                                    onChange = {handle_tab_change} 
                                    aria-label = "basic tabs example"
                                    TabIndicatorProps = {{ style: {  height: "1px", background: (theme === "Light" ? "rgba(3, 52, 77, 1.0)" : "rgba(200, 200, 200, 1.0)") } }}>
                                    {json_data.tabs.map((tab) => (
                                        <Tab style = {{
                                            width: (100 / json_data.tabs.length) + "%", 
                                            maxWidth: (100 / json_data.tabs.length) + "%",
                                            color: (theme === "Light" ? "var(--colour_one)" : (active_tab === json_data.tabs.indexOf(tab) ? "rgba(200, 200, 200, 1.0)" : "rgba(150, 150, 150, 1.0)")),
                                            fontWeight: (active_tab === json_data.tabs.indexOf(tab) ? "bold" : "lighter"),
                                        }} 
                                        key = {json_data.tabs.indexOf(tab)} 
                                        label = {tab.tab_name} 
                                        {...tab_props(json_data.tabs.indexOf(tab))} />
                                    ))}
                                </Tabs>
                            </Box>

                            {json_data.tabs.map((tab) => (
                                
                                <div
                                    key = {json_data.tabs.indexOf(tab)}
                                    role = "tabpanel"
                                    hidden = {active_tab !== json_data.tabs.indexOf(tab)}
                                    id = {`simple-tabpanel-${json_data.tabs.indexOf(tab)}`}
                                    aria-labelledby = {`simple-tab-${json_data.tabs.indexOf(tab)}`}
                                    className = {styles.tab_root}>
                                    {active_tab === json_data.tabs.indexOf(tab) && (
                                    <div className = {styles.tab_root_inner}>
                                        
                                        <div 
                                            className = {styles.settings_div}
                                            style = {{
                                                overflow: (is_mobile ? "scroll" : ""),
                                                marginTop: (window_width <= 575 ? "5px" : "10px"),
                                                marginBottom: (window_width <= 575 ? "5px" : "10px"),
                                                paddingTop: (window_width <= 575 ? "7px" : ""),
                                                paddingBottom: (window_width <= 575 ? "7px" : ""),
                                                maxHeight: (window_width >= 500 ? "300px" : (window_width >= 325 ? "275px" : "150px")),
                                                maskImage: (window_width <= 575 ? "linear-gradient(to bottom, transparent 0%, black 10px, black calc(100% - 10px), transparent 100%)" : ""),
                                            }}>

                                            {tab.items.map((item) => (
                                                <div 
                                                    key = {tab.items.indexOf(item)}
                                                    className = {styles.setting_div}
                                                    style = {{
                                                        flexBasis: (item.size === "small" && (window_width > 575) ? "25%" : (window_width <= 450 ? "100%" : "35%")),
                                                        flexGrow: "1",
                                                        //backgroundColor: (theme === "Light" ? "rgba(100, 100, 100, 0.12)" : "rgba(80, 80, 80, 0.2)"),
                                                        border: (theme === "Light" ? "0.020in solid rgba(100, 100, 100, 0.12)" : "0.020in solid rgba(80, 80, 80, 0.2)"),
                                                    }}>
                                                        
                                                        <div className = {styles.setting_inner_div}>
                                                        <div className = {styles.setting_top_div}>
                                                            <div className = {styles.setting_title_div}>
                                                                <div 
                                                                    className = {styles.setting_title}
                                                                    style = {{
                                                                        color: (theme === "Light" ? "var(--colour_one)" : "white")
                                                                    }}>{item.title}</div>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className = {styles.setting_bottom_div}>
                                                        {
                                                            item.type === "button_group" ? 

                                                                <div className = {styles.settings_button_group_div}>
                                                                    
                                                                    {item.options.map((option) => (
                                                                        <div
                                                                            key = {item.options.indexOf(option)}
                                                                            onClick = {() => handle_changes(json_data.tabs.indexOf(tab) + "_" + tab.items.indexOf(item) + "_" + item.options.indexOf(option))}
                                                                            onMouseEnter = {() => set_settings_hover_state(json_data.tabs.indexOf(tab) + "_" + tab.items.indexOf(item) + "_" + item.options.indexOf(option))}
                                                                            onMouseLeave = {() => set_settings_hover_state(null)}
                                                                            className = {styles.settings_button_div}
                                                                            style = {{
                                                                                //display: (props.settings_menu_style["visibility"] === "hidden" ? "none" : ""),
                                                                                cursor: (props.settings_menu_style["visibility"] === "hidden" ? "normal" : "pointer"),
                                                                                opacity: (determine_settings_button_opacity(json_data.tabs.indexOf(tab) + "_" + tab.items.indexOf(item) + "_" + item.options.indexOf(option)))
                                                                            }}
                                                                            >

                                                                            <Button 
                                                                                orientation = {"vertical"}
                                                                                text = {option.text}
                                                                                text_size = {"11.5px"}
                                                                                text_family = {"regular"}
                                                                                text_color = {determine_settings_button_text_color()}
                                                                                border = {determine_settings_button_border(json_data.tabs.indexOf(tab) + "_" + tab.items.indexOf(item) + "_" + item.options.indexOf(option))}
                                                                                background_color = {determine_settings_button_background_color(json_data.tabs.indexOf(tab) + "_" + tab.items.indexOf(item) + "_" + item.options.indexOf(option))}
                                                                                icon_url = {determine_settings_button_icon_url(option, json_data.tabs.indexOf(tab) + "_" + tab.items.indexOf(item) + "_" + item.options.indexOf(option))}
                                                                                icon_size = {"18px"}
                                                                            />

                                                                        </div>
                                                                    ))}
                                                                
                                                                </div>
                                                                
                                                                :
                                                                
                                                                <div className = {styles.settings_button_group_div}>
                                                                    
                                                                    <div
                                                                        onClick = {() => handle_clip_length_change("minus")}
                                                                        onMouseEnter = {() => set_settings_hover_state(json_data.tabs.indexOf(tab) + "_" + tab.items.indexOf(item) + "_0")}
                                                                        onMouseLeave = {() => set_settings_hover_state(null)}
                                                                        className = {styles.settings_button_div}
                                                                        style = {{
                                                                            flexBasis: "25%",
                                                                            cursor: (props.settings_menu_style["visibility"] === "hidden" ? "normal" : "pointer"),
                                                                            display: (props.settings_menu_style["visibility"] === "hidden" ? "none" : ""),
                                                                            opacity: (settings_hover_state === (json_data.tabs.indexOf(tab) + "_" + tab.items.indexOf(item) + "_0") ? "0.9" : "0.2")
                                                                        }}>
                                                                            <Button 
                                                                                orientation = {"vertical"}
                                                                                text = {null}
                                                                                text_size = {null}
                                                                                text_family = {null}
                                                                                text_color = {determine_settings_button_text_color()}
                                                                                border = {determine_settings_button_border(json_data.tabs.indexOf(tab) + "_" + tab.items.indexOf(item) + "_0")}
                                                                                background_color = {determine_settings_button_background_color(json_data.tabs.indexOf(tab) + "_" + tab.items.indexOf(item) + "_0")}
                                                                                icon_url = {determine_settings_button_icon_url(null, json_data.tabs.indexOf(tab) + "_" + tab.items.indexOf(item) + "_0").replace("TYPE", "minus")}
                                                                                icon_size = {"13px"}
                                                                            />
                                                                    </div>

                                                                    <div 
                                                                        className = {styles.settings_button_div}
                                                                        style = {{
                                                                            flexBasis: "25%",
                                                                            cursor: (props.settings_menu_style["visibility"] === "hidden" ? "normal" : "pointer"),
                                                                            display: (props.settings_menu_style["visibility"] === "hidden" ? "none" : ""),
                                                                        }}>
                                                                        <Button 
                                                                            orientation = {"vertical"}
                                                                            text = {json_data.tabs[1].items[1].options[settings_values[1][1]].text}
                                                                            text_size = {"16px"}
                                                                            text_family = {"semibold"}
                                                                            force_full_height = {true}
                                                                            text_color = {determine_settings_button_text_color()}
                                                                            border = {determine_settings_button_border(json_data.tabs.indexOf(tab) + "_" + tab.items.indexOf(item) + "_1")}
                                                                            background_color = {determine_settings_button_background_color(json_data.tabs.indexOf(tab) + "_" + tab.items.indexOf(item) + "_1")}
                                                                            icon_url = {null}
                                                                            icon_size = {null}
                                                                        />
                                                                    </div>

                                                                    <div
                                                                        onClick = {() => handle_clip_length_change("plus")}
                                                                        onMouseEnter = {() => set_settings_hover_state(json_data.tabs.indexOf(tab) + "_" + tab.items.indexOf(item) + "_2")}
                                                                        onMouseLeave = {() => set_settings_hover_state(null)}
                                                                        className = {styles.settings_button_div}
                                                                        style = {{
                                                                            flexBasis: "25%",
                                                                            cursor: (props.settings_menu_style["visibility"] === "hidden" ? "normal" : "pointer"),
                                                                            display: (props.settings_menu_style["visibility"] === "hidden" ? "none" : ""),
                                                                            opacity: (settings_hover_state === (json_data.tabs.indexOf(tab) + "_" + tab.items.indexOf(item) + "_2") ? "0.9" : "0.2")
                                                                        }}>
                                                                            <Button 
                                                                                orientation = {"vertical"}
                                                                                text = {null}
                                                                                text_size = {null}
                                                                                text_family = {null}
                                                                                text_color = {determine_settings_button_text_color()}
                                                                                border = {determine_settings_button_border(json_data.tabs.indexOf(tab) + "_" + tab.items.indexOf(item) + "_2")}
                                                                                background_color = {determine_settings_button_background_color(json_data.tabs.indexOf(tab) + "_" + tab.items.indexOf(item) + "_2")}
                                                                                icon_url = {determine_settings_button_icon_url(null, json_data.tabs.indexOf(tab) + "_" + tab.items.indexOf(item) + "_2").replace("TYPE", "plus")}
                                                                                icon_size = {"13px"}
                                                                            />
                                                                    </div>

                                                                </div>
                                                        }
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}

                                        </div>

                                    </div>
                                )}
                                </div>
                            ))}

                        </Box>
                    
                    </div>
                
                </div>
            </ClickAwayListener>
        </div>
    );
}

export default SettingsMenu;